import { Form, Formik } from 'formik'
import React, { useEffect, useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { Button, CircularProgress, StylesProvider } from '@material-ui/core'
import AuthLayout from '../../layouts/AuthLayout'
import { loginUserApi } from '../../api'
import { useDispatch } from 'react-redux'
import AuthButton from '../../components/auth/AuthButton'
import AuthField from '../../components/auth/AuthField'
import AuthTitle from '../../components/auth/AuthTitle'
import strings from '../../lang/lang'
import { afterLogin } from '../../store/actions/userActions'
import styles from '../../jsxStyles/publisher'
import ReCAPTCHA from 'react-google-recaptcha'
function Login(props) {
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = styles()
  // const { dispatch } = useContext(AuthContext)
  const [verified, setVerified] = useState(false)

  const [lang, setLang] = useState(localStorage.getItem('lang'))
  const initialValues = {
    email: '',
    password: '',
  }
  const [loginErr, setLoginErr] = useState(false)

  const validationSchema = Yup.object({
    email: Yup.string()
      .required(strings.emailrequired)
      .email(strings.invalidemail),
    password: Yup.string().required(strings.pwdRequired),
  })

  const handleLogin = async (values, onSubmitProps) => {
    // if (verified) {
      const { data } = await loginUserApi(values)
      if (!data.status) {
        setLoginErr(true)
        onSubmitProps.resetForm()
        setTimeout(() => setLoginErr(false), 4000)
      } else if (data.status) {
        localStorage.setItem('authToken', data?.data?.api_token)
        dispatch(afterLogin(data))
        // history.push('/publisher/dashboard')
      }
    // }

  }

  useEffect(() => {
    localStorage.removeItem("user");
    localStorage.removeItem("authToken");
    localStorage.removeItem("token");
    localStorage.removeItem("ad_list");
    localStorage.removeItem("ad_price");
    setLang(localStorage.getItem('lang'))
  }, [props.onChange])

  return (
    <AuthLayout props={props}>
      <div className="xs:mt-7 sm:mt-16">
        <AuthTitle title={strings.loginTitle} lang={lang} />
        <div
          style={{ textAlign: lang === 'ar' ? 'right' : 'left' }}
          className="h-4 mx-4 sm:mx-8 text-red-500 text-sm xs:text-base font-semibold lowercase transition"
        >
          {loginErr ? strings.invalidCredentials : ''}
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={handleLogin}
          validationSchema={validationSchema}
        >
          {({ touched, errors, dirty }) => (
            <Form className="px-4">
              <AuthField
                label={strings.email}
                type="text"
                name="email"
                className={classes.mobileEmail}
                fieldErr={touched.email && errors.email ? errors.email : null}
              />
              <AuthField
                label={strings.password}
                type="password"
                name="password"
                className={classes.mobileEmail}
                fieldErr={
                  touched.password && errors.password ? errors.password : null
                }
              />
              {/* <ReCAPTCHA
                sitekey="6LfT-78ZAAAAAHQ6G3bQm7Mz3-S7TiMp_cGgj3hn"
                onChange={(res) => setVerified(res.status)}
              /> */}
              <Link
                style={{ float: lang === 'en' ? 'left' : 'right', paddingLeft: 12, paddingRight:12}}
                to="/forgot-password"
                className="my-0.5 text-blue-500 text-sm sm:text-base tracking-wide cursor-pointer"
              >
                {strings.forgotPwd}
              </Link>
              <AuthButton
                btnText={strings.login}
                linkText={strings.newAccount}
                linkPath="/register"
                lang={lang}
                isDisabled={!dirty}
              />
            </Form>
          )}
        </Formik>
      </div>
    </AuthLayout>
  )
}

export default Login
