import userReducer from './userReducer';
import paymentReducer from './paymentReducer';
import notificationReducer from './notificationReducer';
import miscReducer from './miscReducer';
import { combineReducers } from 'redux';

const combinedReducer = combineReducers({
    user: userReducer,
    payment: paymentReducer,
    notifications: notificationReducer,
    miscData:miscReducer
})

export default combinedReducer;

