import { makeStyles } from '@material-ui/core/styles'
import LoginBanner from '../assets/img/login.jpg'
export default makeStyles((theme) => ({
  homePage: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(146deg, rgba(38,61,75,1) 0%, rgba(75,134,84,1) 50%, rgba(38,61,75,1) 100%)',
    [theme.breakpoints.down('sm')]: {},
  },
  container: {
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
    webkitBoxShadow: '-11px -2px 33px -4px rgba(0,0,0,0.39)',
    mozBoxShadow: '-1px -2px 33px -4px rgba(0,0,0,0.39)',
    boxShadow: ' -1px -2px 33px -4px rgba(0,0,0,0.39)',
    '& > div:first-child': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      background: `linear-gradient(0deg, rgba(69, 125, 95, 0.7), rgba(69, 125, 95, 0.5)),url(${LoginBanner})`,
      backgroundSize: 'cover',
      width: '50%',
      // backgroundColor: '#2ca862',
    },
    '& .lang-section': {
      display: 'flex',
      fontSize: '0.7rem',
      justifyContent: 'space-evenly',
      width: '100%',
      '& *': {
        cursor: 'pointer',
        transition: 'ease-in-out 0.1s',
      },
      '& *:hover': {
        color: 'blue',
      },
    },
    '& .logo': {
      width: '50%',
    },
    '& .firstBox': {
      height: '100%',
      padding: '2rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
      '& button': {
        color: 'white',
        border: '1px solid white',
        margin: '0.5rem',
        '&:hover': {
          color: 'black',
          backgroundColor: 'blue',
        },
      },
    },
    '& .secondBox': {
      background: 'white',
      display: 'flex',
      flexDirection: 'column',
      padding: '1rem',
      width: '50%',
      '& .buttons': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& button': {
          color: 'black',
          border: '1px solid white',
          padding: '5px',
          borderRadius: '0px',
          border: '1px solid black',
          '&:hover': {
            color: 'black',
            backgroundColor: 'blue',
          },
        },
      },
      '& .login-form': {
        display: 'flex',
        flexDirection: 'column',
        padding: '2rem',
        '& > *': {
          marginBottom: '1rem',
        },
      },
    },
  },
  // homePage:{
  //   div:{
  //     border: "1px solid black",
  //     background: "white",
  //     height: "fit-content"
  //   }
  // },
  activeBtn: {
    background: '#3f51b5',
    color: 'white !important',
  },
  title: {
    color: '#212121',
  },
}))
