import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => {
  return {
    navBar: {
      background: 'linear-gradient(146deg, rgba(214, 255, 221) 0%, rgba(75,134,84,1) 50%, rgba(214, 255, 221) 100%)',
      //background: 'linear-gradient(146deg, rgba(214, 255, 221) 0%, rgba(38,61,75,1) 50%, rgba(214, 255, 221) 100%)',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      maxWidth: '100%',
      padding: '0.5rem 3rem',
      justifyContent: "space-between",
      '& *': {
        margin: '0 0.20rem',
      },
      '& .nav-link': {
        color: 'grey !important',
      },
      '& .nav-item button': {
        color: 'white !important',
        margin: '0 1rem',
      },
      '& .MuiButton-iconSizeMedium > *:first-child': {
        fontSize: '1.5rem',
      },
      [theme.breakpoints.down('md')]:{
        padding: 0
      },
    },
    navBarTop: {
      overflowX: 'hidden',
      display: 'flex',
      justifyContent: 'space-between !important',
      '& .innerContent': {
        minWidth: '36rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between !important',
        marginRight: '2rem',
      },
    },
    navbarBrand: {
      width: '13%',
      '& img': {
        width: '100%',
      },
    },
    main: {
      // backgroundColor: '#ffffff',
      padding: '0px !important',
      margin: '0px !important',
    },
    active: {
      textAlign: "center",
      padding: 5,
      border: "2px solid black",
      borderRadius: 12,
      backgroundColor: '#111827',
      color: '#fff',
      "&:hover": {
        backgroundColor: "#87e2a0",
      },
    },
    notActive: {
      textAlign: "center",
      padding: 5,
      border: "2px solid black",
      borderRadius: 12,
      backgroundColor: "#55bc72",
      color: "white",
      "&:hover": {
        backgroundColor: "#87e2a0",
      },
    },
    thaminLogo:{
      height: 70,
      [theme.breakpoints.down('md')]: {
        height: 50,
      },
    },
    accountBtn:{
      textAlign: "center",
      padding: 5,
      border: "2px solid black",
      borderRadius: 12,
      backgroundColor: "#55bc72",
      color: "white",
      "&:hover": {
        backgroundColor: "#87e2a0",
      },
    },
    accountBtns:{
      //textAlign: "center",
      width: "100%",
      //padding: 5,
      border: "2px solid black",
      borderRadius: 12,
      backgroundColor: "#55bc72",
      color: "white",
      "&:hover": {
        backgroundColor: "#87e2a0",
      },
    },
  }
})
