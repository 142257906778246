import {
    FormHelperText, Slider, Typography, Grid, FormControl, RadioGroup, FormControlLabel, Radio, InputLabel, Select,
    MenuItem, TextField
} from "@material-ui/core";
import { useState, useEffect } from "react";
import { subscribtionsApi } from "../../api";
import strings from '../../lang/lang'
import useStyles from '../../jsxStyles/publisher'
import { useDispatch } from 'react-redux'
import { savePrice } from '../../store/actions/paymentActions'
import { calculateViews } from "../../utils/helpers";

export default function SubscriptionBar(props) {
    const [budgetMarks, setbudgetMarks] = useState([])
    const [maxBudget, setMaxBudget] = useState(400)
    const [minBudget, setMinBudget] = useState(100)
    const [costCollection, setCostCollection] = useState(100)
    const [subscriptionList, setSubcriptionList] = useState([])
    const [gift, setGift] = useState(strings.no)
    const [views, setViews] = useState(0)
    const lang = localStorage.getItem('lang')
    const styles = useStyles()
    const dispatch = useDispatch()
    const {
        errorsRes,
        budget,
        setBudget,
        setSubscriptionId,
        setIncentive,
        incentive,
        setGiftType,
        gift_type,
        incentive_amount,
        setIncentiveAmount,
        incentive_users,
        setIncentiveUsers,
        setGrossTotal,
        grossTotal } = props

    useEffect(() => {
        if (costCollection.length > 0) {
            let obj = costCollection.find((obj) => obj.price == budget)
            if (obj) {
                let percentage_value = (obj.price / 100) * obj.tax;
                //setViews((percentage_value / 0.15).toFixed(0));
                
                setViews((obj.total_views));
                let tax = (obj.price / 100) * 15;
                // setTax(tax)
                // setGrossTotal(obj.price)
                dispatch(savePrice(obj.price))
                localStorage.setItem('subscription_id',obj.id)
                localStorage.setItem('ad_price',budget)
                setGift(obj.price >= 700 ? strings.yes : strings.no)
            }
        }
    }, [costCollection, budget])

    useEffect(() => {
        subscribtionsApi(lang).then((res) => {
            setSubcriptionList(res?.data?.data);
            const seen = new Set()
            const filteredArr = res?.data?.data.filter((el) => {
                const duplicate = seen.has(el.price)
                seen.add(el.price)
                return !duplicate
            })
            const sortedList = filteredArr.sort((a, b) =>
                a.price > b.price ? 1 : b.price > a.price ? -1 : 0,
            )
            setSubscriptionId(sortedList[0].id)
            setbudgetMarks(sortedList.map((obj) => ({ value: obj.price, label: obj.price })))
            setMaxBudget(Math.max(...seen))
            setBudget(Math.min(...seen))
            // setGrossTotal(Math.min(...seen))
            setMinBudget(Math.min(...seen))
            console.log(res)
            setCostCollection(
                res?.data?.data.map((obj) => ({
                    id: obj.id,
                    price: obj.price,
                    views: calculateViews(obj),
                    tax: obj.deduction_ratio,
                    earningUser: obj.share_earn,
                    total_views: obj.total_view,
                })),
            )

        })
    }, [])

    return (
        <>
            {budgetMarks.length > 0 && (
                <>
                    <Slider
                        component="div"
                        step={null}
                        max={maxBudget}
                        min={minBudget}
                        marks={budgetMarks}
                        defaultValue={0}
                        value={budget}
                        valueLabelDisplay="on"
                        onChange={(err, val) => {
                            if(val <= 700){
                                setIncentiveAmount("")
                                setIncentiveUsers("")
                                setIncentive("nothing")
                            }
                            setBudget(val);
                            let item = subscriptionList.filter((el) => el.price == val);
                            setSubscriptionId(item[0].id);
                            setGrossTotal(+(incentive_amount) + +val)
                        }}
                    />
                    {errorsRes &&
                        <FormHelperText style={{ color: 'red' }}>
                            {Object.keys(errorsRes).length > 0 && errorsRes.subscription_id?.[0]}
                        </FormHelperText>
                    }
                    <Grid container>
                        <Grid item xs={1} md={3}/>
                        <Grid item xs={10} md={6}>
                            <Typography blockquote="true" component={'div'} className={styles.budgetCards} >
                                <Typography className={styles.budget}>
                                {budget} {strings.sar} {strings.up} {views} {strings.calcViews}
                                </Typography>
                            </Typography>
                        </Grid>
                        <Grid item xs={1} md={3}/>
                        {/* <Grid item xs={6}>
                            <Typography blockquote="true" component={'div'} className={styles.budgetCards}>
                                <Typography weight={'bold'} variant="h4">
                                    {gift}
                                </Typography>
                                <span>{strings.calcGift}</span>
                            </Typography>
                        </Grid> */}
                    </Grid>
                    {
                        budget >= 700 && (
                            <>
                                <Typography className={styles.whatGiftType}>
                                    {strings.whatGiftType}
                                </Typography>
                                <Grid container className="mt-9" spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth component="fieldset">
                                            {/* <FormLabel id="gift-label">{strings.giftType}</FormLabel> */}
                                            <RadioGroup
                                                aria-label="incentive"
                                                defaultValue={incentive}
                                                name="incentive"
                                                className="d-inline"
                                                onChange={(e) => {
                                                    
                                                    setIncentive(e.target.value)
                                                }}
                                            >
                                                <FormControlLabel
                                                    value="nothing"
                                                    control={<Radio />}
                                                    label={strings.nothing}
                                                    className={styles.nothing}
                                                />
                                                <FormControlLabel
                                                    value="discount"
                                                    control={<Radio />}
                                                    label={strings.discount}
                                                    className={styles.nothing}
                                                />
                                                <FormControlLabel
                                                    value="gift"
                                                    control={<Radio />}
                                                    label={strings.gift}
                                                    className={styles.nothing}
                                                />

                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    {
                                        incentive != "nothing" &&
                                        <>
                                            {
                                                incentive != "nothing" && incentive != "discount" &&
                                                <Grid item xs={12}>
                                                    <FormControl fullWidth component="fieldset">
                                                        {/* <FormLabel id="gift-label">{strings.giftType}</FormLabel> */}
                                                        <RadioGroup
                                                            aria-label="incentive"
                                                            defaultValue={gift_type}
                                                            name="giftType"
                                                            className="d-inline"
                                                            onChange={(e) => setGiftType(e.target.value)}
                                                        >
                                                            <FormControlLabel
                                                                value="gift"
                                                                control={<Radio />}
                                                                label={strings.thminMysteryBox}
                                                            />
                                                            <FormControlLabel
                                                                value="discount"
                                                                control={<Radio />}
                                                                label={strings.gift}
                                                            />

                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                            }

                                            <Grid item xs={12}>
                                                <TextField
                                                    value={incentive_amount}
                                                    fullWidth
                                                    placeholder={strings.enterGiftAmount}
                                                    onChange={val => {
                                                        setIncentiveAmount(val?.target?.value)
                                                        setGrossTotal(+(val?.target?.value) + +budget)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    value={incentive_users}
                                                    fullWidth
                                                    placeholder={strings.numberOfUsers}
                                                    onChange={val => setIncentiveUsers(val?.target?.value)}
                                                />
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </>
                        )
                    }
                </>
            )}
        </>
    )
}