import React, { useState } from "react";
import { AiOutlineDoubleRight } from "react-icons/ai";
import strings from "../../lang/lang";
import styles from "../../modules/authLayout.module.css";

function AccountTypeSelect({ lang, setUserType }) {
  const [selected, setSelected] = useState(null);

  return (
    <div className="px-2 xs:px-4 select-none">
      {/* <header className="w-full sm:mt-8 md:mt-14 mb-6">
        <span className="text-green-800 font-medium text-xl xs:text-2xl">
          {strings.chooseUserType}
        </span>
      </header> */}
      <div className="w-full flex flex-col items-center text-gray-700">
        <div
          onClick={() => setSelected("advertiser")}
          className={`${
            selected === "advertiser" ? styles.active_box : "shadow"
          } relative flex-y-1 w-full xl:w-11/12 2xl:w-10/12 h-44 lg:h-56 flex items-center justify-between mb-3.5 border rounded-lg cursor-pointer transition duration-200 ease-out`}
        >
          <img
            src="/images/advertiser.svg"
            alt="avertiser"
            className="w-1/2 h-full absolute left-0 object-center"
          />
          <span
            style={{ transform: lang === "ar" && "translate(10px, 10px)" }}
            className="w-1/2 absolute right-0 text-2xl xs:text-3xl lg:text-4xl xl:text-5xl tracking-wide font-semibold text-gray-500"
          >
            {strings.advertister}
          </span>
        </div>
        <div
          onClick={() => setSelected("watcher")}
          className={`${
            selected === "watcher" ? styles.active_box : "shadow"
          } relative flex-y-1 w-full xl:w-11/12 2xl:w-10/12 h-44 lg:h-56 flex items-center justify-between my-3 border rounded-lg cursor-pointer transition duration-200 ease-out`}
        >
          <img
            src="/images/watcher.svg"
            alt="watcher"
            className="w-1/2 h-full absolute left-0 object-center"
          />
          <span className="w-1/2 absolute right-0 text-2xl xs:text-3xl lg:text-4xl xl:text-5xl font-semibold tracking-wide text-gray-500">
            {strings.watcher}
          </span>
        </div>
      </div>
      <div>
        <button
          onClick={() => selected && setUserType(selected)}
          style={{ float: lang === "ar" ? "left" : "right" }}
          className={`m-3 flex items-center p-2 text-white tracking-wide bg-green-500 ${
            !selected && "bg-opacity-50"
          } rounded-full focus:outline-none`}
        >
          <AiOutlineDoubleRight
            className="text-3xl"
            style={{ transform: lang === "ar" && "scale(-1)" }}
          />
        </button>
      </div>
    </div>
  );
}

export default AccountTypeSelect;
