import axios from "axios";
import { Form, Formik } from "formik";
import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { afterRegister, setAddress } from '../../store/actions/userActions'
import { registerUserApi } from "../../api";
import { yupResolver } from '@hookform/resolvers/yup'
import AccountTypeSelect from "../../components/auth/AccountTypeSelect";
import AuthButton from "../../components/auth/AuthButton";
import AuthField from "../../components/auth/AuthField";
import AuthTitle from "../../components/auth/AuthTitle";
import strings from "../../lang/lang";
import AuthLayout from "../../layouts/AuthLayout";
import { useDispatch } from "react-redux";
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import * as yup from 'yup';
import { getCountries, getStateByCountryId } from '../../routes/auth';
import {
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from '@material-ui/core'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import useStyles from "../../jsxStyles/publisher";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';
import ManImg from "../../assets/img/img/man.jpeg";
import WomanImg from "../../assets/img/img/woman.jpeg";

function Register(props) {
  const initialValues = {
    fName: "",
    lName: "",
    email: "",
    mobile: "",
    address: "",
    birthdate: "",
    gender: "",
    password: "",
    password_confirmation: "",
  };
  const validationRules = yup.object().shape({
    name: yup.string().required('Company name is required'),
    address: yup.string().required('Company Address is required'),
    instagram: yup.string().required('Instagram account is required'),
    snapchat: yup.string().required('Snapchat account is required'),
    country_id: yup.string().required('Please select country'),
    state_id: yup.string().required('Please select city'),
    mobile: yup.number().min(12, 'Too Short!'),
    logo: yup.mixed().required('A file is required'),
  })
  const validationSchema = Yup.object({
    fName: Yup.string().required(strings.fNameRequired),
    lName: Yup.string().required(strings.lNameRequired),
    email: Yup.string()
      .required(strings.emailrequired)
      .email(strings.invalidemail),
    password: Yup.string().required(strings.pwdRequired),
    password_confirmation: Yup.string()
      .required(strings.confirmPwdRequired)
      .oneOf([Yup.ref("password")], strings.pwdsNotMatch)
  });
  const dispatch = useDispatch()
  const history = useHistory();
  const styles = useStyles();

  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [userType, setUserType] = useState(null);
  const [registerErr, setRegisterErr] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [pwdError, setPwdError] = useState("");
  const [gender, setGender] = useState(2);
  const { register, errors, setValue, getValues } = useForm({
    resolver: yupResolver(validationRules),
  })
  const [countryId, setCountryId] = useState("")
  const [states, setStates] = useState([])
  const [selectText, setSelectText] = useState('Select Country')
  const [countries, setCountries] = useState([])
  const [birthdate, setBirthdate] = React.useState(null);
  useEffect(() => {
    setLang(localStorage.getItem("lang"));
  }, [props.onChange]);

  const handleCountryChange = (value) => {
    if(value != ""){
        setCountryId(value)
        setValue('country_id', value)
        getStateByCountryId(value).then((result) => {
        if (Object.keys(result.data).length == 0 || result.data.length == 0) {
          setStates([])
          setSelectText('No States Available')
          return
        }
        setStates(result.data)
      })
    }
    
  }

  
  const handleRegister = async (values, onSubmitProps) => {
    const val = getValues()
    values.mobile = val.mobile;
    values.address = val.address;
    values.country_id = val.country_id;
    values.state_id = val.state_id;
    values.birthdate = (birthdate).format('YYYY-MM-DD');
    values.gender = gender;
    values.name = `${values.fName} ${values.lName}`;
    const { data } = await registerUserApi(values);
    
    if (!data.status) {
      setRegisterErr(true);
      if (Object.keys(data?.error).length > 0) {
        data?.error?.email && setEmailError(data.error.email);
        data?.error?.password && setPwdError(data.error.password);
      }
      onSubmitProps.resetForm();
      setTimeout(() => setRegisterErr(false), 4000);
    } else if (data.status) {
      localStorage.setItem("authToken", data?.data?.token);
      dispatch(afterRegister(data?.data));
      localStorage.setItem('address', val.address)
      history.push("/publisher/dashboard");
    }
  };

  useEffect(() => {
    getCountries().then((result) => {
      setCountries(result.data)
      const values = getValues()
      if(values.country_id != "")
        handleCountryChange(values.country_id)
    })
  }, [])
  return (
    <AuthLayout props={props}>
      <div className="xs:mt-4 sm:mt-8">
        {/* {!userType ? (
          <AccountTypeSelect lang={lang} setUserType={setUserType} />
        ) : (
          <> */}
            <AuthTitle title={strings.register} lang={lang} />
            <div
              style={{ textAlign: lang === "ar" ? "right" : "left" }}
              className="sm:mx-8 text-red-500 text-sm xs:text-base font-semibold lowercase"
            >
              {registerErr
                ? emailError
                  ? emailError
                  : pwdError
                  ? pwdError
                  : ""
                : ""}
            </div>
            <Formik
              initialValues={initialValues}
              onSubmit={handleRegister}
              validationSchema={validationSchema}
            >
              {({ touched, errors, dirty }) => {
                return (
                  <Form className="xl:px-4">
                    <Grid container>
                    <Grid item xs={12} sm={6}>
                    <AuthField
                      label={strings.fName}
                      type="text"
                      name="fName"
                      fieldErr={
                        touched.fName && errors.fName ? errors.fName : null
                      }
                    />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <AuthField
                      label={strings.lName}
                      type="text"
                      name="lName"
                      fieldErr={
                        touched.lName && errors.lName ? errors.lName : null
                      }
                    />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <AuthField
                      label={strings.email}
                      type="text"
                      name="email"
                      fieldErr={
                        touched.email && errors.email ? errors.email : null
                      }
                    />
                    </Grid>
                    <Grid item xs={12} sm={6} style={{padding:"20px 0px"}}>
                    <Grid container>
                    <Grid item xs={2} sm={2}/>
                    <Grid item xs={8} sm={8}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disableFuture
                      label={strings.birthdate}
                      openTo="year"
                      views={['year', 'month', 'day']}
                      value={birthdate}
                      onChange={(newValue) => {
                        setBirthdate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2} sm={2}/>
                    </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{margin: "auto", padding: "12px 0px"}}>
                      <Grid container>
                          <Grid xs={3} sm={3} className={styles.ganderTitReg}>
                            {strings.gender}: 
                          </Grid>
                          <Grid xs={4} sm={4}>
                             <Button 
                              className={gender == 0 ? styles.ganderReg2: styles.ganderReg }
                              onClick={() => setGender(0)}>
                              <img
                              src={ManImg}
                              style={{ width: "100%" }} 
                              />
                            </Button>
                          </Grid>
                          <Grid xs={4} sm={4}>
                            <Button  
                             className={gender == 1 ? styles.ganderReg2: styles.ganderReg }
                             onClick={() => setGender(1)}
                            >
                             <img
                              src={WomanImg}
                              style={{ width: "100%" }} 
                              />
                            </Button>
                          </Grid>
                          <Grid xs={1} sm={1}/>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{direction: lang === "ar" ? "ltr" : "ltr",padding:12, color: "black"}}>
                      <input
                        name="mobile"
                        onChange={(e) => setValue(e.target.value)}
                        ref={register}
                        type="text"
                        style={{ display: 'none'}}
                      />
                        <PhoneInput
                          fullWidth
                          onChange={(value) => setValue('mobile', value)}
                          country={'sa'}
                          specialLabel={strings.mobile}
                          error={errors?.mobile}
                          helperText={errors?.mobile?.message}
                          inputProps={{
                            required: true,
                            max: '12',
                            name: 'name',
                            variant: 'standard',
                            style: { width: '100%', padding: '27.5px 14px 27px 51px'},
                          }}
                          dropdownStyle={{color:"black"}}
                        />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{padding:12}}>
                        <input type="hidden" ref={register} name="country_id" />
                        {countries.length > 0 && (
                          <TextField
                            value={countryId}
                            fullWidth={true}
                            variant="outlined"
                            label={strings.selectCountry}
                            select={true}
                            error={errors?.country_id}
                            helperText={errors?.country_id?.message}
                            onChange={(e) => handleCountryChange(e.target.value)}
                          >
                            {countries.map(({ name, id }) => (
                              <MenuItem key={id} value={id}>
                                {name}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} style={{padding:12}}>
                      <input type="hidden" ref={register} name="state_id" />
                      <TextField
                        fullWidth={true}
                        variant="outlined"
                        label={strings.selectCity}
                        select={true}
                        error={errors?.state_id}
                        helperText={errors?.state_id?.message}
                        onChange={(e) => setValue('state_id', e.target.value)}
                      >
                        {states.length > 0 &&
                          states.map(({ id, name }) => (
                            <MenuItem key={id} value={id}>
                              {name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} style={{padding:12}}>
                    <TextField
                        fullWidth={true}
                        variant="outlined"
                        inputRef={register}
                        name="address"
                        label={strings.address}
                        error={errors?.address}
                        helperText={errors?.address?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LocationCityIcon htmlColor="" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid> */}
                        
                    <Grid item xs={12} sm={6}>
                    <AuthField
                      label={strings.password}
                      type="password"
                      name="password"
                      fieldErr={
                        touched.password && errors.password
                          ? errors.password
                          : null
                      }
                    />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <AuthField
                      label={strings.password_confirmation}
                      type="password"
                      name="password_confirmation"
                      fieldErr={
                        touched.password_confirmation &&
                        errors.password_confirmation
                          ? errors.password_confirmation
                          : null
                      }
                    />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                    <AuthButton
                      btnText={strings.register}
                      linkText={strings.haveAccount}
                      linkPath="/login"
                      lang={lang}
                      isDisabled={!dirty}
                    />
                    </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          {/* </>
        )} */}
      </div>
    </AuthLayout>
  );
}

export default Register;
