import { CircularProgress, Grid, TextField, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';

import strings from '../../lang/lang';
import { getUserJobs } from '../../routes/auth';
import useStyles from "../../jsxStyles/publisher";

function UserIntersets(props) {
  const lang = localStorage.getItem("lang")
  const [jobs, setJobs] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const styles = useStyles();

  const selectAllInterest = __ => {
    props.setSelectedJobs(jobs)
  }

  useEffect(() => {
    setLoading(true);
    getUserJobs().then((result) => {
      setJobs(result.data);
      setLoading(false);
    });
  }, []);

  return (
    <div className="my-6">
      <label
        className={`w-full mb-3 text-lg sm:text-xl font-semibold`}
      >
        {strings.userinterest}
      </label>
      <Grid container spacing={3} style={{direction:"auto"}}>
        <Grid item xs={8}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Autocomplete
              id="combo-box-demo"
              multiple
              options={jobs}
              className={styles.countrySelect}
              getOptionLabel={(option) => option.title}
              value={props.selectedJobs ?? ""}
              onChange={(event, value) => {
                props.setSelectedJobs(value)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  style={{ margin: "10px 0px"}}
                  variant="outlined"
                  placeholder={strings.selectuserinterest}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={4} style={{ display: "flex", padding: "1.4rem" }}>
          <Button
            fullwidth
            variant="contained"
            onClick={selectAllInterest}
            color="primary">
            {strings.selectAll}
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default UserIntersets;
