import { Box, Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import React from 'react';

import useStyles from '../jsxStyles/publisher.jsx';
import dashboard from '../modules/dashboard.module.css';

function StepCard({ num, name, details, stepNum }) {
	const classes = useStyles()

	return (
		<Grid container style={{ position: "relative", padding: ".5rem 0" }}>
			<Grid item xs={12}>
				<Card className={`${classes.innerCard} ${dashboard.leaf}`}>
					<CardContent className="card-content">
						<Typography>{num}</Typography>
						<Typography
							style={{
								fontWeight: "500",
								fontSize: ".9375rem",
								color: "#ffffff",
							}}
						>
							{name}
						</Typography>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} md={6}>
				{/* <Card variant="outlined" className={classes.stepsCard}> */}
					<Box className={`inner-card ${dashboard.stepContainer}`}>
						{/* <div className={dashboard.step}>
							<Typography variant="h6"><span>{num}</span>{name}</Typography>
						</div> */}
						<Button
							variant="outlined"
							color="primary"
							size="small"
							startIcon={<ControlPointIcon />}
						>
							{stepNum}
						</Button>
						<Typography >{details}</Typography>
					</Box>
				{/* </Card> */}
			</Grid>
		</Grid>
	)
}

export default StepCard
