import { Button, Card, Grid, Typography, Container, LinearProgress, CircularProgress } from '@material-ui/core'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { CardHeader } from 'reactstrap'

import { publisherAdsApi } from '../../api'
import AdsList from '../../components/ads/AdsList'
import useStyle from '../../jsxStyles/publisher.jsx'
import strings from '../../lang/lang'
import { ReactComponent as Active } from './svgs/active.svg'
import { ReactComponent as Close } from './svgs/close.svg'
import { ReactComponent as Pending } from './svgs/pending.svg'
import { ReactComponent as Reject } from './svgs/reject.svg'
import { ReactComponent as Uncomplete } from './svgs/uncomplete.svg'
import editedStyles from '../../modules/dashboard.module.css'

function MyAds() {
  let lang = localStorage.getItem("lang")
  const history = useHistory()
  const style = useStyle()
  let storedToken = localStorage.getItem('authToken')
  const [activeAds, setActiveAds] = useState(null)
  const [rejectedAds, setRejectedAds] = useState(null)
  const [closedAds, setClosedAds] = useState(null)
  const [uncompletedAds, setUncompletedAds] = useState(null)
  const [pendingAds, setPendingAds] = useState(null)
  const [showDataTable, setShowDataTable] = useState(false)
  const [adsData, setAdsData] = useState([])



  useEffect(() => {
    if (!storedToken) window.location.pathname = '/login'
  }, [storedToken])

  useEffect(() => {
    publisherAdsApi(storedToken).then(({ data }) => {
      setActiveAds(data?.data?.products)
      setRejectedAds(data?.data?.rejectedproducts)
      setClosedAds(data?.data?.closedproducts)
      setUncompletedAds(data?.data?.uncompletedproducts)
      setPendingAds(data?.data?.pendingproducts)
    })
  }, [])

  const handleDataTableComponent = (type) => {
    history.push(`/publisher/myadslist/${type}`)
  }

  return (
    <div
      className={`${style.page}  px-2 sm:px-10 lg:px-40`}
    >
      <Container>
        <Grid container>
          <Grid item md={8} xs={12} sm={6}>
            <Card
              className={style.titleParent}
              onClick={() => handleDataTableComponent('active')}
            >
              <CardHeader>
                <Active className={style.svg} />
              </CardHeader>
              <Typography style={{textAlign: "center", padding: "14px 0px 6px 0px", fontSize: 18}}>
                <span style={{padding: "0px 12px"}}>{strings.activeads}</span>
                :
                <span style={{padding: "0px 12px"}}>{
                  activeAds == null
                    ? <CircularProgress />
                    : activeAds.length}</span>
              </Typography>
            </Card>
          </Grid>
          <Grid item md={4} xs={12} sm={6}>
            <Card
              className={style.titleParent}
              onClick={() => handleDataTableComponent('pending')}
            >
              <CardHeader>
                <Pending className={style.svg} />
              </CardHeader>
              <Typography style={{textAlign: "center", padding: "14px 0px 6px 0px", fontSize: 18}}>
              <span style={{padding: "0px 12px"}}>{strings.pendingads}</span>
              :
              <span style={{padding: "0px 12px"}}>{
                  pendingAds == null
                    ? <CircularProgress />
                    : pendingAds.length}</span>
              </Typography>
            </Card>
          </Grid>
          <Grid item md={4} xs={12} sm={6}>
            <Card
              className={style.titleParent}
              onClick={() => handleDataTableComponent('rejected')}
            >
              <CardHeader>
                <Reject className={style.svg} />
              </CardHeader>
              <Typography style={{textAlign: "center", padding: "14px 0px 6px 0px", fontSize: 18}}>
                <span style={{padding: "0px 12px"}}>{strings.rejectedads}</span>
                :
                <span style={{padding: "0px 12px"}}>{
                  rejectedAds == null ?
                    <CircularProgress /> : rejectedAds.length}</span>
              </Typography>
            </Card>
          </Grid>
          <Grid item md={4} xs={12} sm={6}>
            <Card
              className={style.titleParent}
              onClick={() => handleDataTableComponent('closed')}
            >
              <CardHeader>
                <Close className={style.svg} />
              </CardHeader>
              <Typography style={{textAlign: "center", padding: "14px 0px 6px 0px", fontSize: 18}}>
                <span style={{padding: "0px 12px"}}>{strings.closedads}</span>
               :
               <span style={{padding: "0px 12px"}}>{closedAds == null ?
                  <CircularProgress /> : closedAds.length}</span>
              </Typography>
            </Card>
          </Grid>
          <Grid item md={4} xs={12} sm={6}>
            <Card
              className={style.titleParent}
              onClick={() => handleDataTableComponent('uncompleted')}
            >
              <CardHeader>
                <Uncomplete className={style.svg} />
              </CardHeader>
              <Typography style={{textAlign: "center", padding: "14px 0px 6px 0px", fontSize: 18}}>
                <span style={{padding: "0px 12px"}}>{strings.uncompleteads}</span>
                :
                <span style={{padding: "0px 12px"}}>{uncompletedAds == null ?
                  <CircularProgress /> : uncompletedAds.length}</span>
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default MyAds
