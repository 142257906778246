import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthLayout from "../../layouts/AuthLayout";
import strings from "../../lang/lang";
import { Formik, Form } from "formik";
import AuthField from "../../components/auth/AuthField";
import AuthButton from "../../components/auth/AuthButton";
import { useHistory } from "react-router";
import { resetPasswordApi } from "../../api";
import * as Yup from "yup";


function NewPass(props) {
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  //const [email, setEmail] = useState(localStorage.getItem("email"));
  const history = useHistory();
  const initialValues = { 
    password: "",
    password_confirmation: "",
     };
 
     const validationSchema = Yup.object({
        password: Yup.string().required(strings.pwdRequired),
        password_confirmation: Yup.string()
          .required(strings.confirmPwdRequired)
          .oneOf([Yup.ref("password")], strings.pwdsNotMatch)
      });

  const handleNewPassword = (values) => {
    values.email = localStorage.getItem("email");

    resetPasswordApi(values)
       .then((res) => {
        if (res.data.status){
         history.push("/done");
       }
       else {
        alert(res.data.error.password)
        history.push("/resetPassword");
      }
    })
    .catch((err) => console.log(err));
      history.push("/resetPassword");
};

  useEffect(() => {
    setLang(localStorage.getItem("lang"));
  }, [props.onChange]);
  
  return (
    <AuthLayout props={props}>
      <div className="flex flex-col items-center font-medium text-xl">
        <h1 className="mt-7 mb-6 xs:mb-10 text-green-600 font-semibold tracking-wide text-2xl xs:text-3xl lg:text-4xl">
          {strings.NewPassMsg1}
        </h1>
        <div>
          <img
            src="/images/email.svg"
            alt="email-sent"
            title="email sent!"
            className="w-full h-64"
          />
        </div>
        <div className="flex flex-col mt-10 text-gray-700 text-base xs:text-lg sm:text-xl">
          <span>{strings.NewPassMsg2}</span>
          <Formik
            initialValues={initialValues}
            onSubmit={handleNewPassword}
            validationSchema={validationSchema}
          >
            {({ touched, errors, dirty }) => (
              <Form className="px-3 mt-10">
                <AuthField
                  label={strings.labelNewPass}
                  type="text"
                  name="password"
                  fieldErr={
                    touched.password &&
                    errors.password
                      ? errors.password
                      : null
                  }
                />
                 <AuthField
                  label={strings.labelReNewPass}
                  type="text"
                  name="password_confirmation"
                  fieldErr={
                    touched.password_confirmation &&
                    errors.password_confirmation
                      ? errors.password_confirmation
                      : null
                  }
                />
                <AuthButton
                  btnText={strings.resetPass}
                  lang={lang}
                  linkText={strings.backToLogin}
                  linkPath="/login"
                  isDisabled={!dirty}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </AuthLayout>
  );
}

export default NewPass;
