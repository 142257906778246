import React, { useState,useReducer,createContext } from 'react';
import { authReducer } from '../reducers/authReducer';


export const AuthContext= createContext()


function AuthContextProvider({ children }) {

    const [authState, dispatch] = useReducer(authReducer, {user: null})

    return (
         <AuthContext.Provider value={{ authState, dispatch }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContextProvider
