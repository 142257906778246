import axios from 'axios';
import { LIVE_URL as url } from '../utils/helpers';
const lang = localStorage.getItem('lang')
/*
* user
* type 1 is earner
* type 2 is publisher
*/


export const getCountries = () => {
    return axios.get(`${url}/country/list/${lang}`)
        .then((result) => {
            return result.data;
        })
}

export const getUserJobs = () => {
    return axios.get(`${url}/users_jobs/list/${lang}`)
        .then((result) => {
            return result.data;
        })
}

export const getCategories = () => {
    return axios.get(`${url}/categories/${lang}`)
        .then((result) => {
            return result.data;
        })
}

export const getStates = (id) => {
    return axios.get(`${url}/city/list/${lang}`)
        .then((result) => {
            return result.data;
        })
}

export const getNeighborhood = (id) => {
    return axios.get(`${url}/neighborhood/list/${lang}`)
        .then((result) => {
            return result.data;
        })
}

export const getStateByCountryId = (id) => {
    return axios.get(`${url}/city/getByCountryId/${id}/${lang}`)
        .then((result) => {
            return result.data;
        })
}


