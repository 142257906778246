import { Button } from "@material-ui/core"
import React, { useState, useContext, useEffect } from "react"
import {
  FaCamera,
  FaFacebookF,
  FaLinkedin,
  FaMobileAlt,
  FaPlaneDeparture,
  FaTwitter,
  FaUser,
  FaMinusCircle,
} from "react-icons/fa"
import { MdDateRange, MdLocationOn, MdPublic } from "react-icons/md"
import ProfileInfoHead from "../../components/ProfileInfoHead"
import { AuthContext } from "../../contexts/authContext"
import strings from "../../lang/lang"
import styles from "../../modules/profile.module.css"
import EditProfileModal from "../../components/modals/EditProfileModal"
import { string } from "yup"
import { BiMaleSign, BiFemaleSign } from "react-icons/bi"

function Profile() {
  const storedToken = localStorage.getItem("authToken")

  const { authState } = useContext(AuthContext)
  const lang = localStorage.getItem("lang")

  const { user } = authState
  const [personalInfo, setPersonalInfo] = useState(true)
  const [companyInfo, setCompanyInfo] = useState(true)
  const [editModal, setEditModal] = useState(false)

  if (editModal) window.document.body.style.overflow = "hidden"
  else window.document.body.style.overflow = "scroll"

  useEffect(() => {
    if (!storedToken) window.location.pathname="/login"
  }, [storedToken])


  return (
    <>
      {editModal && (
        <EditProfileModal
          setModal={setEditModal}
          countryId={user?.country_id || null}
        />
      )}
      <div
        className={`${styles.profilePage} transform translate-y-12 xl:translate-y-36 h-screen bg-white select-none`}
      >
        <div className={`${styles.profileHead} relative w-full shadow-lg`}>
          <div className="w-full absolute">
            {user && user.cover_img ? (
              <img
                src={user.cover_img}
                alt="cover"
                className={`${styles.profileCover} w-full`}
              />
            ) : (
              <img
                src="/images/default-cover.png"
                alt="cover"
                className={`${styles.profileCover} opacity-60 w-full`}
              />
            )}
          </div>
          <div className="absolute top-0 transform w-full flex flex-col items-center lg:items-start lg:flex-row space-x-7 xl:space-x-12 px-10 xl:px-32 translate-y-32 xs:translate-y-40 sm:translate-y-44 lg:translate-y-48 lg:mt-24">
            <div className="rounded-full border-4 border-transparent shadow-lg">
              {user && user.profile_img ? (
                <img
                  src={user.profile_img}
                  alt="user"
                  className="w-40 lg:w-48 h-40 lg:h-40 object-center rounded-full"
                />
              ) : (
                <FaUser className="w-32 sm:w-40 lg:w-48 h-32 sm:h-40 lg:h-48 object-center text-gray-600 bg-white rounded-full" />
              )}
            </div>
            <div className="w-full flex flex-col items-center lg:items-start  self-center transform lg:translate-y-10 mt-4">
              <div className="w-full flex flex-col lg:flex-row items-center lg:justify-between">
                <div className="sm:mx-2 font-semibold text-gray-700 text-2xl xs:text-3xl md:text-4xl lg:text-5xl capitalize">
                  {user?.name || "__"}
                </div>
                <button
                  dir={lang == "en" ? "rtl" : "ltr"}
                  onClick={() => setEditModal(true)}
                  className="mt-4 lg:mt-0 self-end px-4 py-2 bg-green-500 hover:bg-green-700 text-white font-semibold rounded-full focus:outline-none transition duration-300"
                >
                  {strings.edit}
                </button>
              </div>
              {/* <div className="text-center mx-1 mt-3 text-lg sm:text-xl md:text-2xl font-medium text-gray-500 capitalize">
              advertiser and publisher at abc. company
            </div>
            <div className="mt-3 text-base lg:text-xl text-gray-500 flex flex-col lg:flex-row lg:items-center space-y-4 lg:space-y-0 lg:space-x-6 lg:space-x-8">
              <div className="flex items-center mx-3" dir="ltr">
                <FaFacebookF className="text-xl lg:text-2xl text-blue-600" />
                <span className="mx-1 tracking-wide">kevensmith995</span>
              </div>
              <div className="flex items-center mx-3" dir="ltr">
                <FaLinkedin className="text-xl lg:text-2xl text-blue-800" />
                <span className="mx-1 tracking-wide">keven_smith</span>
              </div>
              <div className="flex items-center mx-3" dir="ltr">
                <FaTwitter className="text-xl lg:text-2xl text-blue-300" />
                <span className="mx-1 tracking-wide">keven_smith</span>
              </div>
            </div> */}
            </div>
          </div>
        </div>

        <div className="mt-6 lg:mx-4 py-6 px-3 lg:p-6">
          <ProfileInfoHead
            title={strings.personalInfo}
            setShowItem={setPersonalInfo}
            showItem={personalInfo}
          />

          {personalInfo && (
            <section className="p-4 flex flex-col space-y-7 border border-gray-200 shadow-sm bg-white">
              <div className="flex items-center">
                {user?.gender == "0" ? (
                  <BiMaleSign className="text-3xl text-red-400" />
                ) : user?.gender == "1" ? (
                  <BiFemaleSign className="text-3xl text-red-400" />
                ) : (
                  <FaMinusCircle className="text-3xl text-red-400" />
                )}
                <span className="mx-2 tracking-wide capitalize text-lg">
                  {user?.gender == "0"
                    ? strings.male
                    : user?.gender == "1"
                    ? strings.female
                    : "___" || "__"}
                </span>
              </div>
              <div className="flex items-center">
                <MdDateRange className="text-3xl text-blue-400" />
                <span className="mx-2 tracking-wide capitalize text-lg">
                  {user?.birthdate || "__"}
                </span>
              </div>
              {/* <div className="flex items-center">
                <MdLocationOn className="text-3xl text-red-400" />
                <span className="mx-2 tracking-wide capitalize text-lg">
                  {user?.country?.slug || "__"}
                </span>
              </div> */}
              <div className="flex items-center">
                <FaMobileAlt className="text-3xl text-gray-600" />
                <span className="mx-2 tracking-wide capitalize text-lg">
                  {user?.mobile || "__"}
                </span>
              </div>
            </section>
          )}
        </div>

        {/* <div className="mt-2 lg:mx-4 py-6 px-3 lg:p-6">
        <ProfileInfoHead
          title={strings.companyInfo}
          setShowItem={setCompanyInfo}
          showItem={companyInfo}
        />
        {companyInfo && (
          <section className="p-4 h-36 flex flex-col space-y-7 border border-gray-200 shadow-sm bg-white">
            <div className="flex items-center space-x-3">
              <FaPlaneDeparture
                className="text-3xl text-blue-400"
                style={{
                  transform:
                    localStorage.getItem("lang") === "ar"
                      ? "scaleX(-1)"
                      : "scaleX(1)",
                }}
              />
              <span className="mx-2 tracking-wide capitalize text-lg">
                ABC Company
              </span>
            </div>
            <div className="flex items-center">
              <MdPublic className="text-3xl text-red-400" />
              <span className="mx-2 tracking-wide text-lg">abc-site.com</span>
            </div>
          </section>
        )}
      </div> */}
      </div>
    </>
  )
}

export default Profile
