import { TextField } from "@material-ui/core";
import { Field } from "formik";
import React from "react";

function AuthField({ fieldErr, ...props }) {
  return (
    <div className="mt-3 xs:mt-4 py-2 lg:mx-3">
      <Field
        as={TextField}
        fullWidth
        {...(fieldErr && { error: true, helperText: fieldErr })}
        {...props}
        style={{fontSize: 20}}
      />
    </div>
  );
}

export default AuthField;
