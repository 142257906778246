import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
  Container,
  CircularProgress,
  LinearProgress,
  Modal,
  CardHeader,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
import { Field, Form, Formik } from "formik";
import { MdPhoto, MdVideoLibrary } from "react-icons/md";
import { useHistory } from "react-router";
import { CardBody, CardSubtitle, CardTitle } from "reactstrap";
import * as Yup from "yup";
import { savePrice } from "../../store/actions/paymentActions";
import EditAudience from "../../components/audience/Index";
import { AudienceContext } from "../../contexts/audience";
import useStyles from "../../jsxStyles/publisher";
import strings from "../../lang/lang";
import { getCategories } from "../../routes/auth";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SubscriptionBar from "../../components/ads/SubscriptionBar";
import {
  createAdApi,
  publisherAdApi,
  subscribtionsApi,
  saveAudienceApi,
} from "../../api";
import { useSelector } from "react-redux";
import { createQuestionApi, getProductQuestionsById } from "../../api";
import { MdDone } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";
import contactInfo from "../../assets/img/img/contact_info.gif";
import video from "../../assets/img/img/video.gif";
import Q from "../../assets/img/img/Q.gif";
import share from "../../assets/img/img/share.gif";
import sing_up from "../../assets/img/img/sing up.gif";
import map from "../../assets/img/img/map.gif";
import CloseIcon from "@mui/icons-material/Close";
import MediaVideoModal from "../../components/modals/MediaVideoModal";

const Alert = withReactContent(Swal);
const testFunction = () => {};
function CreateAd(props) {
  const lang = localStorage.getItem("lang");
  const storedToken = localStorage.getItem("authToken");
  const ad_list = JSON.parse(localStorage.getItem("ad-form"));
  const videoRef = useRef();
  const [imageError, setImageError] = useState(false);
  const [progress, setProgress] = useState(0);
  const [editAudience, setEditAudience] = useState(false);
  const [mediaType, setMediaType] = useState(0);
  const [imgPreview, setImgPreview] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [posterPreview, setPosterPreview] = useState(null);
  const [categories, setCategories] = useState([]);
  const [budget, setBudget] = useState(100);
  const [subscription_id, setSubscriptionId] = useState(0);
  const [costCollection, setCostCollection] = useState([]);
  const [ageCategory, setAgeCategory] = useState(0);
  const [incentive, setIncentive] = useState("nothing");
  const [gift_type, setGiftType] = useState("gift");
  const [incentive_amount, setIncentiveAmount] = useState("");
  const [incentive_users, setIncentiveUsers] = useState("");
  const [quota, setQuota] = useState({});
  const [gift, setGift] = useState(strings.no);
  const [views, setViews] = useState(0);
  const [tax, setTax] = useState(0);
  const [grossTotal, setGrossTotal] = useState(0);
  const [isSubmit, setIsSubmit] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
  const [errorsRes, setErrorsRes] = useState([]);
  const styles = useStyles();
  const dispatch = useDispatch();
  const audienceContext = useContext(AudienceContext);
  const history = useHistory();
  const [initialValues, setInitialValue] = useState({
    cat_id: "",
    website: "",
    name: "",
    des: "",
    keywords: "",
    image: "",
    logo: "",
    incentive: "nothing",
    incentive_amount: "",
  });
  const { match } = props;
  const answerField = useRef(null);
  const answerSubmit = useRef(null);
  const authState = useSelector((state) => state.user);

  const [question, setQuestion] = useState("");
  const [error, setError] = useState(null);
  const [isQuestion, setIsQuestion] = useState(false);

  const [answer, setAnswer] = useState("");
  const [trueAnswer, setTrueAnswer] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [countWeb, setCountWeb] = useState(0);
  const [count5to20, setCount5to20] = useState(0);
  const [count20to50, setCount20to50] = useState(0);
  const [countKey, setCountKey] = useState(0);
  const [fileSiz, setFileSiz] = useState(0);

  

  useEffect((__) => {
    if (ad_list) {
      delete ad_list.image;
      delete ad_list.logo;
      localStorage.setItem("ad-form", JSON.stringify(ad_list));
      //localStorage.setItem("product_id", res?.data?.data?.id)
    }
  }, []);

  const validationSchema = Yup.object({
    // cat_id: Yup.string().required(strings.requiredCategory),
    // website: Yup.string().url(strings.requiredWebsite),
    // name: Yup.string()
    //   .required(strings.requiredTitle)
    //   .min(5, strings.titleLimit)
    //   .max(25, strings.titleMaxLimit),
    // des: Yup.string()
    //   .required(strings.requiredDes)
    //   .min(20, strings.desLimit)
    //   .max(50, strings.desMaxLimit),
    //keywords: Yup.string().required(strings.requiredKeywords),
    // image: Yup.mixed().required(strings.requiredMedia),
    // logo: Yup.mixed().required(strings.requiredPoster),
    // incentive_amount:Yup.number().integer("Enter only numbers").test(
    //   "onlyNumbers",
    //   "only numbers please",
    //   (value) => /^[0-9]*$/.test(value.toString())
    // )
  });

  const mediaPicker = useRef();
  const posterPicker = useRef();
  const urlRef = useRef();

  const {
    selectedCountries,
    selectedStates,
    selectedNeighborhood,
    selectedJobs,
    gender,
    age,
  } = useContext(AudienceContext);

  const triggerPosterPicker = () => {
    posterPicker.current.accept = ".jpg,.png";
    posterPicker.current.click();
  };

  const triggerMediaPicker = (type) => {
    setMediaType(type);
    setImgPreview(null);
    setVideoPreview(null);
    if (type == 0) {
      mediaPicker.current.accept = ".jpg,.png";
      mediaPicker.current.click();
    } else {
      mediaPicker.current.accept = ".mp4";
      mediaPicker.current.click();
    }
  };

  const handlePosterChange = (poster, setVal) => {
    if (poster) {
      setVal("logo", poster);
      setPosterPreview(URL.createObjectURL(poster));
    }
  };

  const handleUrlChange = (e,setVal)=>{
    setVideoPreview(e.target.value);
    setVideoModal(false)
    setVal("image", e.target.value);
    setMediaType(1);
  }

  const handleMediaChange = (file, setVal) => {
    if (file) {
      let fileSize = file.size / 1000; //converting bytes into kb
      setVal("image", file);
      let size = 2000;
      let fileSiz = (file.size / 1000000).toFixed(2);
      setFileSiz(fileSiz);
      if (mediaType == 1) size = 10000;
      if (fileSize <= size) {
        if (mediaType == 1) {
          if (["video/mp4"].includes(file.type)) {
            setImgPreview(null);
            setVideoPreview(URL.createObjectURL(file));
            videoPreview && videoRef.current.load();
            setVideoModal(false);
          } else {
            Alert.fire(strings.videoImageSize);
          }
        } else if (mediaType == 0) {
          if (["image/png", "image/jpg", "image/jpeg"].includes(file.type)) {
            setVideoPreview(null);
            setImgPreview(URL.createObjectURL(file));
          } else {
            Alert.fire(strings.videoImageSize);
          }
        }
      } else {
        Alert.fire(strings.videoImageSize);
      }
    }
  };

  const handleCreatedAd = (values) => {
    setIsSubmit(true);
    if (age[0] >= 18 && age[1] <= 25) setAgeCategory(1);
    else if (age[1] <= 33) setAgeCategory(2);
    else if (age[1] <= 41) setAgeCategory(3);
    else if (age[1] <= 49) setAgeCategory(4);
    else if (age[1] <= 57) setAgeCategory(5);
    else if (age[1] <= 65) setAgeCategory(6);
    else if (age[1] > 65) setAgeCategory(7);
    else alert("select a valid age in audience settings!");
    const selectedJobsList = selectedJobs.map((job) => job.title);
    const demographic = {
      gender,
      age: ageCategory,
      users_jobs: selectedJobsList.join(",") ?? "",
      country: selectedCountries.join(",") ?? "",
      state: selectedStates.join(",") ?? "",
      neighborhood: selectedNeighborhood.join(",") ?? "",
    };
    if (values.logo == "") {
      Alert.fire(strings.requiredPoster);
      setIsSubmit(false);
      return;
    }
    if (values.image == "") {
      Alert.fire(strings.requiredMedia);
      setIsSubmit(false);
      return;
    }
    if (values.cat_id == "") {
      Alert.fire(strings.requiredCategory);
      setIsSubmit(false);
      return;
    }
    if (values.name.length < 5) {
      Alert.fire(strings.titleLimit1);
      setIsSubmit(false);
      return;
    }
    if (values.name.length > 20) {
      Alert.fire(strings.titleMaxLimit1);
      setIsSubmit(false);
      return;
    }
    if (values.des.length < 20) {
      Alert.fire(strings.titleLimit2);
      setIsSubmit(false);
      return;
    }

    if (values.des.length > 50) {
      Alert.fire(strings.titleMaxLimit2);
      setIsSubmit(false);
      return;
    }
    if (question == "") {
      Alert.fire(strings.quesRequired);
      setIsSubmit(false);
      return;
    }
    if (answers < 3) {
      Alert.fire(strings.ansRequired);
      setIsSubmit(false);
      return;
    }
    if (values.keywords.length == 0) {
      Alert.fire(strings.requiredKeywords);
      setIsSubmit(false);
      return;
    }

    // values.subscription_id = quota.id
    values.mediatype = mediaType;
    values.incentive_amount = quota.price;
    values.incentive_users = quota.earningUser;
    values.demographic = demographic;
    const formData = new FormData();
    formData.append("cat_id", values.cat_id);
    formData.append("name", values.name);
    formData.append("des", values.des);
    formData.append("website", values.website);
    formData.append("keywords", values.keywords);
    formData.append("demographic", values.demographic);
    formData.append("subscription_id", subscription_id);
    formData.append("mediatype", values.mediatype);
    formData.append("image", values.image);
    formData.append("logo", values.logo);
    formData.append("incentive", incentive);
    formData.append("incentive_amount", incentive_amount);
    formData.append("incentive_users", incentive_users);
    formData.append("gift_type", gift_type);
    createAdApi(storedToken, formData, setProgress)
      .then((res) => {
        const formDat = new FormData();
        formDat.append(
          "country",
          selectedCountries.map((item) => item.name)
        );
        formDat.append(
          "state",
          selectedStates.map((item) => item.name)
        );
        formDat.append(
          "neighborhood",
          selectedNeighborhood.map((item) => item.name)
        );
        formDat.append(
          "users_jobs",
          selectedJobs.map((item) => {
            return item.id;
          })
        );
        formDat.append("age", age);
        formDat.append("gender", gender);
        formDat.append("product_id", res?.data?.data?.id);
        saveAudienceApi(storedToken, formDat);

        
        if (!res?.data?.status) {
          setErrorsRes(res?.data?.error);
          return;
        }
        let ad_list = [{ product_id: res?.data?.data?.id, budget }];
        dispatch(savePrice(grossTotal == 0 ? budget : grossTotal));
        const setFunc = async () => {
          await localStorage.setItem("ad_list", JSON.stringify(ad_list));
          if (res?.data?.status) {
            createQuestionApi(storedToken, {
              name: question,
              answers,
              product_id: res?.data?.data?.id,
              user_id: authState?.user?.id,
              true_answer: trueAnswer,
            })
              .then(__ => {
                localStorage.setItem('product',JSON.stringify(res?.data?.data))                
                window.location.href = `/publisher/ad_response?product_id=${res?.data?.data?.id}`;
                setIsSubmit(false);
              })
              .catch((err) => {
                setIsSubmit(false);
              });
          }
        };
        setFunc();
      })
      .catch((err) => {
        setIsSubmit(false);
      });
  };

  const handleGiftValueChange = (val, setVal, gift_type) => {
    if (gift_type !== "nothing") {
      setVal("incentive_amount", val.target.value);
      let price = parseFloat(val.target.value) + parseFloat(budget);
      price && setGrossTotal(price);
    } else {
      let price = parseFloat(val.target.value) - parseFloat(budget);
      price && setGrossTotal(price);
      setVal("incentive_amount", 0);
    }
  };

  useEffect(() => {
    if (costCollection.length > 0) {
      let obj = costCollection.find((obj) => obj.price == budget);
      if (obj) {
        let percentage_value = (obj.price / 100) * obj.tax;
        setViews((percentage_value / 0.15).toFixed(0));
        let tax = (obj.price / 100) * 15;
        setTax(tax);
        
        setGrossTotal(obj.price);
        setGift(obj.price >= 700 ? strings.yes : strings.no);
      }
    }
  }, [costCollection, budget]);

  useEffect(() => {
    getCategories().then((result) => {
      setCategories(result.data);
    });
  }, []);

  testFunction();
  useEffect((__) => {
    /*getProductQuestionsById(storedToken,match.params.id)
    .then(res=>{
    })*/
  });

  useEffect(() => {
    if (!storedToken) window.location.pathname = "/login";
  }, [storedToken]);

  return (
    <div className={styles.page}>
      <Container
        style={{
          textAlign: lang === "en" ? "left" : "right",
          direction: lang === "en" ? "ltr" : "rtl",
        }}
      >
        {editAudience && (
          <EditAudience setEditAudience={setEditAudience} create={true} />
        )}
        <Box>
          <Grid container style={{ display: editAudience ? "none" : "block" }}>
            <Grid item xs={12}>
              <div className="my-3">
                <Typography variant="h4" className={styles.createAd}>
                  {strings.createad}
                </Typography>
              </div>
            </Grid>

            {/* Start of Formik Form */}
            <Formik
              initialValues={initialValues}
              onSubmit={handleCreatedAd}
              mode="onChange"
              validationSchema={validationSchema}
            >
              {({ errors, touched, setFieldValue, values }) => {
                return (
                  <Form className="w-full">
                    <Grid container spacing={2}>
                      <Grid item xs={1} md={2}></Grid>
                      <Grid item xs={10} md={8}>
                        {/* Start Ad Poster Picker */}
                        <Card raised className={styles.cardsCreadeAd}>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              style={{ margin: "auto" }}
                              className={styles.hideOnSm}
                            >
                              <img
                                src={contactInfo}
                                alt="logo"
                                className="w-30 xs:w-60 sm:w-60"
                                style={{ borderRadius: 16 }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              style={{ margin: "auto" }}
                            >
                              <CardBody className={styles.cardBodySM}>
                                <CardTitle>
                                  <Grid container>
                                    <Grid xs={11}>
                                      <Typography className={styles.cardTitle}>
                                        {strings.chooseAdPoster}
                                      </Typography>
                                    </Grid>
                                    <Grid xs={1} style={{ margin: "auto" }}>
                                      <Tooltip
                                        title={strings.chooseSubAdPoster}
                                        aria-label="add"
                                      >
                                        <InfoIcon
                                          variant="outlined"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </Tooltip>
                                    </Grid>
                                  </Grid>
                                </CardTitle>
                                <div
                                  className="w-full flex justify-center"
                                  style={{ paddingBottom: 20 }}
                                >
                                  {posterPreview && (
                                    <div className="mt-4 shadow rounded-lg overflow-hidden">
                                      <img
                                        width="200"
                                        height="200"
                                        src={posterPreview}
                                        alt="poster-preview"
                                        className="h-full rounded-lg"
                                      />
                                    </div>
                                  )}
                                </div>
                                <input
                                  type="file"
                                  name="logo"
                                  ref={posterPicker}
                                  onChange={(e) =>
                                    handlePosterChange(
                                      e.target.files[0],
                                      setFieldValue
                                    )
                                  }
                                  className="hidden"
                                />
                                <div className="w-full flex justify-around">
                                  <Button
                                    color="primary"
                                    onClick={triggerPosterPicker}
                                    startIcon={<MdPhoto />}
                                    variant="contained"
                                    className={styles.buttonCreateAd}
                                  >
                                    {strings.poster}
                                  </Button>
                                </div>
                                {touched.logo && errors.logo && (
                                  <FormHelperText style={{ color: "red" }}>
                                    {errors.logo}
                                  </FormHelperText>
                                )}
                              </CardBody>
                            </Grid>
                          </Grid>
                        </Card>
                        {/* End Ad Poster Picker */}
                        {/* Start Ad Media Picker */}
                        <Card raised className={styles.cardsCreadeAd}>
                          {/* style={{backgroundImage:  `url("/images/pngtree-futuristic-shape-abstract-background-chemistry-technology-concept-for-website-picture-image_1250295.jpeg")` */}
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              style={{ margin: "auto" }}
                            >
                              <CardBody>
                                <CardTitle>
                                  <Typography className={styles.cardTitle}>
                                    {strings.chooseAdMedia}
                                  </Typography>
                                </CardTitle>
                                <input
                                  type="file"
                                  name="image"
                                  ref={mediaPicker}
                                  onChange={(e) =>
                                    handleMediaChange(
                                      e.target.files[0],
                                      setFieldValue
                                    )
                                  }
                                  className="hidden"
                                />
                                <div>
                                  <Typography
                                    className={styles.chooseAdSubTitlss}
                                  >
                                    {strings.chooseVidOrImg}
                                  </Typography>
                                </div>
                                <div>
                                  <Typography
                                    className={styles.chooseAdSubTitle}
                                  >
                                    {strings.videoImageSize}
                                  </Typography>
                                </div>
                                <div>
                                  <Typography
                                    className={styles.chooseAdSubTitle}
                                  >
                                    {strings.supportedFormats}
                                  </Typography>
                                </div>
                                <div>
                                  {fileSiz == 0 ? (
                                    <Typography
                                      style={{
                                        color: "red",
                                        textAlign: "center",
                                        padding: "12px 0px",
                                      }}
                                    >
                                      {strings.fileSize} : {fileSiz}{" "}
                                      {strings.Mb}
                                    </Typography>
                                  ) : (
                                    <Typography
                                      style={{
                                        color: "blue",
                                        textAlign: "center",
                                        padding: "12px 0px",
                                      }}
                                    >
                                      {strings.fileSize} : {fileSiz}{" "}
                                      {strings.Mb}
                                    </Typography>
                                  )}
                                </div>
                                <div
                                  className="w-full flex justify-center"
                                  style={{ paddingBottom: 20 }}
                                >
                                  {imgPreview && (
                                    <div className="mt-4  shadow rounded-lg overflow-hidden">
                                      <img
                                        width="200"
                                        height="200"
                                        src={imgPreview}
                                        alt="img-preview"
                                        className="h-full rounded-lg"
                                      />
                                    </div>
                                  )}
                                  {videoPreview && (
                                    <div className="mt-4  shadow rounded-lg overflow-hidden">
                                      <video
                                        ref={videoRef}
                                        width="200"
                                        height="200px"
                                        autoPlay
                                        loop
                                        className="rounded-lg"
                                      >
                                        <source src={videoPreview} />
                                      </video>
                                    </div>
                                  )}
                                </div>
                                <div className="w-full flex justify-around">
                                  <Button
                                    color="primary"
                                    onClick={() => triggerMediaPicker(0)}
                                    startIcon={<MdPhoto />}
                                    variant="contained"
                                    className={styles.buttonCreateAd}
                                  >
                                    {strings.image}
                                  </Button>
                                  <Button
                                    color="primary"
                                    onClick={() => setVideoModal(!videoModal)}
                                    startIcon={<MdVideoLibrary />}
                                    variant="contained"
                                    className={styles.buttonCreateAd}
                                  >
                                    {strings.video}
                                  </Button>
                                </div>
                                {(touched.image && errors.image) ||
                                  (imageError && (
                                    <FormHelperText style={{ color: "red" }}>
                                      {errors.image}
                                    </FormHelperText>
                                  ))}
                              </CardBody>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              style={{ margin: "auto" }}
                              className={styles.hideOnSm}
                            >
                              <img
                                src={video}
                                className="w-30 xs:w-60 sm:w-60 "
                                alt="logo"
                                style={{ borderRadius: 16 }}
                              />
                            </Grid>
                          </Grid>
                        </Card>
                        {/* End Ad Media Picker */}
                        {/* Start Category Select Card  */}
                        <Card raised className={styles.cardsCreadeAd}>
                          {/* style={{backgroundImage:  `url("/img/662ccd92f707452c20deeaccfee2b5c5.jpeg")`}}> */}
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              style={{ margin: "auto" }}
                              className={styles.hideOnSm}
                            >
                              <img
                                src={video}
                                className="w-30 xs:w-60 sm:w-60 "
                                alt="logo"
                                style={{ borderRadius: 16 }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              style={{ margin: "auto" }}
                            >
                              <CardTitle>
                                <Typography className={styles.cardTitle}>
                                  {strings.selectAdCategory}
                                </Typography>
                              </CardTitle>
                              <CardBody>
                                <FormControl className="w-full">
                                  <InputLabel className={styles.adcategory}>
                                    {strings.adcategory}
                                  </InputLabel>
                                  <Field
                                    as={Select}
                                    name="cat_id"
                                    {...(touched.cat_id &&
                                      errors.cat_id && {
                                        error: true,
                                        helpertext:
                                          touched.cat_id && errors.cat_id,
                                      })}
                                    className="w-full"
                                  >
                                    <MenuItem
                                      value=""
                                      disabled
                                      style={{
                                        direction:
                                          lang === "en" ? "ltr" : "rtl",
                                      }}
                                    >
                                      {strings.selectAdCategory}
                                    </MenuItem>
                                    {categories.map((category, index) => (
                                      <MenuItem
                                        key={index}
                                        value={category.id}
                                        style={{
                                          direction:
                                            lang === "en" ? "ltr" : "rtl",
                                        }}
                                      >
                                        {category.name}
                                      </MenuItem>
                                    ))}
                                  </Field>
                                  {touched.cat_id && errors.cat_id && (
                                    <FormHelperText style={{ color: "red" }}>
                                      {errors.cat_id}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </CardBody>
                            </Grid>
                          </Grid>
                        </Card>

                        {/* Start Ad Info Inputs */}
                        <div className="mt-6">
                          <Card raised className={styles.cardsCreadeAd}>
                            {/* style={{backgroundImage:  `url("/images/pngtree-green-gradient-white-simple-circle-pattern-abstract-background-image_756686.jpeg")`}}> */}
                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                md={6}
                                style={{ margin: "auto" }}
                              >
                                <CardTitle>
                                  <Typography className={styles.cardTitle}>
                                    {strings.additionalInfo}
                                  </Typography>
                                </CardTitle>
                                <div>
                                  <Typography
                                    className={styles.chooseAdSubTitls}
                                  >
                                    {strings.chooseAdSubPoster}
                                  </Typography>
                                </div>
                                <CardBody>
                                  <Grid container spacing={1}>
                                    <Grid container>
                                      <Grid item xs={9}>
                                        <Field
                                          as={TextField}
                                          name="website"
                                          fullWidth
                                          label={strings.website}
                                          placeholder="http://example.com"
                                          {...(touched.website &&
                                            errors.website && {
                                              error: true,
                                              helpertext:
                                                touched.website &&
                                                errors.website,
                                            })}
                                          className={styles.websiteCreateAd}
                                          onKeyUp={(e) =>
                                            setCountWeb(e.target.value.length)
                                          }
                                        />
                                        {touched.website && errors.website && (
                                          <FormHelperText
                                            style={{ color: "red" }}
                                          >
                                            {errors.website}
                                          </FormHelperText>
                                        )}
                                      </Grid>
                                    </Grid>
                                    <Grid container>
                                      <Grid item xs={9}>
                                        <Field
                                          as={TextField}
                                          name="name"
                                          fullWidth
                                          label={strings.adTitle}
                                          {...(touched.name &&
                                            errors.name && {
                                              error: true,
                                              helpertext:
                                                touched.name && errors.name,
                                            })}
                                          className={styles.websiteCreateAd}
                                          onKeyUp={(e) =>
                                            setCount5to20(e.target.value.length)
                                          }
                                        />
                                        {touched.name && errors.name ? (
                                          <FormHelperText
                                            style={{ color: "red" }}
                                          >
                                            {errors.name}
                                          </FormHelperText>
                                        ) : (
                                          <FormHelperText
                                            style={{ color: "black" }}
                                          >
                                            {strings.charactor5to10}
                                          </FormHelperText>
                                        )}
                                      </Grid>
                                      <Grid
                                        item
                                        xs={3}
                                        style={{
                                          padding: "0px 5px",
                                          marginTop: 20,
                                        }}
                                      >
                                        {count5to20 < 5 || count5to20 > 20 ? (
                                          <Typography style={{ color: "red" }}>
                                            {count5to20}
                                            {"/20"}
                                          </Typography>
                                        ) : (
                                          <Typography
                                            style={{ color: "black" }}
                                          >
                                            {count5to20}
                                            {"/20"}
                                          </Typography>
                                        )}
                                      </Grid>
                                    </Grid>
                                    <Grid container>
                                      <Grid item xs={9}>
                                        <Field
                                          as={TextareaAutosize}
                                          name="des"
                                          rowsMax={4}
                                          label={strings.adDescription}
                                          placeholder={strings.adDescription}
                                          style={{
                                            width: "100%",
                                            outline: "none",
                                          }}
                                          className={`${
                                            touched.des && errors.des
                                              ? "border-red-500 border-b-2"
                                              : "border-gray-500 border-b"
                                          } focus:border-green-500 mt-3`}
                                          onKeyUp={(e) =>
                                            setCount20to50(
                                              e.target.value.length
                                            )
                                          }
                                        />
                                        {touched.des && errors.des ? (
                                          <FormHelperText
                                            style={{ color: "red" }}
                                          >
                                            {errors.des}
                                          </FormHelperText>
                                        ) : (
                                          <FormHelperText
                                            style={{ color: "black" }}
                                          >
                                            {strings.charactor20to50}
                                          </FormHelperText>
                                        )}
                                      </Grid>
                                      <Grid
                                        item
                                        xs={3}
                                        style={{
                                          padding: "0px 5px",
                                          marginTop: 20,
                                        }}
                                      >
                                        {count20to50 < 20 ||
                                        count20to50 > 50 ? (
                                          <Typography style={{ color: "red" }}>
                                            {count20to50}
                                            {"/50"}
                                          </Typography>
                                        ) : (
                                          <Typography
                                            style={{ color: "black" }}
                                          >
                                            {count20to50}
                                            {"/50"}
                                          </Typography>
                                        )}
                                      </Grid>
                                    </Grid>
                                    <Grid container>
                                      <Grid item xs={9}>
                                        <Field
                                          as={TextField}
                                          fullWidth
                                          name="keywords"
                                          label={strings.adKeywords}
                                          {...(touched.keywords &&
                                            errors.keywords && {
                                              error: true,
                                              helpertext:
                                                touched.keywords &&
                                                errors.keywords,
                                            })}
                                          className={styles.websiteCreateAd}
                                          onKeyUp={(e) =>
                                            setCountKey(e.target.value.length)
                                          }
                                        />
                                        {touched.keywords &&
                                          errors.keywords && (
                                            <FormHelperText
                                              style={{ color: "red" }}
                                            >
                                              {errors.keywords}
                                            </FormHelperText>
                                          )}
                                      </Grid>
                                      <Grid
                                        item
                                        xs={3}
                                        style={{
                                          padding: "0px 5px",
                                          marginTop: 20,
                                        }}
                                      >
                                        {countKey > 20 ? (
                                          <Typography style={{ color: "red" }}>
                                            {countKey}
                                            {"/20"}
                                          </Typography>
                                        ) : (
                                          <Typography
                                            style={{ color: "black" }}
                                          >
                                            {countKey}
                                            {"/20"}
                                          </Typography>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </CardBody>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={4}
                                style={{ margin: "auto" }}
                                className={styles.hideOnSm}
                              >
                                <img
                                  src={contactInfo}
                                  className="w-30 xs:w-60 sm:w-60 "
                                  alt="logo"
                                  style={{ borderRadius: 16 }}
                                />
                              </Grid>
                            </Grid>
                          </Card>
                        </div>
                        {/* End Ad Info Inputs */}

                        {/* Start Audience Card */}
                        <Card
                          raised
                          className={styles.cardsCreadeAd}
                          style={{
                            textAlign: lang === "en" ? "left" : "right",
                          }}
                        >
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              style={{ margin: "auto" }}
                              className={styles.hideOnSm}
                            >
                              <img
                                src={map}
                                className="w-30 xs:w-60 sm:w-60 "
                                alt="logo"
                                style={{ borderRadius: 16 }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              style={{ margin: "auto" }}
                            >
                              <CardBody style={{ padding: 0 }}>
                                <CardTitle>
                                  <Grid container>
                                    <Grid xs={1} style={{ margin: "auto" }}>
                                      <Tooltip
                                        title={strings.editAudienceDescription}
                                        aria-label="add"
                                      >
                                        <InfoIcon
                                          style={{ cursor: "pointer" }}
                                          variant="outlined"
                                        />
                                      </Tooltip>
                                    </Grid>
                                    <Grid xs={11}>
                                      <Typography className={styles.cardTitle}>
                                        {strings.audience}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </CardTitle>
                                <CardSubtitle>
                                  {strings.whocanseead}
                                </CardSubtitle>
                                <Card className={styles.detailCard}>
                                  <div className="detailTop">
                                    <CardTitle className={styles.detailTitles}>
                                      {strings.audiencedetails}
                                    </CardTitle>
                                    <Button
                                      onClick={() => setEditAudience(true)}
                                      variant="contained"
                                      color="primary"
                                      className={styles.buttonCreateAd}
                                      endIcon={<EditIcon />}
                                      size="small"
                                    >
                                      {strings.edit}
                                    </Button>
                                  </div>
                                  <Typography className={styles.detailTitles}>
                                    {strings.gender + " : "} -{" "}
                                    {gender == 0 && " Male"}
                                    {gender == 1 && " Female"}
                                    {gender == 2 && strings.all}
                                  </Typography>
                                  <Typography className={styles.detailTitles}>
                                    {strings.age + " : "} {age[0]} - {age[1]}
                                  </Typography>
                                  <Grid
                                    item
                                    xs={12}
                                    className={styles.locationBox}
                                  >
                                    <Typography className={styles.detailTitles}>
                                      {strings.countries + " : "}
                                    </Typography>
                                    {selectedCountries.length > 0
                                      ? selectedCountries.map((item, index) => {
                                          return (
                                            <Chip
                                              key={index}
                                              color="primary"
                                              label={item?.name}
                                            />
                                          );
                                        })
                                      : strings.nocountry}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    className={styles.locationBox}
                                  >
                                    <Typography className={styles.detailTitles}>
                                      {strings.state + " : "}
                                    </Typography>
                                    {selectedStates.length > 0
                                      ? selectedStates.map((item, index) => {
                                          return (
                                            <Chip
                                              key={index}
                                              color="primary"
                                              label={item?.name}
                                            />
                                          );
                                        })
                                      : strings.nostate}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    className={styles.locationBox}
                                  >
                                    <Typography className={styles.detailTitles}>
                                      {strings.neighborhood + " : "}
                                    </Typography>
                                    {selectedNeighborhood.length > 0
                                      ? selectedNeighborhood.map(
                                          (item, index) => {
                                            return (
                                              <Chip
                                                key={index}
                                                color="primary"
                                                label={item?.name}
                                              />
                                            );
                                          }
                                        )
                                      : strings.noneighborhood}
                                  </Grid>
                                  <Typography className={styles.detailTitles}>
                                    {strings.userinterest + " : "}
                                  </Typography>
                                  <Grid
                                    item
                                    xs={12}
                                    className={styles.locationBox}
                                  >
                                    {selectedJobs.length > 0
                                      ? selectedJobs.map((item, index) => {
                                          return (
                                            <Chip
                                              color="primary"
                                              label={item.title}
                                            />
                                          );
                                        })
                                      : strings.nouserinterest}
                                  </Grid>
                                </Card>
                              </CardBody>
                            </Grid>
                          </Grid>
                        </Card>
                        {/* End Audience Card */}

                        {/* Start question Card */}
                        <Card
                          raised
                          className={styles.cardsCreadeAd}
                          style={{
                            textAlign: lang === "en" ? "left" : "right",
                          }}
                        >
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              style={{ margin: "auto" }}
                            >
                              <Container>
                                <Card
                                  raised
                                  style={{
                                    textAlign: lang == "ar" ? "right" : "left",
                                    border: "1px solid black",
                                    backgroundColor: "azure",
                                  }}
                                >
                                  <CardHeader
                                    title={strings.addQuestion}
                                    className={styles.addQuestion}
                                  />
                                  <div
                                    dir={lang == "ar" ? "rtl" : "ltr"}
                                    style={{
                                      textAlign:
                                        lang == "ar" ? "right" : "left",
                                    }}
                                  >
                                    <div>
                                      <div>
                                        <div>
                                          <Typography
                                            style={{ padding: "10px 0px" }}
                                          >
                                            {strings.typeQuestion}
                                          </Typography>
                                          <input
                                            type="text"
                                            placeholder={strings.typeQuestion}
                                            onKeyUp={() =>
                                              question
                                                ? setIsQuestion(true)
                                                : setIsQuestion(false)
                                            }
                                            className="w-11/12 lg:w-11/12 px-3 py-2 mb-3 text-sm text-gray-600 border-2 border-gray-400 rounded-lg focus:border-indigo-500 focus:outline-none"
                                            onChange={(e) =>
                                              setQuestion(e.target.value)
                                            }
                                          />
                                        </div>
                                        {isQuestion && (
                                          <div>
                                            <Typography>
                                              {strings.typeAnswers}
                                            </Typography>
                                            <Typography
                                              style={{ padding: "10px 12px" }}
                                            >
                                              <Grid container>
                                                * {strings.addQuesExp} (
                                                <MdDone />)
                                              </Grid>
                                            </Typography>
                                            <div>
                                              {answers.map((answer, idx) => (
                                                <div
                                                  key={idx}
                                                  className={`w-11/12 justify-between my-1 px-3 py-1.5 ${
                                                    trueAnswer === idx
                                                      ? "bg-green-100"
                                                      : "bg-indigo-50"
                                                  } text-gray-600 font-semibold text-sm rounded-lg border-2 border-indigo-100`}
                                                >
                                                  <Grid container>
                                                    <Grid
                                                      xs={1}
                                                      style={{ margin: "auto" }}
                                                    >
                                                      <IoCloseSharp
                                                        onClick={() => {
                                                          answers.length ===
                                                            1 &&
                                                            setTrueAnswer(0);
                                                          setAnswers(
                                                            answers.filter(
                                                              (_, index) =>
                                                                index !== idx
                                                            )
                                                          );
                                                        }}
                                                      />
                                                    </Grid>
                                                    <Grid
                                                      xs={10}
                                                      style={{ margin: "auto" }}
                                                    >
                                                      <span className="break-words w-7/12">
                                                        {answer}
                                                      </span>
                                                    </Grid>
                                                    <Grid
                                                      xs={1}
                                                      style={{ margin: "auto" }}
                                                    >
                                                      <div>
                                                        <MdDone
                                                          onClick={() =>
                                                            setTrueAnswer(idx)
                                                          }
                                                          className={`${
                                                            trueAnswer === idx
                                                              ? "text-green-600 text-2xl"
                                                              : "text-gray-400 text-xl"
                                                          } cursor-pointer font-bold transition duration-300`}
                                                        />
                                                      </div>
                                                    </Grid>
                                                  </Grid>
                                                </div>
                                              ))}
                                            </div>
                                            {answers.length < 4 && (
                                              <>
                                                <input
                                                  ref={answerField}
                                                  value={answer}
                                                  placeholder={
                                                    strings.typeAnswer
                                                  }
                                                  className="w-11/12 mt-2 px-3 py-2 text-sm text-gray-600 border-2 border-gray-400 rounded-lg focus:border-indigo-500 focus:outline-none"
                                                  onChange={(e) =>
                                                    setAnswer(e.target.value)
                                                  }
                                                ></input>

                                                {error && (
                                                  <FormHelperText
                                                    style={{ width: "70%" }}
                                                    error
                                                  >
                                                    {error}
                                                  </FormHelperText>
                                                )}
                                                <Grid
                                                  container
                                                  style={{ paddingTop: 10 }}
                                                >
                                                  <button
                                                    ref={answerSubmit}
                                                    onClick={() => {
                                                      answer &&
                                                        answers.length <= 4 &&
                                                        setAnswers([
                                                          ...answers,
                                                          answer,
                                                        ]);
                                                      setAnswer("");
                                                      answerField.current.focus();
                                                    }}
                                                    disabled={!answer}
                                                    className="self-end my-2 px-3 py-1.5 text-sm font-semibold rounded-full text-white bg-green-400 hover:bg-green-500 focus:outline-none"
                                                  >
                                                    <Typography
                                                      className={
                                                        styles.addMoreAnswer
                                                      }
                                                    >
                                                      {strings.addMoreAnswer}
                                                    </Typography>
                                                  </button>
                                                  <Grid
                                                    style={{ paddingLeft: 20 }}
                                                  />
                                                </Grid>
                                              </>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </Card>
                              </Container>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={4}
                              style={{ margin: "auto" }}
                              className={styles.hideOnSm}
                            >
                              <img
                                src={Q}
                                className="w-30 xs:w-60 sm:w-60 "
                                alt="logo"
                                style={{ borderRadius: 16 }}
                              />
                            </Grid>
                          </Grid>
                        </Card>
                        {/* End question Card */}
                      </Grid>
                      {/*  End Category Select Card */}
                      {/*  */}
                      <Grid item xs={1} md={2}></Grid>

                      <Grid item xs={1} md={2}></Grid>
                      <Grid item xs={10} md={8}>
                        {/* Start Ad Budget Slider */}
                        <Card raised className={styles.cardsCreadeAd}>
                          <CardBody>
                            <CardTitle className={styles.totalbudget}>
                              <Grid container>
                                <Grid xs={1}>
                                  <Tooltip
                                    title={strings.budgettooltip}
                                    aria-label="add"
                                  >
                                    <InfoIcon
                                      variant="outlined"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </Tooltip>
                                </Grid>
                                <Grid xs={11}>
                                  <Typography
                                    className={styles.cardTitle}
                                    style={{ paddingBottom: 34 }}
                                  >
                                    {strings.totalbudget}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardTitle>
                            <SubscriptionBar
                              {...{
                                errorsRes,
                                budget,
                                setBudget,
                                setSubscriptionId,
                                setIncentive,
                                incentive,
                                setGiftType,
                                gift_type,
                                incentive_amount,
                                setIncentiveAmount,
                                incentive_users,
                                setIncentiveUsers,
                                setGrossTotal,
                              }}
                            />
                          </CardBody>
                        </Card>
                      </Grid>
                      {/* End Of Media Picker  */}
                      <Grid item xs={1} md={2}></Grid>
                      <Grid item xs={1} md={2}></Grid>
                      {/* Start Form Button */}
                      <Grid item xs={12} md={8} style={{ textAlign: "center" }}>
                        <Box mb={7}>
                          {isSubmit ? (
                            <LinearProgress
                              variant="determinate"
                              value={progress}
                            />
                          ) : (
                            <Button
                              variant="contained"
                              type="submit"
                              color="primary"
                              size="large"
                              className={styles.buttonCreateAd}
                            >
                              {strings.createad}
                            </Button>
                          )}
                        </Box>
                      </Grid>
                      {/* End Form Button */}
                      <Grid item xs={1} md={2}></Grid>
                    </Grid>
                    {/* <PersistFormikValues storage="localStorage" name="ad-form"/> */}
                    <MediaVideoModal
                      setVideoPreview={setVideoPreview}
                      triggerMediaPicker={triggerMediaPicker}
                      errors={errors}
                      touched={touched}
                      mediaPicker={mediaPicker}
                      open={videoModal}
                      handleMediaModal={setVideoModal}
                      handleUrlChange={handleUrlChange}
                      urlRef={urlRef}
                      setFieldValue={setFieldValue}
                    />
                  </Form>
                );
              }}
            </Formik>
            {/* End of Formik Form */}
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

export default CreateAd;
