export const authReducer = (state, action) => {
  switch (action.type) {
    case "REGISTER":
      state = { ...state, user: action.payload }
      return state

    case "LOGIN":
      state = { ...state, user: action.payload }
      return state

    // case "LOGOUT":
    //   state = { ...state, user: null }
    //   localStorage.removeItem("authToken")
    //   return state

    case "VALIDATE_TOKEN":
      const user = action.payload
      if (!user) localStorage.removeItem("authToken")
      state = { ...state, user }
      return state

    default:
      return state
  }
}
