import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@material-ui/core";
import { Doughnut } from "react-chartjs-2";

import { demographicAdApi } from "../../api";
import strings from "../../lang/lang";

const chart_bg_colors = [
  "rgba(255, 159, 64, 0.3)",
  "rgba(54, 162, 235, 0.3)",
  "rgba(255, 99, 132, 0.3)",
];

const chart_border_colors = [
  "rgba(255, 159, 64, 1)",
  "rgba(54, 162, 235, 1)",
  "rgba(255, 99, 132, 1)",
];

const options = { maintainAspectRatio: true, reponsive: true };

function DemographicChartSm(props) {
  const storedToken = localStorage.getItem("authToken");
  const [data, setData] = useState([]);
  const [ageGaps, setAgeGaps] = useState([]);
  const [maleValues, setMaleValues] = useState([]);
  const [femaleValues, setFemaleValues] = useState([]);

  useEffect(() => {
    demographicAdApi(storedToken, props.data).then(({ data }) => {
      setData(data?.data);

      setMaleValues(
        Array.from(data?.data || []).map((item) => parseInt(item.male))
      );

      setFemaleValues(
        Array.from(data?.data || []).map((item) => parseInt(item.female))
      );

      setAgeGaps(Array.from(data?.data || []).map((item) => item.ageGap));
    });
  }, []);

  return (
    <div className="items-center text-center" >
      <Typography variant="h6" className="py-6">
        {strings.demographicbyagegender}
      </Typography>
      <div>
        {data.length > 0 ? (
          <>
            <Grid container spacing={1}>
              <Grid item md={6} xs={6}>
                <Typography
                  color="primary"
                  variant="h6"
                >
                  {strings.males}
                </Typography>

                <Doughnut
                  data={{
                    labels: ageGaps,
                    datasets: [
                      {
                        data: maleValues,
                        backgroundColor: chart_bg_colors,
                        borderColor: chart_border_colors,
                        borderWidth: 1,
                      },
                    ],
                  }}
                  options={options}
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <Typography color="primary" variant="h6">
                  {strings.females}
                </Typography>

                <Doughnut
                  data={{
                    labels: ageGaps,
                    datasets: [
                      {
                        data: femaleValues,
                        backgroundColor: chart_bg_colors,
                        borderColor: chart_border_colors,
                        borderWidth: 1,
                      },
                    ],
                  }}
                  options={options}
                />
              </Grid>
            </Grid>
          </>
        )
          :
          <Typography align="center">No data to avialble right now</Typography>
        }
      </div>
    </div>
  );
}

export default DemographicChartSm;
