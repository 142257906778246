import { useRef, useState } from 'react'
import {
    Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormHelperText, Grid, Typography
} from '@material-ui/core'
import strings from "../../lang/lang"
import PublishIcon from '@material-ui/icons/Publish';
import { getSubscriptionByAd, submitSubscriptionProof } from '../../api/index'

const BankReceipt = (props) => {
    const lang = localStorage.getItem('lang')
    const { product_id, storedToken, showBankPayment, closeBankModal,subscription_id } = props
    const [subscription_proof, setSubscriptionProof] = useState(1)
    const [errors, setErrors] = useState(null)
    const [isSubmit, setIsSubmit] = useState(false)
    const [subscription_name, setSubscriptionName] = useState(strings.subscription_proof)
    const inputRef = useRef(null);


    const handleSubscriptionProof = (e) => {
        setIsSubmit(true)
        let formData = new FormData();
        formData.append('subscription_proof', subscription_proof)
        formData.append('product_id', product_id)
        formData.append('subscription_id', subscription_id)
        submitSubscriptionProof(storedToken, formData)
            .then(res => {
                if (res.data.status == "success"){
                    window.location.href = res.data.message;
                }
                else {
                    setIsSubmit(false)
                    setErrors(res.data.errors)
                }
            })
    }

    return <Dialog
        open={true}
        onClose={closeBankModal}
        fullWidth>
        <DialogTitle style={{ textAlign: lang == "en" ? "left" : "right" }}>
            {strings.bankPayment}
        </DialogTitle>
        <DialogContent dividers>
            <Grid container>
                {/* <Grid item xs={6}>
                    <Typography align="left" variant="h6">
                        البنك الاهلي
                        <br />SA791000015582159000100
                    </Typography>
                    <Divider />
                    <Typography align="left" variant="h6">
                        البنك العربي الوطني
                        <br />SA7630400108095379760014
                    </Typography>
                    <Divider />
                    <Typography align="left" variant="h6">
                        الرقم التسجيل الضريبي
                        <br />VAT # 310251270300003
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography align="left" variant="h6">
                        {strings.amountToPay}
                        <br />{price} {strings.ryal}
                    </Typography>
                    <Typography>

                    </Typography>
                </Grid> */}
            </Grid>


            <Divider />
            <Button
                style={{ backgroundColor: "#3c8851", color: "#fff", marginTop: "10px" }}
                fullWidth
                variant="contained"
                onClick={() => inputRef.current.click()}
                component="label">
                {subscription_name}
            </Button>
            <input
                ref={inputRef}
                style={{ display: "none" }}
                type="file"
                name="subscription_proof"
                autoComplete="off"
                onChange={(e) => {
                    setSubscriptionName(e.target.files[0].name)
                    setSubscriptionProof(e.target.files[0])
                }}
            />
            {errors &&
                Object.values(errors).map(item => {
                    return <FormHelperText error={true}>{item[0]}</FormHelperText>
                })
            }

        </DialogContent>
        <DialogActions>
            {
                isSubmit ?
                    <CircularProgress className="d-flex justify-content-center" />
                    :
                    <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        fullWidth
                        endIcon={<PublishIcon />}
                        onClick={handleSubscriptionProof}
                    >
                        {strings.payment.payBtn}
                    </Button>
            }
        </DialogActions>
    </Dialog>
}

export default BankReceipt;