import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import QueryString from "query-string"
import strings from "../../lang/lang"
import {
  Grid, Button, TextField,
  Card, CardHeader, CardContent, Typography, CardActionArea, CardActions, CardMedia, Container, Divider
} from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import makeStyles from '../../jsxStyles/payment';
import GoBack from "../../components/GoBack"
import PaymentSuccess from "./PaymentSuccess"
import BankImage from '../../assets/img/bank.jpeg'
import CreditCardImage from '../../assets/img/creditcards.jpeg'
import BankReceipt from "./BankReceipt"
import useStyle from '../../jsxStyles/publisher.jsx'
import 'react-credit-cards/es/styles-compiled.css';
import clsx from "clsx"
const SECRET_KEY = "sk_live_96KaUFtDrf6PAfUs7nGJR2chtza2KMkk2MfqrGcq"

function Payment() {
  const storedToken = localStorage.getItem("authToken")

  const location = useLocation();
  const [showBankPayment, setShowBankPayment] = useState(false)
  const history = useHistory()

  const { success } = QueryString.parse(location.search)
  const ad_list = JSON.parse(localStorage.getItem("ad_list"))
  const product_id = JSON.parse(localStorage.getItem("product_id"))
  const subscription_id = JSON.parse(localStorage.getItem("subscription_id"))
  const classes = makeStyles();
  const style = useStyle();
  const price = localStorage.getItem('ad_price')
  const product = JSON.parse(localStorage.getItem('product'))
  const tax = price * 0.15;
  useEffect(() => {
    if (!storedToken) window.location.pathname = "/login"

  }, [storedToken])

  useEffect(() => {
    // if (ad_list && ad_list.length > 0) {
    //   const product = ad_list.find((item) => item.product_id == product_id)
    //   setPaidAmount(product.budget)
    // } else {
    //   window.location.pathname = "/publisher/adcenter"
    // }
  }, [])

  return (
    <>
      <GoBack />
      {/* {!success && (
        <Typography
          className={clsx(style.page, classes.pageTitle)}
          variant="h3"
          align="center">{strings.paymentMethods}</Typography>
      )} */}
      <Container className={clsx(style.page, classes.gridContainer)}
        style={{
          direction: localStorage.getItem("lang") === "ar" ? "rtl" : "ltr",
          textAlign: localStorage.getItem("lang") === "ar" ? "right" : "left",
        }}
      >
        {success === "true" && <PaymentSuccess product_id={ad_list?.[0].product_id ?? product_id} />}

        {!success && (
          <Grid spacing={3} container>
            <Grid item xs={12} md={4}>
              <Card className={classes.root}>
                <CardHeader
                  title={strings.paymentDetails}
                />
                <CardContent>
                  <span className={classes.titleDesc}>
                    <Typography variant="h5" className={classes.title}>
                      {strings.adTitle} :
                    </Typography>
                    <Typography variant="h6" className={classes.description}>
                      {product?.name}
                    </Typography>
                  </span>
                  <span className={classes.titleDesc}>
                    <Typography variant="h5" className={classes.title}>
                      {strings.adPrice} :
                    </Typography>
                    <Typography variant="h6" className={classes.description}>
                      {price} {strings.ryal}
                    </Typography>
                  </span>
                  <span className={classes.titleDesc}>
                    <Typography variant="h5" className={classes.title}>
                      {strings.bankTax} :
                    </Typography>
                    <Typography variant="h6" className={classes.description}>
                      {tax} (15%)
                    </Typography>
                  </span>
                  <span className={classes.titleDesc}>
                    <Typography variant="h5" className={classes.title}>
                      {strings.amountToPay} :
                    </Typography>
                    <Typography variant="h6" className={classes.description}>
                      {+tax + +price} {strings.ryal}
                    </Typography>
                  </span>
                  <Divider style={{margin:"1rem 0"}} />
                  <Card className={classes.bankInfo} variant="outlined">
                    <CardHeader
                      title={strings.bankDetails}
                      subheader={strings.forBankPayment}
                    />
                    <Divider/>
                    <Typography align="left" variant="h6">
                      <span className={classes.title}>البنك الاهلي </span>
                      <br />SA791000015582159000100
                    </Typography>
                    <Divider />
                    <Typography align="left" variant="h6">
                    <span className={classes.title}>البنك العربي الوطني </span>
                      <br />SA7630400108095379760014
                    </Typography>
                    <Divider />
                    <Typography align="left" variant="h6">
                    <span className={classes.title}>الرقم التسجيل الضريبي </span>
                      <br />VAT # 310251270300003
                    </Typography>
                  </Card>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={8}>
              <Card className={classes.root}>
                <CardHeader
                  title={strings.paymentMethods} />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Card className={classes.noBorderShadow} variant="outlined">
                      <CardActionArea>
                        <CardMedia
                          className={classes.media}
                          image={BankImage}
                          title="Contemplative Reptile"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="h2">
                            {strings.bankPayment}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" component="p">
                            {strings.bankPay}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions>
                        <Button
                          onClick={() => setShowBankPayment(true)}
                          size="large"
                          color="primary"
                          variant="contained"
                          fullWidth
                          endIcon={<ArrowForwardIcon />}>
                          {strings.continue}
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card className={classes.noBorderShadow} variant="outlined">
                      <CardActionArea>
                        <CardMedia
                          className={classes.media}
                          image={CreditCardImage}
                          title="Contemplative Reptile"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="h2">
                            {strings.creditDebitPayment}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" component="p">
                            {strings.creditDebitPay}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions>
                        <Button
                          onClick={__ => history.push(`/publisher/onlinepayment/${ad_list?.[0].product_id ?? product_id }/renew`)}
                          size="large"
                          color="primary"
                          variant="contained"
                          fullWidth
                          endIcon={<ArrowForwardIcon />}>
                          {strings.continue}
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              </Card>
            </Grid>

          </Grid>
        )}
      </Container>
      {showBankPayment && <BankReceipt subscription_id={subscription_id} product_id={ad_list?.[0].product_id ?? product_id} storedToken={storedToken} closeBankModal={() => { setShowBankPayment(!showBankPayment) }} />}
    </>
  )
}




export default Payment
