import { makeStyles } from '@material-ui/core'
import React from 'react'

function AlertBar() {
  const classes = styles()
  return (
    <div className={classes.root}>
      <span className={classes.close}>X</span>
      <p>This is alert</p>
    </div>
  )
}

const styles = makeStyles(theme => {
  return {
    root: {
      height: "2rem",
      backgroundColor: "#ff2424",
      color: "white",
      display: "flex",
      justifyContent: "space-between",

      position: "relative",
      '& p': {
        fontSize: "1.5rem",
      }
    },
    close: {
      fontSize: "0.8rem",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      fontWeight: 700

    }
  }
})
export default AlertBar
