import React, { useEffect, useState } from "react";
import {Link} from "react-router-dom"
import AuthLayout from "../../layouts/AuthLayout"; 
import strings from "../../lang/lang"
import { Formik, Form } from "formik";
import AuthField from "../../components/auth/AuthField";
import AuthButton from "../../components/auth/AuthButton";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { forgotPwdApi } from "../../api";

function DoneMessage(props) {
  const [lang, setLang] = useState(localStorage.getItem("lang"));

  const initialValues = { email: "" };
  const validationSchema = Yup.object({
    email: Yup.string()
      .required(strings.emailrequired)
      .email(strings.invalidemail),
  });

  const handleForgetPwd = (values) => {
    // forgotPwdApi(values)
    //   .then((res) => {
    //     history.push("/done");
    //   })
    //   .catch((err) => console.log(err));
    //     history.push("/done");
    

  };

  useEffect(() => {
    setLang(localStorage.getItem("lang"));
  }, [props.onChange]);
  return (
    <AuthLayout props={props}>
      <div className="flex flex-col items-center font-medium text-xl">
        <h1 className="mt-7 mb-6 xs:mb-10 text-green-600 font-semibold tracking-wide text-2xl xs:text-3xl lg:text-4xl">
          {strings.passwordRest}
        </h1>
        <div>
          <img
            src="/images/email.svg"
            alt="email-sent"
            title="email sent!"
            className="w-full h-64"
          />
        </div>
        <div className="flex flex-col mt-10 text-gray-700 text-base xs:text-lg sm:text-xl">
          {/* <span>{strings.doneMsg2}</span>
          <span>{strings.doneMsg3}</span> */}
          <Link
            to="/login"
            className="mt-4 mb-10 tracking-wide text-lg xs:text-xl capitalize"
          >
            {strings.backToLogin}
          </Link>
        </div>
      </div>
    </AuthLayout>
  );
}

export default DoneMessage;
