import React from "react";

import strings from "../../lang/lang";

function GenderSelect({ gender, setGender }) {
  const lang = localStorage.getItem("lang");

  return (
    <div className="my-6 flex flex-col">
      <label
        className={`mb-3 text-lg sm:text-xl font-semibold ${
          lang == "ar" && "ml-auto"
        }`}
      >
        {strings.gender}
      </label>
      <div className="flex sm:mx-8 md:mx-12" dir={lang == "ar" ? "rtl" : "ltr"}>
        <div
          onClick={() => setGender(0)}
          className={`${
            gender == 0 ? "bg-green-200" : "bg-white hover:border-green-600"
          } relative mx-1 xs:mx-2 sm:mx-8 p-2 sm:p-4  w-1/3 sm:w-1/5 flex justify-center items-center text-base sm:text-lg font-medium tracking-wide text-gray-700 border-2 border-gray-200  rounded-lg shadow-sm cursor-pointer`}
        >
          {strings.male}
        </div>
        <div
          onClick={() => setGender(1)}
          className={`${
            gender == 1 ? "bg-green-200" : "bg-white hover:border-green-600"
          } relative mx-1 xs:mx-2 sm:mx-8 p-2 sm:p-4 w-1/3 sm:w-1/5 flex justify-center items-center text-base  sm:text-lg font-medium tracking-wide text-gray-700 border-2 border-gray-200 rounded-lg shadow-sm cursor-pointer`}
        >
          {strings.female}
        </div>
        <div
          onClick={() => setGender(2)}
          className={`${
            gender == 2 ? "bg-green-200" : "bg-white hover:border-green-600"
          } relative mx-1 xs:mx-2 sm:mx-8 p-2 sm:p-4 w-1/3 sm:w-1/5 flex justify-center items-center text-base sm:text-lg font-medium tracking-wide text-gray-700 border-2 border-gray-200 rounded-lg shadow-sm cursor-pointer`}
        >
          {strings.all}
        </div>
      </div>
    </div>
  );
}

export default GenderSelect;
