import { Grid } from "@material-ui/core";
import { Field } from "formik";
import React from "react";

function TextFieldWithIcon({ InputIcon, lang, ...props }) {
  return (
    <Grid item xs={12} sm={6}>
      <div className="w-full h-12 relative flex items-center text-gray-700">
        <Field
          type="text"
          dir={lang === "ar" ? "rtl" : "ltr"}
          className={`w-full h-full px-3 ${
            lang === "ar" ? "pl-5" : "pr-5"
          } text-base rounded-md focus:outline-none border-2 border-gray-300 focus-within:border-green-400 shadow-sm`}
          autoComplete="off"
          {...props}
        />
        <InputIcon
          className={`${
            lang === "ar" ? "left-0" : "right-0"
          } absolute flex items-center mx-3 text-2xl`}
        />
      </div>
    </Grid>
  );
}

export default TextFieldWithIcon;
