const initialState = {
    price: 0
}

export default (state = initialState, action) => {
    switch (action.type) {
        case "AD_PRICE":
            let price = { ...state, price: action.payload }
            return price
        default:
            return state;

    }
}