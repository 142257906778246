import { Redirect, Route } from "react-router-dom"


function ProtectedRoute(props) {
  const user = localStorage.getItem('user')

    if (user) {
      if (props.loggedIn && !user) {
        return <Redirect to="/login" />
      } else if (props.guest && user) {
        return <Redirect to="/publisher/dashboard" />
      } else {
        return <Route component={props.component} {...props} />
      }
    } else {
      return <Route component={props.component} {...props} />
    }
}

export default ProtectedRoute
