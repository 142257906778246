
export const afterLogin = (data) => {
    return {
        type: "LOGIN_SUCCESS",
        payload: data
    }
}

export const afterRegister = (data) => {
    return {
        type: "REGISTER_SUCCESS",
        payload: data
    }
}

export const afterValidateToken = (data) => {
    return {
        type: "VALIDATED_TOKEN",
        payload: data
    }
}

export const logout = (data) => {
    return {
        type: "LOGGED_OUT",
        payload: data
    }
}

export const storeNotifications = (data) => {
    return {
        type : "Notifications",
        payload:data
    }
}

export const addNotification = (data,notificationsCount) => {
    return {
        type : "AddNotification",
        payload:{notification:data,count:notificationsCount}
    }
}

export const setCount = (data) => {
    return {
        type : "SetCount",
        payload:data
    }
}

export const setUnreadMsgs = (data) => {
    return {
        type : "setUnreadMsgs",
        payload:data
    }
}