import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";

import CompanyWizard from "../components/modals/CompanyWizard";
import PublisherNavbar from "../components/nav/PublisherNavbar";
import AudienceContextProvider from "../contexts/audience";
import NotFound from "../pages/NotFound";
import AdQuestionsForm from "../pages/publisher/AdQustionsForm";
import CreateAd from "../pages/publisher/CreateAd";
import OnlinePayment from "../pages/publisher/OnlinePayment";
import Dashboard from "../pages/publisher/Dashboard";
import MyAd from "../pages/publisher/MyAd";
import MyAds from "../pages/publisher/MyAds";
import Payment from "../pages/publisher/Payment";
import Profile from "../pages/publisher/Profile";
import ProtectedRoute from "../ProtectedRoute";
import { validateTokenApi } from "../api/index";
import { useDispatch } from "react-redux";
import { addNotification, afterValidateToken } from "../store/actions/userActions";
import { CircularProgress } from "@material-ui/core";
import RenewAd from "../components/ads/RenewAd";
import Audience from "../components/ads/Audience";
import AdsList from "../components/ads/AdsList";
import Analytics from "../pages/publisher/Analytics";
import AlertBar from "../components/nav/AlertBar";
import Account from "../pages/publisher/Account";

export default function PublisherLayout() {
  const dispatch = useDispatch();
  const [companyModal, setCompanyModal] = useState(false);
  const toggleCompanyModal = () => setCompanyModal(!companyModal);
  const [isRendered, setRendered] = useState(true);
  const storedToken = localStorage.getItem("authToken");

  useEffect((__) => {
    validateTokenApi(storedToken).then((response) => {
      if (response?.data?.data?.status) {
        dispatch(afterValidateToken(response?.data?.data));
        setRendered(false);
      }
    });

  }, []);

  return (
    <>
      {isRendered ? (
        <span style={{ display: "flex", justifyContent: "center", alignContent: "center", height: "100vh" }}>
          <CircularProgress style={{ display: "flex", justifySelf: "center", alignSelf: "center" }} />
        </span>
      ) : (
        <AudienceContextProvider>
          <PublisherNavbar toggleCompanyModal={toggleCompanyModal} />
          {companyModal && (
            <CompanyWizard toggleCompanyModal={toggleCompanyModal} />
          )}
          {/* <AlertBar/> */}
          <Switch>
            <ProtectedRoute
              path="/publisher/dashboard"
              component={Dashboard}
              loggedIn
            />
            <ProtectedRoute
              path="/publisher/adcenter"
              component={CreateAd}
              loggedIn
            />
            <ProtectedRoute
              path="/publisher/onlinepayment/:product_id/:type"
              component={OnlinePayment}
              loggedIn
            />
            <ProtectedRoute
              path="/publisher/myads"
              component={MyAds}
              loggedIn
            />
            <ProtectedRoute
              path="/publisher/myadslist/:type"
              component={AdsList}
              loggedIn
            />
            <ProtectedRoute
              path="/publisher/myad/:id"
              component={MyAd}
              loggedIn
            />
            <ProtectedRoute
              path="/publisher/profile"
              component={Profile}
              loggedIn
            />
            <ProtectedRoute
              path="/publisher/renew/:product_id"
              component={RenewAd}
              loggedIn
            />
            <ProtectedRoute
              path="/publisher/ad/:id/questions/add"
              component={AdQuestionsForm}
              loggedIn
            />
            <ProtectedRoute
              path="/publisher/ad_response"
              component={Payment}
              loggedIn
            />
            <ProtectedRoute
              path="/publisher/audience/:product_id"
              component={Audience}
              loggedIn
            />
            <ProtectedRoute
              path="/publisher/analytics"
              component={Analytics}
              loggedIn
            />
            <ProtectedRoute
              path="/publisher/account"
              component={Account}
              loggedIn
            />
            <Route component={NotFound} loggedIn />
          </Switch>
        </AudienceContextProvider>
      )}
    </>
  );
}
