import strings from '../lang/lang'
import Dummy from '../assets/img/dummy.png';
export const LIVE_URL = "https://thmin.com/api/v1.1.1"
export const LOCAL_URL = "http://thamin.test/api/v1.1.1/"
export const BUCKET_URL = "https://thaminmainweb.fra1.digitaloceanspaces.com/files/"
export const IMAGE_URL = "https://thaminmainweb.fra1.digitaloceanspaces.com/files/profile/"

const lang = localStorage.getItem("lang");

export const getNotificationMessage = (type, ad_name) => {
    switch (type) {
        case "subscription":
            return `${strings.adOrder} ${ad_name} ${strings.adOrderRecieved}`
        case "App\\Application\\Model\\User":
            return `${ad_name?.[lang]}`
        case "published":
            return `${strings.congratulations} ${ad_name} ${strings.adPublishedRecieved} ${strings.adOrderRecieved}`
    }
}

export const checkGender = (item) => {
    if (item == 2) return strings.male;
    return item == 0 ? strings.male : strings.female;
};

export const calculatePrice = (subscription) => {
    let tax = (parseFloat(subscription?.price) / 100) * 15;
    return parseInt(subscription.price + tax)
};

export const calculateViews = (subscription) => {
    console.log(subscription)
    let percentage_value = (subscription.price / 100) * parseFloat(subscription?.deduction_ratio);
    return parseInt(parseInt(percentage_value) / 0.15)
};

export const getImage = (image) => {
    return image ? IMAGE_URL+image : Dummy;
};
