export const selectAgeCategory = (range, setAgeCategory, ageCategory) => {
  if (range[0] >= 18 && range[1] <= 25)
    setAgeCategory((oldVal) => ({ ...oldVal, age: 1 }));
  else if (range[1] <= 33) setAgeCategory((oldVal) => (2));
  else if (range[1] <= 41) setAgeCategory((oldVal) => ({ ...oldVal, age: 3 }));
  else if (range[1] <= 49) setAgeCategory((oldVal) => ({ ...oldVal, age: 4 }));
  else if (range[1] <= 57) setAgeCategory((oldVal) => ({ ...oldVal, age: 5 }));
  else if (range[1] <= 64) setAgeCategory((oldVal) => ({ ...oldVal, age: 6 }));
  else if (range >= 65) setAgeCategory((oldVal) => ({ ...oldVal, age: 7 }));
};

export const getAgeCategory = (range) => {
  if (range[0] >= 18 && range[1] <= 25) return 0;
  else if (range[1] <= 33) return  1;
  else if (range[1] <= 41) return  2;
  else if (range[1] <= 49) return  3;
  else if (range[1] <= 57) return  4;
  else if (range[1] <= 64) return  5;
  else if (range >= 65) return  6;
}

export const getAgeRangeByCategory = (ageCategory) => {
  if (ageCategory == 0)
    return [18, 25]
  else if (ageCategory == 1)
    return [18, 33]
  else if (ageCategory == 2)
    return [18, 41]
  else if (ageCategory == 3)
    return [18, 49]
  else if (ageCategory == 4)
    return [18, 57]
  else if (ageCategory == 5)
    return [18, 64]
  else if (ageCategory == 6)
    return [18, 65]
}


