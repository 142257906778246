import { Box, Card, Container, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState, useContext } from 'react'
import { Bar } from 'react-chartjs-2'

import { publisherDashboardApi } from '../../api'
import StepCard from '../../components/StepCard'
import TotalCount from '../../components/TotalCount'
import styles from '../../jsxStyles/publisher'
import strings from '../../lang/lang'
import dashboard from '../../modules/dashboard.module.css'
import { AuthContext } from '../../contexts/authContext'
import CommentIcon from '@material-ui/icons/Comment'
import GroupIcon from '@material-ui/icons/Group'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import VisibilityIcon from '@material-ui/icons/Visibility'
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt'

const options = { maintainAspectRatio: false, reponsive: true }

export default function Dashboard() {
  const storedToken = localStorage.getItem('authToken')
  const lang = localStorage.getItem('lang')
  const classes = styles()

  const [activeTab, setActiveTab] = useState('chart')
  const [chartData, setChartData] = useState([])
  const [comments, setComments] = useState(null)
  const [ratings, setRatings] = useState(0)
  const [visitors, setVisitors] = useState(0)
  const [watchers, setWatchers] = useState(null)
  const [views, setViews] = useState(null)
  const [wrongAnswers, setWrongAnswers] = useState(0)
  const [visits, setVisits] = useState([])
  const [months, setMonths] = useState([])

  useEffect(() => {
    if (!storedToken) window.location.href = '/login'
    if (lang == 'en')
      document.title = strings.dashboard + ' | ' + strings.thamin
    else document.title = strings.dashboard + ' | ' + strings.thamin

    publisherDashboardApi(storedToken).then(({ data }) => {
      setComments(data.comments)
      setRatings(data.ratings)
      setVisitors(data.visitors)
      setWatchers(data.watchers)
      setViews(data.visitors + data.watchers)
      setWrongAnswers(data.wrong)
      setMonths(Array.from(data.barData).map((item) => item.month))
      setVisits(Array.from(data.barData).map((item) => item.visits))
      setChartData(data?.barData)
    })
  }, [])

  return (
    <div
      className={`${classes.page}`}
    >
      <Container>
        <Grid spacing={3} container>
          <Grid item xs={12} md={4}>
            <TotalCount
              title={strings.watchers}
              count={watchers}
              icon={<SupervisedUserCircleIcon style={{ color: '#3E659D' }} />}
              backgroundColor="#89B7FB"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TotalCount
              title={strings.views}
              count={views}
              icon={<VisibilityIcon style={{ color: '#996A3D' }} />}
              backgroundColor="#EAC29C"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TotalCount
              title={strings.comments}
              count={comments}
              icon={<CommentIcon style={{ color: '#2F8316' }} />}
              backgroundColor="#A7EC92"
            />
          </Grid>
        </Grid>
        {/* <Grid spacing={3} container>
          <Grid item xs={4}>
            <TotalCount
              title={strings.likes}
              count={ratings}
              icon={<ThumbUpAltIcon style={{ color: '#7E382D' }} />}
              backgroundColor="#ED9182"
            />
          </Grid>
          <Grid item xs={4}>
            <TotalCount
              title={strings.visitors}
              count={visitors}
              icon={<GroupIcon style={{ color: '#519695' }} />}
              backgroundColor="#80E6E4"
            />
          </Grid>
          
          <Grid item xs={4}>
            <TotalCount
              title={strings.wronganswers}
              count={wrongAnswers}
              icon={<QuestionAnswerIcon style={{ color: '#969862' }} />}
              backgroundColor="#F0F297"
            />
          </Grid>
        </Grid> */}
        <div className="flex w-full mt-5">
          <Box
            className={`${dashboard.tab} ${
              activeTab === 'chart' && dashboard.activeTab
            }`}
            onClick={() => setActiveTab('chart')}
          >
            <Typography style={{fontSize: 20}}>{strings.monthlyviews}</Typography>
          </Box>

          {/* <Box
            className={`${dashboard.tab} ${
              activeTab === 'adSteps' && dashboard.activeTab
            }`}
            onClick={() => setActiveTab('adSteps')}
          >
            <Typography>{strings.steps}</Typography>
          </Box> */}
        </div>
        <div className="flex flex-col mt-1">
          {activeTab === 'chart' && (
            <Card className="h-80 xs:h-104 w-full" style={{padding:"0px"}}>
              {chartData.length > 0 ? (
                <Bar
                  data={{
                    labels: months,
                    datasets: [
                      {
                        label: 'Visits: ',
                        data: visits,
                        backgroundColor: '#55BC72a7',
                        borderColor: 'rgba(75, 192, 192, 1)',
                        borderWidth: 1,
                      },
                    ],
                  }}
                  options={options}
                />
              ) : (
                <div className="text-center">No Data Found!</div>
              )}
            </Card>
          )}

          {/* {activeTab === 'adSteps' && (
            <Grid container spacing={2}>
              <Grid xs={12} md={6}>
                <StepCard
                  num={strings.one}
                  name={strings.step1Title}
                  stepNum={strings.step1}
                  // details="Lorem ipsum dolor sit."
                />
              </Grid>
              <Grid xs={12} md={6}>
                <StepCard
                  num={strings.two}
                  name={strings.step2Title}
                  stepNum={strings.step2}
                  // details="Lorem ipsum dolor sit."
                />
              </Grid>
              <Grid xs={12} md={6}>
                <StepCard
                  num={strings.three}
                  name={strings.step3Title}
                  stepNum={strings.step3}
                  // details="Lorem ipsum dolor sit."
                />
              </Grid>
              <Grid xs={12} md={6}>
                <StepCard
                  num={strings.four}
                  name={strings.step4Title}
                  stepNum={strings.step4}
                  // details="Lorem ipsum dolor sit."
                />
              </Grid>
            </Grid>
          )} */}
        </div>
      </Container>
    </div>
  )
}
