import { StylesProvider } from '@material-ui/core'
import { jssPreset, ThemeProvider } from '@material-ui/core/styles'
import { create } from 'jss'
import rtl from 'jss-rtl'
import React, { useContext, useEffect, useState } from 'react'
import { Redirect, useLocation, Switch, Route, useHistory } from 'react-router'

import { afterValidateToken } from './store/actions/userActions'
import { validateTokenApi } from './api'
import theme from './enum/colors.js'
import strings from './lang/lang'
import PublisherLayout from './layouts/PublisherLayout'
import UserLayout from './layouts/UserLayout'
import DoneMessage from './pages/auth/DoneMessage'
import ForgetPassword from './pages/auth/ForgetPassword'
import Login from './pages/auth/Login'
import Register from './pages/auth/Register'
import NotFound from './pages/NotFound'
import ProtectedRoute from './ProtectedRoute'
import { useDispatch, useSelector } from 'react-redux'
import { install } from 'resize-observer'
import Pusher from 'pusher-js';
import { Helmet } from 'react-helmet'
import VerificationCode from './pages/auth/VerificationCode'
import NewPass from './pages/auth/NewPass'

const storedToken = localStorage.getItem('authToken')
const siteLang = localStorage.getItem('lang')
const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
let html = document.querySelector('html')
let body = document.querySelector('body')

function App() {
  install();
  const history = useHistory()
  const dispatch = useDispatch();
  const [lang, setLang] = useState(siteLang)

  const current_user = useSelector(state => state.user.user)
  const [direction, setDirection] = useState(siteLang === 'en' ? 'rtl' : 'ltr')



  useEffect(async () => {
    if (lang == null || lang == 'ar') {
      localStorage.setItem('lang', 'ar')
      strings.setLanguage('ar')
      setDirection('rtl')
      body.style.fontFamily = 'avenir next medium'

    } else {
      localStorage.setItem('lang', lang)
      strings.setLanguage(lang)
      setDirection('ltr')
      body.style.fontFamily = 'Open Sans'
    }
    html.setAttribute('lang', lang)
    body.setAttribute('dir', direction)
    if (storedToken == null) {
      localStorage.removeItem("user");
      localStorage.removeItem("authToken");
      localStorage.removeItem("token");
      localStorage.removeItem("ad_list");
      localStorage.removeItem("ad_price");
      history.push('/login')
      return;
    }
    // let response = await validateTokenApi(storedToken)

  }, [lang, storedToken])
  // debuggerss
  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={{ ...theme, direction }}>
        <Helmet>
          <meta name="description" content="Thamin Advertising Company to Grow Business and earn from it" />
          <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
        </Helmet>
        <Switch>
          <ProtectedRoute exact path="/">
            <Redirect
              to={current_user?.length != 0 ? "/publisher/dashboard" : '/login'}
            />
          </ProtectedRoute>
          <ProtectedRoute path="/publisher" loggedIn>
            <PublisherLayout onChange={(value) => setLang(value)} loggedIn />
          </ProtectedRoute>
          <ProtectedRoute exact path="/users" loggedIn>
            <UserLayout onChange={(value) => setLang(value)} loggedIn />
          </ProtectedRoute>
          <ProtectedRoute exact path="/register" guest>
            <Register onChange={(value) => setLang(value)} guest />
          </ProtectedRoute>
          <ProtectedRoute exact path="/login" guest>
            <Login onChange={(value) => setLang(value)} guest />
          </ProtectedRoute>
          <ProtectedRoute exact path="/forgot-password" guest>
            <ForgetPassword onChange={(value) => setLang(value)} guest />
          </ProtectedRoute>
          <ProtectedRoute exact path="/verification-code" guest>
            <VerificationCode onChange={(value) => setLang(value)} guest />
          </ProtectedRoute>
          <ProtectedRoute exact path="/resetPassword" guest>
            <NewPass onChange={(value) => setLang(value)} guest />
          </ProtectedRoute>
          <ProtectedRoute exact path="/done" guest>
            <DoneMessage onChange={(value) => setLang(value)} guest />
          </ProtectedRoute>
          <Route>
            <NotFound onChange={(value) => setLang(value)} guest />
          </Route>
        </Switch>
      </ThemeProvider>
    </StylesProvider>
  )
}

export default App
