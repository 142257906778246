import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import CompanyWizard from '../components/modals/CompanyWizard';
import UserNavbar from '../components/nav/UserNavbar';
import Dashboard from '../pages/user/Dashboard';



export default function UserLayout() {
    const [companyModal,setCompanyModal] = useState(false)
    const toggleCompanyModal = () => setCompanyModal(!companyModal)

    return (
        <div>
            <UserNavbar toggleCompanyModal={toggleCompanyModal}/>
            {companyModal && <CompanyWizard toggleCompanyModal={toggleCompanyModal} />}
            <Router>
                <Switch>
                    <Route path="/">
                        <Dashboard/>
                    </Route>
                </Switch>
            </Router>
        </div>
    )
}
