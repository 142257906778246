import { createContext, useState } from "react"



export const AudienceContext = createContext()


function AudienceContextProvider({ children }) {

    const [selectedNeighborhood, setSelectedNeighborhood] = useState([])
    const [selectedCountries, setSelectedCountries] = useState([])
    const [selectedStates, setSelectedStates] = useState([])
    const [selectedJobs, setSelectedJobs] = useState([])
    const [gender, setGender] = useState(14)
    const [age, setAge] = useState([18, 65])
    const [locations, setLocations] = useState([])

    const props = {
        setSelectedNeighborhood,
        selectedNeighborhood,
        selectedCountries,
        setSelectedCountries,
        selectedStates,
        setSelectedStates,
        selectedJobs,
        setSelectedJobs,
        gender,
        setGender,
        age,
        setAge,
        locations,
        setLocations
    }

    return (
        <AudienceContext.Provider value={props}>
            {children}
        </AudienceContext.Provider>
    )
}


export default AudienceContextProvider;



















