import {
  Card,
  Checkbox,
  FormControlLabel,
  TextareaAutosize,
  CardHeader,
  CardContent,
  CardActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useContext } from "react";
import { AuthContext } from "../contexts/authContext";
import moment from "moment";
import strings from "../lang/lang";
import { replyCommentApi } from "../api";
import { useSelector } from "react-redux";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import clsx from "clsx";

function CommentBox({ comment }) {
  const classes = styles();
  const storedToken = localStorage.getItem("authToken");
  const lang = localStorage.getItem("lang");
  const [reply, setReply] = useState("");
  const [hiddenReply, setHiddenReply] = useState(false);
  const [unPublishChecked, setUnPublishChecked] = useState(false);
  const [hideReplyChecked, setHideReplyChecked] = useState(false);
  const authState = useSelector((state) => state.user);
 
  const handleAddReply = (e) => {
    e.preventDefault();

    if (reply.trim() !== "") {
      setHiddenReply(true);

      const formData = new FormData();
      formData.append("reply_message", reply);
      formData.append(
        "publish_status",
        unPublishChecked ? "Deactive" : "Active"
      );
      formData.append("reply_status", hideReplyChecked ? "Deactive" : "Active");

      replyCommentApi(storedToken, comment?.id, formData)
        .then((res) => {})
        .catch((err) => console.log("err"));
    }
  };

  return (
    <div className="w-full mt-2 mb-3" dir="rtl">
      <Card className={classes.commentBox} variant="outlined">
        <CardHeader
          className={classes.commentBoxUserName}
          subheader={
            <span className="font-bold">{comment?.user?.name ?? "username"}</span>
          }
          avatar={<AccountCircleIcon />}
        />
        <CardContent className={classes.commentBoxMessages}>
          {comment?.message}
        </CardContent>
        <CardActions className={classes.commentBoxFooter}>
          <span dir="ltr">{(comment?.reply_date?.substring(0, 10))}</span>
        </CardActions>
      </Card>

      {comment.reply ? (
        <Card className={clsx(classes.commentBoxx, "mx-3 b")} variant="outlined">
          <CardHeader
            className={classes.commentBoxUserName}
            subheader={
              <span className="font-bold">
                {authState?.user?.name || "publisher"}
              </span>
            }
            avatar={<AccountCircleIcon />}
          />
          <CardContent className={classes.commentBoxMessages}>
          {comment?.reply}
          </CardContent>
          <CardActions className={classes.commentBoxFooter}>
            <span dir="ltr">{(comment?.reply_date.substring(0, 10))}</span>
          </CardActions>
        </Card>
      ) : (
        <>
          {!hiddenReply && (
            <form dir={lang === "ar" ? "rtl" : "ltr"} onSubmit={handleAddReply}>
              <div className="self-start flex  xsgap-2 sm:gap-4">
                {/* <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={unPublishChecked}
                        onChange={() => setUnPublishChecked(!unPublishChecked)}
                        name="publish_status"
                        color="primary"
                        size="small"
                        fontSize="small"

                      />
                    }
                    label={strings.publishCheck}
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={hideReplyChecked}
                        onChange={() => setHideReplyChecked(!hideReplyChecked)}
                        name="reply_status"
                        color="primary"
                        size="small"
                        fontSize="small"
                      />
                    }
                    label={strings.replyCheck}
                  />
                </div> */}
              </div>
              <TextareaAutosize
                rowsMax={2}
                placeholder={strings.typeReply}
                onChange={(e) => setReply(e.target.value)}
                value={reply}
                style={{
                  width: "100%",
                  border: "1px solid black",
                  padding: "5px",
                  borderRadius: "5px",
                  marginTop: 8,
                }}
              />
              <button
                style={{
                  backgroundColor: "#0366b9",
                  color: "#fff",
                  padding: "5px 12px",
                  borderRadius: "5px",
                  marginTop: 6,
                }}
                type="submit"
              >
                {strings.reply}
              </button>
            </form>
          )}
          {hiddenReply && (
            <div className="mx-3 p-2 border-black border rounded-xl bg-gray-100 shadow-md">
              <div className="flex flex-col">
                <h3 className="text-sm text-gray-800 font-semibold tracking-wide capitalize">
                  {authState?.user?.name || "publisher"}
                </h3>
              </div>
              <div className="px-2">
                <p className="break-words leading-tight">{reply}</p>
              </div>
              <span className="px-1.5 text-xs text-gray-500 ">
                {moment(new Date()).fromNow()}
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
}

const styles = makeStyles((theme) => {
  return {   
    commentBox: {
      padding: "1px",
      fontSize: "0.875rem",
      backgroundColor: "#e3f7ff",
      border: "1px solid black !important",
      borderRadius:10,
    },
    commentBoxx: {
      padding: "1px",
      fontSize: "0.875rem",
      backgroundColor: "#d1ffe8",
      border: "1px solid black !important",
      borderRadius:10,
    },
    commentBoxUserName: {
      padding: "5px",
    },
    commentBoxMessages: {
      margin: "0px 14px",
      maxHeight: 60,
      overflowY: "scroll",
      padding: "0px 8px 0px 8px",
      textAlign: "justify",
    },
    commentBoxFooter: {
      padding: "10px",
      fontSize: "0.65rem",
    },
  };
});

export default CommentBox;
