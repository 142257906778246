import React from "react";

function AuthTitle({ title, lang }) {
  return (
    <header
      style={{ textAlign: lang === "en" ? "left" : "right" }}
      className="block text-green-900 text-2xl sm:text-3xl tracking-wider font-medium mt-2 mb-4 mx-3 sm:mx-0 lg:mx-3 capitalize"
    >
      {title}
    </header>
  );
}

export default AuthTitle;
