import React from 'react';
import { useHistory } from 'react-router';
import strings from '../../lang/lang'


export default function PaymentSuccess(props){
    const history = useHistory();
    localStorage.removeItem('ad_list')
    return <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="my-10 flex justify-center">
            <img
                src="/images/paymentDone.svg"
                alt="success-payment"
                className="rounded-md w-25"
            />
        </div>
        <h1 className="text-4xl lg:mx-10 w-full text-green-600 text-center py-4 rounded-lg">
            {strings.thankyou}
        </h1>
        <div>
            <p>{strings.newadrequest}</p>
        </div>
        <div>
            <p>{strings.adreview}</p>
        </div>
        <div>
            <p>{strings.slogan}</p>
        </div>
        
        <div className="text-center">
            <button
                className="px-4 py-2 bg-green-500 tracking-wide font-semibold capitalize text-base lg:text-xl focus:outline-none focus:bg-green-600 text-white rounded-lg"
                onClick={() => history.push('/publisher/myadslist/pending')}
            >
                {strings.visitAd}
            </button>
        </div>
    </div>

}