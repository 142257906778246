import * as React from "react";
import {useState } from "react";
import { Box, Container, Typography, TextField } from "@material-ui/core";
import { Grid, Card, Button } from "@material-ui/core";
import useStyles from "../../jsxStyles/publisher";
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";
import strings from "../../lang/lang";
import AuthField from "../../components/auth/AuthField";
import AuthButton from "../../components/auth/AuthButton";
import { useHistory } from "react-router";
import { resetPasswordApi } from "../../api";
import * as Yup from "yup";
import Swal from "sweetalert2";

function Account() {
  let lang = localStorage.getItem("lang");
  const styles = useStyles();
  const classes = useStyles();
  const user = useSelector((state) => state?.user?.user);
  const [resetPass, setResetPass] = useState(false)
  const checkGender = (item) => {
    if (item === "") return strings.notSet;
    else {
      return item == 0 ? strings.male : strings.female;
    }
  };
  const checkObject = (item) => {
    if (item) {
      let value = JSON.parse(item);
      if (
        typeof value == "object" &&
        value?.[lang] === "المملكة العربية السعودية"
      )
        return "السعودية";
      else if (typeof value == "object") return value?.[lang];
      return value;
    }
    return "-";
  };

  const history = useHistory();
  const initialValues = {
    password: "",
    password_confirmation: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string().required(strings.pwdRequired),
    password_confirmation: Yup.string()
      .required(strings.confirmPwdRequired)
      .oneOf([Yup.ref("password")], strings.pwdsNotMatch),
  });

  const handleNewPassword = (values) => {
    values.email = user.email;

    resetPasswordApi(values)
      .then((res) => {
        if (res.data.status) {
          Swal.fire(strings.passwordRest);
          history.push("/done");
        } else {
          alert(res.data.error.password);
          history.push("/resetPassword");
        }
      })
      .catch((err) => console.log(err));
    history.push("/resetPassword");
  };

  return (
    <div className={styles.page}>
      <Container
        style={{
          textAlign: lang === "en" ? "left" : "right",
          direction: lang === "en" ? "ltr" : "rtl",
        }}
      >
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <div className="my-3">
                <Typography variant="h4" className={styles.accountInfo}>
                  {strings.accountInfo}
                </Typography>
              </div>
            </Grid>
            <Formik>
              {({ errors, touched, setFieldValue, values }) => {
                return (
                  <Form className="w-full">
                    <Grid container spacing={2}>
                      <Grid item xs={1} md={2}></Grid>
                      <Grid item xs={10} md={8}>
                        <Card raised className={styles.cardsCreadeAd3}>
                          <Grid container>
                            <Grid container xs={12} md={12} lg={12}>
                              <Grid
                                xs={12}
                                md={6}
                                lg={6}
                                style={{ padding: "20px 0px 20px 0px" }}
                              >
                                <Box
                                  sx={{
                                    "& > :not(style)": { m: 1 },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <TextField
                                    label={strings.fullName}
                                    defaultValue={user.name}
                                    disabled="true"
                                    className={styles.InputLabelProps}
                                  />
                                </Box>
                              </Grid>
                              <Grid
                                xs={12}
                                md={6}
                                lg={6}
                                style={{ padding: "20px 0px 20px 0px" }}
                              >
                                <Box
                                  sx={{
                                    "& > :not(style)": { m: 1 },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <TextField
                                    label={strings.birthday}
                                    defaultValue={user.birthdate}
                                    disabled="true"
                                    className={styles.InputLabelProps}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                            <Grid container xs={12} md={12} lg={12}>
                              <Grid
                                xs={12}
                                md={6}
                                lg={6}
                                style={{ padding: "20px 0px 20px 0px" }}
                              >
                                <Box
                                  sx={{
                                    "& > :not(style)": { m: 1 },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <TextField
                                    label={strings.gender}
                                    defaultValue={checkGender(user.gender)}
                                    disabled="true"
                                    className={styles.InputLabelProps}
                                  />
                                </Box>
                              </Grid>
                              <Grid
                                xs={12}
                                md={5}
                                lg={5}
                                style={{ padding: "20px 0px 20px 0px" }}
                              >
                                <Box
                                  sx={{
                                    "& > :not(style)": { m: 1 },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <TextField
                                    label={strings.mobile}
                                    defaultValue={user.mobile}
                                    disabled="true"
                                    className={styles.InputLabelProps}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                            <Grid container xs={12} md={12} lg={12}>
                            <Grid
                                xs={12}
                                md={6}
                                lg={6}
                                style={{ padding: "20px 0px 20px 0px" }}
                              >
                                <Box
                                  sx={{
                                    "& > :not(style)": { m: 1 },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <TextField
                                    label={strings.country}
                                    defaultValue={checkObject(
                                      user?.country?.name
                                    )}
                                    disabled="true"
                                    className={styles.InputLabelProps}
                                  />
                                </Box>
                              </Grid>
                              <Grid
                                xs={12}
                                md={6}
                                lg={6}
                                style={{ padding: "20px 0px 20px 0px" }}
                              >
                                <Box
                                  sx={{
                                    "& > :not(style)": { m: 1 },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <TextField
                                    label={strings.statee}
                                    defaultValue={checkObject(
                                      user?.state?.name
                                    )}
                                    disabled="true"
                                    className={styles.InputLabelProps}
                                  />
                                </Box>
                              </Grid>
                              {/* <Grid
                                xs={12}
                                md={6}
                                lg={6}
                                style={{ padding: "20px 0px 20px 0px" }}
                              >
                                <Box
                                  sx={{
                                    "& > :not(style)": { m: 1 },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <TextField
                                    label={strings.address}
                                    defaultValue={checkObject(
                                      user?.address?.name
                                    )}
                                    disabled="true"
                                    className={styles.InputLabelProps}
                                  />
                                </Box>
                              </Grid> */}
                            </Grid>
                            <Grid container xs={12} md={12} lg={12}>
                              <Grid
                                xs={12}
                                md={5}
                                lg={5}
                                style={{ padding: "20px 0px 20px 0px" }}
                              >
                                <Box
                                  sx={{
                                    "& > :not(style)": { m: 1 },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <TextField
                                    fullWidth="true"
                                    label={strings.email}
                                    defaultValue={user.email}
                                    disabled="true"
                                    className={styles.InputLabelProps}
                                  />
                                </Box>
                              </Grid>
                              <Grid xs={0} md={1} lg={1}></Grid>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                      <Grid item xs={1} md={2}></Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </Box>
        <Box>
         { 
         resetPass ? null :  
        <Grid container style={{paddingTop: 16, paddingBottom: 30}}>
        <Grid item xs={1} md={2}></Grid>
        <Grid item xs={6} md={6}>
        <Button
          onClick={() => setResetPass(true)}
          color="primary"
          variant="contained"
          className={classes.downButtons}
        >
          {strings.resPass}
        </Button>
        </Grid>
        <Grid item xs={1} md={2}></Grid>
         </Grid> 
         }
        </Box>
        { resetPass ?
        <Box>
          <Grid container>
            <Grid item xs={12}>
              <div className="my-3">
                <Typography variant="h4" className={styles.accountInfo}>
                  {strings.resetPass}
                </Typography>
              </div>
            </Grid>
            <Formik
              initialValues={initialValues}
              onSubmit={handleNewPassword}
              validationSchema={validationSchema}
            >
              {({ touched, errors, dirty }) => (
                <Form className="w-full">
                  <Grid container spacing={2}>
                    <Grid item xs={1} md={2}></Grid>
                    <Grid item xs={10} md={8}>
                      <Card raised className={styles.cardsCreadeAd3}>
                        <Grid container>
                        <Grid item xs={1} md={3}/>
                        <Grid item xs={11} md={6} style={{paddingRight: 12}}>
                          <AuthField
                            label={strings.labelNewPass}
                            type="text"
                            name="password"
                            fieldErr={
                              touched.password && errors.password
                                ? errors.password
                                : null
                            }
                            className={styles.websiteCreateAd} 
                          />
                          </Grid>
                          <Grid item xs={0} md={3}/>
                          <Grid item xs={1} md={3}/>
                           <Grid item xs={11} md={6} style={{paddingRight: 12}}>
                          <AuthField
                            label={strings.labelReNewPass}
                            type="text"
                            name="password_confirmation"
                            fieldErr={
                              touched.password_confirmation &&
                              errors.password_confirmation
                                ? errors.password_confirmation
                                : null
                            }
                            className={styles.websiteCreateAd} 
                          />
                          </Grid>
                          <Grid item xs={0} md={3}/>
                          <Grid item xs={2} md={4}/>
                           <Grid item xs={8} md={4}>
                          <AuthButton
                            onClick={() => setResetPass(false)}
                            btnText={strings.resetPass}
                            lang={lang}
                            //linkText={strings.backToLogin}
                            linkPath="/login"
                            isDisabled={!dirty}
                          />
                          </Grid>
                          <Grid item xs={2} md={4}/>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid item xs={1} md={2}></Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Box>
        : null }
      </Container>
    </div>
  );
}

export default Account;
