import React, { useState, useEffect, useContext } from "react";
import AuthTitle from "../../components/auth/AuthTitle";
import AuthLayout from "../../layouts/AuthLayout";
import strings from "../../lang/lang";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AuthField from "../../components/auth/AuthField";
import AuthButton from "../../components/auth/AuthButton";
import { useHistory } from "react-router";
import { forgotPwdApi } from "../../api";

function ForgetPassword(props) {
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const history = useHistory();
  const initialValues = { email: "" };
  const validationSchema = Yup.object({
    email: Yup.string()
      .required(strings.emailrequired)
      .email(strings.invalidemail),
  });

  const handleForgetPwd = (values) => {
    localStorage.setItem('email', values.email)

    forgotPwdApi(values)
      .then((res) => {
        history.push("/verification-code");
      })
      .catch((err) => console.log(err));
        history.push("/verification-code");
    

  };

  useEffect(() => {
    setLang(localStorage.getItem("lang"));
  }, [props.onChange]);

  return (
    <AuthLayout props={props}>
      <div className="xs:mt-7 sm:mt-8 xl:mx-12">
        <div className="w-full flex justify-center">
          <img
            src="/images/forgot-pwd.svg"
            className="w-full h-64 rounded-lg"
            alt="img"
            title="forgot password?"
          />
        </div>

        <Formik
          initialValues={initialValues}
          onSubmit={handleForgetPwd}
          validationSchema={validationSchema}
        >
          {({ touched, errors, dirty }) => (
            <Form className="px-3 mt-10">
              <AuthField
                label={strings.email}
                type="text"
                name="email"
                fieldErr={touched.email && errors.email ? errors.email : null}
              />

              <AuthButton
                btnText={strings.sendEmail}
                lang={lang}
                linkText={strings.backToLogin}
                linkPath="/login"
                isDisabled={!dirty}
              />
            </Form>
          )}
        </Formik>
      </div>
    </AuthLayout>
  );
}

export default ForgetPassword;
