import React from "react";
import { Slider } from "@material-ui/core";
import strings from "../../lang/lang";


function AgeSelect({ ageRanges, age1, age2, setAge }) {
  const lang = localStorage.getItem("lang");
  
  return (
    <div className="my-6" dir={lang == "en" ? "rtl" : "ltr"}>
      <label
        className={`w-full mb-3 text-lg sm:text-xl font-semibold ${
          lang == "ar" && "text-right"
        }`}
      >
        {strings.age}
      </label>
      <div className="sm:ml-10 sm:mr-28 flex space-x-3 sm:space-x-5">
        <div className="font-semibold text-gray-600 ">{age2}</div>
        <Slider
          step={7}
          min={14}
          max={90}
          value={ageRanges}
          onChange={(event, newValue) => setAge(newValue)}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
        />
        <div className="font-semibold text-gray-600">{age1}</div>
      </div>
    </div>
  )
}

export default AgeSelect;
