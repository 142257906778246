import { CircularProgress, Grid, TextField, Button } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCitiesByStateId } from "../../api";
import strings from "../../lang/lang";
import useStyles from "../../jsxStyles/publisher";

function NeighborhoodSelect(props) {
  const lang = localStorage.getItem("lang");
  const state_id = useSelector((state) => state?.miscData?.state_id);
  const [neighborhood, setNeighborhood] = useState([]);
  const [isLoading, setLoading] = useState(false);
  let neighborhoodData = [];
  const selectAllInterest = (__) => {
  props.setSelectedNeighborhood(neighborhood);
  };
  const styles = useStyles();

  useEffect(() => {
    setLoading(true);
    // props.setSelectedNeighborhood([])
    setNeighborhood([]);
    if (state_id.length > 0) {
      neighborhoodData = [];
      state_id.map(item=>{
        getCitiesByStateId(item, lang).then((result) => {
          neighborhoodData = [...neighborhood, ...result?.data?.data];
          setNeighborhood(neighborhoodData);
          setLoading(false);
        });
      })
    } else {
      setNeighborhood([]);
      setLoading(false);
    }
  }, [state_id]);

  return (
    <div className="my-6">
      <label className={`w-full mb-3 text-lg sm:text-xl font-semibold`}>
        {strings.neighborhoodinterest}
      </label>
      <Grid container spacing={3} style={{ direction: "auto" }}>
        <Grid item xs={8}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Autocomplete
              id="combo-box-demo"
              multiple
              options={neighborhood}
              className={styles.countrySelect}
              getOptionLabel={(option) => option.name}
              value={props.selectedNeighborhood ?? ""}
              onChange={(event, value) => {
                props.setSelectedNeighborhood(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  style={{ margin: "10px 0px" }}
                  variant="outlined"
                  placeholder={strings.selectneighborhoodinterest}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={4} style={{ display: "flex", padding: "1.4rem" }}>
          <Button
            fullwidth
            variant="contained"
            onClick={selectAllInterest}
            color="primary"
          >
            {strings.selectAll}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default NeighborhoodSelect;
