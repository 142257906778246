import { Grid, Typography, Button, Card, CardContent, CardHeader, Select, MenuItem, FormControl, InputLabel, TextField, FormLabel, RadioGroup, FormControlLabel, Radio, CircularProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import strings from '../../lang/lang'
import SubscriptionBar from './SubscriptionBar'
import useStyles from '../../jsxStyles/publisher'
import { Container } from 'reactstrap'
import { useHistory, useParams } from 'react-router'
import { isRenewable, publisherAdApi, renewAd } from '../../api'
import { useDispatch } from 'react-redux'
import { savePrice } from '../../store/actions/paymentActions'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Alert = withReactContent(Swal)

export default function RenewAd(props) {
    const lang = localStorage.getItem('lang')
    const token = localStorage.getItem('token')
    const [budget, setBudget] = useState(100)
    const [costCollection, setCostCollection] = useState([])
    const [grossTotal, setGrossTotal] = useState(0)
    const [incentive, setIncentive] = useState("nothing")
    const [gift_type, setGiftType] = useState("gift")
    const [incentive_amount, setIncentiveAmount] = useState("")
    const [incentive_users, setIncentiveUsers] = useState("")
    const [subscription_id, setSubscriptionId] = useState(0)
    const [renewable, setRenewable] = useState(false)
    const [product, setProduct] = useState(false)
    const { product_id } = useParams()
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(__ => {
        publisherAdApi(token, product_id)
        .then(res =>{
           localStorage.setItem('product',JSON.stringify(res?.data?.data?.product))
        })
        // isRenewable(token, product_id)
        //     .then(res => {
        //         let data = res.data;
        //         setProduct(data.data)
        //         setRenewable(data.status)
        //         if (!data.status) {
        //             Alert.fire("Ad is not renewable")
        //                 .then(response => {
        //                     if (response.isConfirmed) {
        //                         history.push('/publisher/dashboard')
        //                     }
        //                 })
        //         }
        //     })
        setRenewable(true)
    }, [])


    const handleSubmit = () => {
        history.push('/publisher/ad_response')
        // let data = {
        //     subscription_id,
        //     incentive,
        //     incentive_amount,
        //     incentive_users,
        //     gift_type
        // }
        // renewAd(token, data, product_id)
        //     .then(res => {
        //         if (res.data.status) {
        //             dispatch(savePrice(grossTotal))
        //             localStorage.setItem('product',JSON.stringify(product))
        //             window.location.href = `/publisher/ad_response?product_id=${product_id}`;
        //         }
        //     })

    }

    return (
        <Container className="mt-10" style={{ direction: lang === "en" ? "ltr" : "rtl", textAlign: lang === "en" ? "left" : "right" }}>
            <Card>
                <CardHeader
                    title={strings.renew}
                />
                <CardContent>
                    <Grid container>
                        {
                            renewable ?
                                <>
                                    <Grid item xs={12}>

                                        <SubscriptionBar
                                            {
                                            ...{
                                                budget,
                                                setBudget,
                                                costCollection,
                                                subscription_id,
                                                setSubscriptionId,
                                                setCostCollection,
                                                setIncentive,
                                                incentive,
                                                setGiftType,
                                                gift_type,
                                                incentive_amount,
                                                setIncentiveAmount,
                                                incentive_users,
                                                setIncentiveUsers,
                                                setGrossTotal,
                                                grossTotal
                                            }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            className="mt-5 d-flex justify-right"
                                            onClick={handleSubmit}
                                        >
                                            {strings.continueToPayment}
                                        </Button>
                                    </Grid>
                                </>
                                :
                                <CircularProgress />
                        }

                    </Grid>
                </CardContent>
            </Card>

        </Container>
    )
}
