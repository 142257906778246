import { useState } from "react";
import {
  Grid,
  Card,
  TextField,
  Button,
  Container,
  CircularProgress,
} from "@material-ui/core";
import CreditCard from "react-credit-cards";
import axios from "axios";
import strings from "../../lang/lang";
import QueryString from "query-string";
import { useLocation, useParams } from "react-router";
import GoBack from "../../components/GoBack";
import makeStyles from "../../jsxStyles/payment";
import { useEffect } from "react";
import { subscriptionByIdApi } from "../../api";
import { calculatePrice } from "../../utils/helpers";
import { useCallback } from "react";

const OnlinePayment = (props) => {
  const direction = localStorage.getItem("lang") == "en" ? "ltr" : "rtl";
  const { product_id, type } = useParams();
  const ad_price = localStorage.getItem("ad_price");
  const subscription_id = localStorage.getItem("subscription_id");
  const tax = ad_price * 0.15;
  const total_amount = +tax + +ad_price;
  const callback_url = `https://thmin.com/en/${type}`;
  const publishable_api_key = "pk_live_5MYJy1Dtu4Edqhp4uUoxZc2Ft8Y1UuQKsqFmhuxh";  //live key
  //const publishable_api_key = "pk_test_Mb3NboGVtdbam6pVfVdnruqNyzYs5gDQgReR2EvD";//test key
  const source_type = "creditcard";
  const [source_name, setSourceName] = useState("");
  const [source_number, setSourceNumber] = useState("");
  const [source_month, setSourceMonth] = useState("");
  const [source_year, setSourceYear] = useState("");
  const [source_cvc, setSourceCvc] = useState("");
  const [focused, setfocused] = useState("number");
  const [errors, setErrors] = useState([]);
  const [paidAmount, setPaidAmount] = useState(0);
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect((__) => {
    subscriptionByIdApi(subscription_id).then((res) => {
      if (res?.data?.status) {
        let price = calculatePrice(res?.data?.data);
        setPaidAmount(price * 100);
      }
    });
  }, []);

  const handleOnlinePayment = () => {
    setIsSubmit(true);
    const metadata = {
            product_id: product_id,
            subscription_id: subscription_id
    };

    var form_data = new FormData();
    form_data.append("callback_url", callback_url);
    form_data.append("publishable_api_key", publishable_api_key);
    form_data.append("amount", paidAmount);
    form_data.append("description", `${product_id},${subscription_id}`);
    //form_data.append("metadata", metadata);
    form_data.append("source[type]", source_type);
    form_data.append("source[name]", source_name);
    form_data.append("source[number]", source_number);
    form_data.append("source[month]", source_month);
    form_data.append("source[year]", source_year);
    form_data.append("source[cvc]", source_cvc);
    axios
      .post("https://api.moyasar.com/v1/payments", form_data)
      .then((result) => {
        let url = result?.data?.source?.transaction_url;
        window.location.href = url;
      })
      .catch((err) => {
        setIsSubmit(false);
        if (err.response) setErrors(err.response.data.errors);
      });
  };

  return (
    <>
      <GoBack />
      <Container style={{ direction: direction }}>
        <Grid container spacing={3}>
          <Grid item md={3} style={{ marginTop: "5rem" }}>
            <CreditCard
              cvc={source_cvc}
              expiry={`${source_month}/${source_year}`}
              focused={focused}
              name={source_name}
              number={source_number}
            />
          </Grid>
          <Grid item md={6}>
            <header className="mb-6 flex justify-center">
              <h1 className="text-3xl font-medium sm:font-semibold text-gray-700 tracking-wide">
                {strings.payment.formTitle}
              </h1>
            </header>
            {Object.keys(errors).length > 1 && errors?.company && (
              <p className="mb-4 text-sm text-red-600">
                {errors?.company[0] ? errors?.company[0] : ""}
              </p>
            )}
            <Grid container spacing={3}>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  type="number"
                  name="source[number]"
                  value={source_number}
                  onChange={(val) => {
                    if (val.target.value.length <= 16)
                      setSourceNumber(val.target.value);
                    setfocused("number");
                  }}
                  autoComplete="off"
                  placeholder={strings.payment.number}
                  helperText={
                    errors?.number && errors?.number[0] ? errors?.number[0] : ""
                  }
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  type="text"
                  name="source[name]"
                  value={source_name}
                  onChange={(val) => {
                    setSourceName(val.target.value);
                    setfocused("name");
                  }}
                  autoComplete="off"
                  placeholder={strings.payment.name}
                  helperText={
                    errors?.name && errors?.name[0] ? errors?.name[0] : ""
                  }
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  type="text"
                  value={source_month}
                  onChange={(val) => {
                    if (val.target.value.length <= 2)
                      setSourceMonth(val.target.value);
                    setfocused("expiry");
                  }}
                  name="source[month]"
                  placeholder="04"
                  autoComplete="off"
                  helperText={
                    errors?.month && errors?.month[0] ? errors?.month[0] : ""
                  }
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  type="text"
                  value={source_year}
                  onChange={(val) => {
                    if (val.target.value.length <= 4)
                      setSourceYear(val.target.value);
                    setfocused("expiry");
                  }}
                  name="source[year]"
                  autoComplete="off"
                  placeholder="2024"
                  helperText={
                    errors?.year && errors?.year[0] ? errors?.year[0] : ""
                  }
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  type="text"
                  name="source[cvc]"
                  value={source_cvc}
                  onChange={(val) => {
                    if (val.target.value.length <= 3)
                      setSourceCvc(val.target.value);
                    setfocused("cvc");
                  }}
                  placeholder={strings.payment.cvc}
                  autoComplete="off"
                  helperText={
                    errors?.cvc && errors?.cvc[0] ? errors?.cvc[0] : ""
                  }
                />
              </Grid>
              <Grid item md={12}>
                {isSubmit ? (
                  <CircularProgress />
                ) : (
                  <Button
                    color="primary"
                    fullWidth
                    variant="contained"
                    onClick={handleOnlinePayment}
                  >
                    {strings.payment.payBtn}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default OnlinePayment;
