let initialValues = {
    country_id: [],
    state_id: []
}

export default (state = initialValues, action) => {
    let updatedState;
    switch(action.type){
        case 'CountrySelect':
            updatedState = {...state, country_id:action.payload}
            return updatedState
        case 'StateSelect':
            updatedState = {...state, state_id:action.payload}
            return updatedState
        default:
            return state;
    }
}