import {
  Grid,
} from "@material-ui/core";
import React, { useState, useContext, useEffect } from "react";
import { AudienceContext } from "../../contexts/audience";
import EditAudience from "../audience/Index";
import useStyles from "../../jsxStyles/publisher";
import { useHistory, useParams } from "react-router";
import {
  updateAdApi,
} from "../../api";

export default function Audience() {
  const [editAudience, setEditAudience] = useState(false);
  const [demographic, setDemographic] = useState({
    gender: 0,
    age: [14, 65],
    users_jobs: "",
    country: "",
    state: "",
  });
  const token = localStorage.getItem("token");
  const history = useHistory();
  const { product_id } = useParams();
  

  const {
    selectedCountries,
    selectedStates,
  } = useContext(AudienceContext);

  useEffect(() => {
    
    

  }, []);

  useEffect(() => {
      // const countries = selectedCountries == "" ? [] : selectedCountries.map((item) => item.id);
      // const states = selectedStates == "" ? [] : selectedStates.map((item) => item.id);
      // const job = selectedJobs == "" ? [] : selectedJobs.map((item) => item.id);
      // setDemographic({
      //   gender: gender,
      //   age: getAgeCategory(age),
      //   users_jobs: job.length > 0 ? job.join(",") : "",
      //   country: countries.length > 0 ? countries.join(",") : "",
      //   state: states.length > 0 ? states.join(",") : "",
      // });
    },[selectedCountries, selectedStates]);

  const handleSubmit = (__) => {
    updateAdApi(
      token,
      { demographicData: JSON.stringify(demographic) },
      product_id
    ).then((res) => {
      let data = res?.data;
      if (data.status) history.push(`/publisher/myad/${product_id}`);
    });
  };

  return (
    <div style={{ padding: "1rem" }}>
      <Grid
        container
        spacing={3}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Grid item xs={12} md={10}>
          <EditAudience
            setEditAudience={setEditAudience}
          />
        </Grid>
      </Grid>
    </div>
  );
}
