import React from 'react'
import {
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Typography,
  Hidden,
  CircularProgress,
} from '@material-ui/core'
import CountUp from 'react-countup'
import clsx from 'clsx'
import useStyles from '../jsxStyles/publisher.jsx'
import { useHistory } from 'react-router'
function TotalCount(props) {
  let history = useHistory()
  let lang = localStorage.getItem('lang')
  const classes = styles()
  return (
    <Card
      className={clsx(classes.card, 'cardAnim')}
      style={{ direction: `${lang == 'en' ? 'ltr' : 'rtl'}` ,textAlign:lang == "en"?"left":"right"}}
    >
      <CardHeader
        className={classes.header}
        avatar={
          <Avatar
            aria-label="recipe"
            style={{ backgroundColor: props.backgroundColor }}
            classes={{ root: classes.avatar }}
          >
            {props.icon}
          </Avatar>
        }
        title={<Typography className={classes.title}>{props.title}</Typography>}
      />
      <CardContent className={clsx(classes.ptb0, classes.content)}>
        <Typography className={classes.count}>
          <span className={classes.symbol}>{props.symbol}</span>
          {props.count == null ? (
            <CircularProgress />
          ) : props.symbol != '' ? (
            parseFloat(props.count)
          ) : (
            parseFloat(props.count)
          )}
        </Typography>
        <Hidden only={['xs', 'sm']}>
          <Typography
            className={classes.link}
            onClick={() => {
              history.push(props.url)
              if (props.updateSelectedIndex !== undefined) {
                props.updateSelectedIndex()
              }
            }}
          >
            {props.urlText}&nbsp;&nbsp;
            {/* {props.users} */}
          </Typography>
        </Hidden>
      </CardContent>
    </Card>
    // <Grid item xs={6} lg={4}>
    //   <Card variant="elevation" className={classes.dashboardCards}>
    //     {/* <CardHeader color="black" subheader="Total Views" /> */}
    //     <CardContent className="card-content">
    //       <p>
    //         <CountUp end={value} />
    //       </p>
    //       <Typography
    //         style={{
    //           fontWeight: "500",
    //           fontSize: ".9375rem",
    //           color: "#a2a2a2",
    //         }}
    //       >
    //         {title}
    //       </Typography>
    //     </CardContent>
    //   </Card>
    // </Grid>
  )
}

const styles = makeStyles((theme) => {
  return {
    content: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    ptb0: {
      padding: '0rem 1rem !important',
    },
    link: {
      display: 'inline-flex',
      fontSize: '0.9rem',
      color: '#6C63FF',
      cursor: 'pointer',
    },
    count: {
      fontSize: '1.5rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.3rem',
        textAlign: 'center',
      },
    },

    header: {
      padding: '1rem 1rem 0rem 1rem',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        '& .MuiCardHeader-avatar': {
          marginRight: 0,
        },
      },
    },
    title: {
      fontSize: '1.3rem',
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        padding: '0.5rem 0 0 0',
      },
    },
    avatar: {
      fontSize: '1rem',
      [theme.breakpoints.down('sm')]: {
        marginRight: '0rem',
      },
    },
    symbol: {
      color: '#006AB3',
      fontSize: '3rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    card: {
      width: '100%',
      height: 'auto',
      boxShadow: '0px 10px 30px 0px rgba(0,0,0,0.1)',
      borderRadius: '0.625rem',
      // transition: "0.5s ease-in-out",
      // backgroundColor:"#006ab3",
      [theme.breakpoints.down('sm')]: {
        padding: '1rem 0rem 0.9rem 0rem',
        height: 'auto',
      },
      // transition: "background-size .5s, color .5s",
      // "@keyframes hoverAnimation":{
      //     "0%":{
      //         backgroundImage: "radial-gradient(red 0%, #fff 0%)",
      //     },
      //     "100%":{
      //         backgroundImage: "radial-gradient(red 100%, #fff 0%)",
      //     },
      // },
      // '&:hover': {
      //     animation: "hoverAnimation ease"
      // }
    },
  }
})

export default TotalCount
