import {makeStyles} from "@material-ui/core";

export default makeStyles(theme => {
  return {
      modalHeader:{
          backgroundColor:"#55bc72",
          color:"white",
          "& .close": {
           padding: 0,
           margin: 0
          },
      },
      imageView:{
        display:"flex",
        alignItems: "center",
      }
  }  
})
