import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthLayout from "../../layouts/AuthLayout";
import strings from "../../lang/lang";
import { Formik, Form } from "formik";
import AuthField from "../../components/auth/AuthField";
import AuthButton from "../../components/auth/AuthButton";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { verificationCodeApi } from "../../api";


function VerificationCode(props) {
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  //const [email, setEmail] = useState(localStorage.getItem("email"));
  const history = useHistory();
  const initialValues = { 
      code: ""
     };
 
   
  const handleVerificationCode = (values) => {
    values.email = localStorage.getItem("email");
    
    verificationCodeApi(values)
       .then((res) => {
         if (res.data.status == "success"){
          history.push("/resetPassword");
         }
         else{
           alert(res.data.errors.code)
           history.push("/verification-code");
         }
       })
       .catch((err) => console.log(err));
         history.push("/verification-code");
  };
  const validationSchema = Yup.object({
    code: Yup.string().required(strings.pwdRequired),
  });

  useEffect(() => {
    setLang(localStorage.getItem("lang"));
  }, [props.onChange]);
  return (
    <AuthLayout props={props}>
      <div className="flex flex-col items-center font-medium text-xl">
        <h1 className="mt-7 mb-6 xs:mb-10 text-green-600 font-semibold tracking-wide text-2xl xs:text-3xl lg:text-4xl">
          {strings.doneMsg1}
        </h1>
        <div>
          <img
            src="/images/email.svg"
            alt="email-sent"
            title="email sent!"
            className="w-full h-64"
          />
        </div>
        <div className="flex flex-col mt-10 text-gray-700 text-base xs:text-lg sm:text-xl">
          <span>{strings.doneMsg2}</span>
          <span>{strings.doneMsg3}</span>
          <Formik
            initialValues={initialValues}
            onSubmit={handleVerificationCode}
            validationSchema={validationSchema}
          >
            {({ touched, errors, dirty }) => (
              <Form className="px-3 mt-10">
                <AuthField
                  label={strings.labelPassword}
                  type="text"
                  name="code"
                  fieldErr={
                    touched.code &&
                    errors.code
                      ? errors.code
                      : null
                  }
                />
                <AuthButton
                  btnText={strings.verificationCode}
                  lang={lang}
                  linkText={strings.backToLogin}
                  linkPath="/login"
                  isDisabled={!dirty}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </AuthLayout>
  );
}

export default VerificationCode;
