import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
    noBorderShadow: {
        border: "none !important",
        boxShadow: "none !important"
    },
    root: {
        // maxWidth: 345,
        height: "100%"
    },
    media: {
        height: 200,
    },
    gridContainer: {
        height: "80%",
        display: "flex",
        justifyContent: "center",
        alignContent: "center"
    },
    pageTitle: {
        fontSize: '3rem',
        fontWeight: "500",
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },
    title: {
        color:"grey"
    },
    description:{
        color:"black",
        margin:"0 0.5rem"
    },
    titleDesc:{
        display:"flex"
    },
    bankInfo:{
        border:"1px dashed",
        margin:"0.5rem 0",
        padding:"0.5rem"
    }
}));

