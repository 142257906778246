import React, { useContext, useState } from 'react'
import { IoMdClose } from 'react-icons/io'
import {
  MdPhotoCamera,
  MdPhotoFilter,
  MdPhotoSizeSelectActual,
} from 'react-icons/md'
import { FaUser } from 'react-icons/fa'
import { AuthContext } from '../../contexts/authContext'
import { TextField } from '@material-ui/core'
import { updateProfileApi } from '../../api'
import strings from '../../lang/lang'

function EditProfileModal({ setModal, countryId }) {
  const storedToken = localStorage.getItem('authToken')
  const lang = localStorage.getItem('lang')
  const { authState } = useContext(AuthContext)
  const { user } = authState

  const [name, setName] = useState(user ? user.name : '')
  const [gender, setGender] = useState(user ? user.gender : '')
  const [birthdate, setBirthdate] = useState(user ? user.birthdate : '')
  const [mobile, setMobile] = useState(user ? user.mobile : '')
  const [profileFile, setProfileFile] = useState(user ? user.profile_img : '')
  const [coverFile, setCoverFile] = useState(user ? user.cover_img : '')
  const [imgPreview, setImgPreview] = useState(null)
  const [coverPreview, setCoverPreview] = useState(null)
  const [changeProfileText, setChangeProfileText] = useState(
    strings.changeProfile,
  )

  const handleImgChange = (type, file) => {
    if (file.size / 100000 > 2) setChangeProfileText('Less then 2mb is valid')
    if (
      file.type != 'image/png' ||
      file.type != 'image/jpg' ||
      file.type != 'image/jpeg' ||
      file.type != 'video/mp4'
    )
      if (file) {
        setChangeProfileText(strings.changeProfile)
        if (type == 'cover') {
          setCoverFile(file)
          setCoverPreview(URL.createObjectURL(file))
        } else if (type == 'profile') {
          setProfileFile(file)
          setImgPreview(URL.createObjectURL(file))
        }
      } else {
        alert('no image selected!')
      }
  }

  const handleProfileUpdate = () => {
    const formData = new FormData()
    formData.append('name', name)
    formData.append('mobile', mobile)
    formData.append('country_id', parseInt(countryId))
    formData.append('gender', gender)
    formData.append('birthdate', birthdate)
    imgPreview && formData.append('profile_img', profileFile)
    coverPreview && formData.append('cover_img', coverFile)

    updateProfileApi(storedToken, formData)
      .then((res) => {
        if (res?.data?.status) {
          window.location.reload()
          setModal(false)
        }
      })
      .catch((err) => console.log('errors image', err))
  }

  return (
    <>
      <button
        onClick={() => setModal(false)}
        className="w-full h-full fixed inset-0 bg-gray-700 opacity-50 focus:outline-none z-30 cursor-default"
      ></button>
      <div
        dir={lang == 'ar' ? 'ltr' : 'rtl'}
        className="w-full h-full inset-0 fixed flex justify-center items-center z-40"
      >
        <div className="h-124 sm:h-128 w-11/12 lg:w-7/12 xl:w-6/12 p-4 mt-24 lg:mt-32 bg-gray-50 rounded-lg overflow-auto overflow-x-hidden">
          <header className="px-2 flex justify-between items-center">
            <span className="text-center font-semibold text-2xl text-gray-700">
              {strings.editProfile}
            </span>
            <IoMdClose
              className="text-2xl text-gray-800 hover:text-red-500 cursor-pointer"
              onClick={() => setModal(false)}
            />
          </header>
          <main className="mt-12">
            <div className="sm:my-8 flex flex-col sm:flex-row sm:space-x-3 items-center sm:justify-around">
              <div
                className={`w-full my-3 sm:my-0 sm:w-5/12 flex flex-col ${
                  lang == 'ar' && 'items-end'
                }`}
              >
                <label className="font-semibold text-base">
                  {strings.profileName}
                </label>
                <input
                  type="text"
                  className="w-full sm:my-0 px-4 py-2 border-2
                border-gray-300 rounded-xl focus:border-green-500
                focus:outline-none"
                  value={name}
                  placeholder="Profile Name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div
                className={`w-full my-3 sm:my-0 sm:w-5/12 flex flex-col ${
                  lang == 'ar' && 'items-end'
                }`}
              >
                <label className="font-semibold text-base text-gray-700">
                  {strings.birthdate}
                </label>
                <input
                  type="date"
                  className="w-full px-4 py-2 border-2 border-gray-300 rounded-xl focus:border-green-500 focus:outline-none"
                  value={birthdate}
                  placeholder="Profile Name"
                  onChange={(e) => setBirthdate(e.target.value)}
                />
              </div>
            </div>

            <div className="sm:my-8 flex flex-col sm:flex-row sm:space-x-3 items-center sm:justify-around">
              <div
                className={`w-full my-3 sm:my-0 sm:w-5/12 flex flex-col ${
                  lang == 'ar' && 'items-end'
                }`}
              >
                <label className="font-semibold text-base text-gray-700">
                  {strings.mobile}
                </label>
                <input
                  type="number"
                  className="w-full px-4 py-2 border-2 border-gray-300 rounded-xl focus:border-green-500 focus:outline-none"
                  value={mobile}
                  placeholder={strings.mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </div>
              <div
                className={`w-full my-3 sm:my-0 sm:w-5/12 flex flex-col ${
                  lang == 'ar' && 'items-end'
                }`}
              >
                <label className="font-semibold text-base text-gray-700">
                  {strings.gender}
                </label>
                <select
                  className="w-full px-4 py-2 bg-white border-2 border-gray-300 rounded-xl focus:border-green-500 focus:outline-none"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option disabled value="">
                    {strings.selectGender}
                  </option>
                  <option value={0}>{strings.male}</option>
                  <option value={1}>{strings.female}</option>
                </select>
              </div>
            </div>
            <hr />
            <div className="my-8 px-1 sm:px-3 flex items-center justify-between">
              <label
                htmlFor="profile_img"
                className="w-1/2 sm:w-5/12 flex justify-center items-center space-x-1 p-2 bg-gray-200 hover:bg-gray-300 cursor-pointer rounded-xl group"
              >
                <span className="text-xs sm:text-base uppercase text-gray-900 tracking-wide">
                  {changeProfileText}
                </span>
                <MdPhotoCamera className="hidden sm:inline-flex text-2xl group-hover:text-gray-600" />
              </label>
              <div>
                <input
                  type="file"
                  id="profile_img"
                  onChange={(e) =>
                    handleImgChange('profile', e.target.files[0])
                  }
                  className="hidden"
                />
              </div>
              {imgPreview ? (
                <img
                  src={imgPreview}
                  className="w-12 h-12 sm:w-16 sm:h-16 rounded-full shadow-lg"
                  alt="default"
                />
              ) : user && user.profile_img ? (
                <img
                  src={user.profile_img}
                  className="w-12 h-12 sm:w-16 sm:h-16 rounded-full shadow-lg"
                  alt="profile_img"
                />
              ) : (
                <FaUser className="text-5xl sm:text-6xl text-gray-700 rounded-full shadow-lg" />
              )}
            </div>

            <hr />

            <div className="my-8 px-2 sm:px-3 flex items-center justify-between">
              <label
                htmlFor="cover_img"
                className="w-1/2 sm:w-5/12 flex justify-center items-center space-x-1 p-2 bg-gray-200 hover:bg-gray-300 cursor-pointer rounded-xl group"
              >
                <span className="text-xs sm:text-base uppercase text-gray-900 tracking-wide">
                  {strings.changeCover}
                </span>
                <MdPhotoFilter className="hidden sm:inline-flex text-2xl group-hover:text-gray-600" />
              </label>
              <input
                type="file"
                id="cover_img"
                onChange={(e) => handleImgChange('cover', e.target.files[0])}
                className="hidden"
              />
              {coverPreview ? (
                <img
                  src={coverPreview}
                  className="w-12 h-12 sm:w-16 sm:h-16 rounded-full shadow-lg"
                  alt="default"
                />
              ) : user && user.cover_img ? (
                <img
                  src={user.cover_img}
                  className="w-12 h-12 sm:w-16 sm:h-16 rounded-full shadow-lg"
                  alt="cover_img"
                />
              ) : (
                <img
                  src="/images/default-cover.png"
                  className="w-12 h-12 sm:w-16 sm:h-16 rounded-full shadow-lg"
                  alt="default"
                />
              )}
            </div>

            <div className="my-4 flex flex-col justify-center items-center">
              <button
                onClick={handleProfileUpdate}
                className="w-8/12 sm:w-1/2 px-4 py-2 text-gray-100 bg-green-500 hover:bg-green-600 rounded-full focus:outline-none"
              >
                {strings.saveChanges}
              </button>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default EditProfileModal
