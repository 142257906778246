import 'react-phone-input-2/lib/material.css'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from '@material-ui/core'
import BusinessIcon from '@material-ui/icons/Business'
import EmojiNatureIcon from '@material-ui/icons/EmojiNature'
import ImageIcon from '@material-ui/icons/Image'
import InstagramIcon from '@material-ui/icons/Instagram'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import styles from '../../jsxStyles/companywizard.jsx'
import strings from '../../lang/lang'
import Denied from '../../pages/publisher/svgs/access_denied.svg'
import { getCountries, getStateByCountryId } from '../../routes/auth'
import { createCompanyApi } from '../../api'
import {
  FaStreetView,
  FaInstagram,
  FaSnapchat,
  FaWhatsapp,
  FaFacebook,
  FaTiktok,
  FaTwitter,
} from "react-icons/fa";
import TextFieldWithIcon from "../TextFieldWithIcon.jsx";
import WebIcon from '@mui/icons-material/Web';

const validationRules = yup.object().shape({
  name: yup.string().required('Company name is required'),
  address: yup.string().required('Company Address is required'),
  instagram: yup.string().required('Instagram account is required'),
  snapchat: yup.string().required('Snapchat account is required'),
  country_id: yup.string().required('Please select country'),
  city_id: yup.string().required('Please select city'),
  whatsapp: yup.number().min(12, 'Too Short!'),
  logo: yup.mixed().required('A file is required'),
})

function CompanyWizard(props) {
  const classes = styles()
  const lang = localStorage.getItem('lang')
  const storedtoken = localStorage.getItem('authToken')
  const logoInputField = useRef()
  const [modal, setModal] = useState(false)
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [file, setFile] = useState(null)
  const [countryId, setCountryId] = useState("")
  const [selectText, setSelectText] = useState('Select Country')

  const { register, errors, setValue, getValues } = useForm({
    resolver: yupResolver(validationRules),
  })

  const toggle = () => setModal(!modal)
  const triggerImgUploadFunc = () => logoInputField.current.click()

  const uploadImg = (e) => {
    const allowedTypes = ['image/jpeg', 'image/png']
    const file = e.target.files[0]
    if (file && allowedTypes.includes(file.type)) {
      setFile(file)
    }
  }

  const handleCountryChange = (value) => {
    if(value != ""){
        setCountryId(value)
        setValue('country_id', value)
        getStateByCountryId(value).then((result) => {
        if (Object.keys(result.data).length == 0 || result.data.length == 0) {
          setStates([])
          setSelectText('No States Available')
          return
        }
        setStates(result.data)
      })
    }
    
  }

  const handleSubmit = () => {
    const values = getValues()
    const formData = new FormData()
    formData.append('name', values.name)
    formData.append('logo', file)
    formData.append('address', values.address)
    formData.append('whatsapp', values.whatsapp)
    formData.append('instagram', values.instagram)
    formData.append('snapchat', values.snapchat)
    formData.append('facebook', values.facebook)
    formData.append('twitter', values.twitter)
    formData.append('tiktok', values.tiktok)
    formData.append('website', values.website)
    formData.append('country_id', values.country_id)
    formData.append('city_id', values.city_id)

    createCompanyApi(storedtoken, formData).then((res) => {
      if (!res.data.status) {
        if(file){
          Swal.fire({
            imageUrl: Denied,
            imageHeight: '6.25rem',
            title: 'Error!',
            text: strings.companyexist,
          })
          props.toggleCompanyModal()
        }else{
          Swal.fire({
            imageUrl: Denied,
            imageHeight: '6.25rem',
            title: 'Error',
            text: strings.requiredPoster,
          })
        }
        
      } else {
        Swal.fire(strings.companyCreated)
        props.toggleCompanyModal()
      }
    })
  }

  useEffect(() => {
    getCountries().then((result) => {
      setCountries(result.data)
      const values = getValues()
      if(values.country_id != "")
        handleCountryChange(values.country_id)
    })
  }, [])

  return (
    <div>
      <Modal 
      isOpen={true}
      toggle={toggle}
      size="lg"
      style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
      >
        <ModalHeader
          toggle={props.toggleCompanyModal}
          className={classes.modalHeader}
        >
          {strings.createcompany}
        </ModalHeader>
        <ModalBody>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required={true}
                fullWidth
                variant="outlined"
                type="button"
                name="logo"
                color="primary"
                helperText={errors?.logo?.message}
                error={errors?.logo}
                inputRef={register}
                onClick={triggerImgUploadFunc}
                label={strings.companylogo}
                value={file ? file.name : strings.companyLogo}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ImageIcon htmlColor="" />
                    </InputAdornment>
                  ),
                }}
              />
              <input
                type="file"
                ref={logoInputField}
                name="logo"
                onChange={uploadImg}
                accept=".jpg,.png"
                className="hidden"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required={true}
                inputRef={register}
                name="name"
                fullWidth
                label={strings.companyName}
                variant="outlined"
                error={errors?.name}
                helperText={errors?.name?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BusinessIcon htmlColor="" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{direction: lang === "ar" ? "ltr" : "ltr"}}>
              <input
                name="whatsapp"
                onChange={(e) => setValue(e.target.value)}
                ref={register}
                type="text"
                style={{ display: 'none'}}
              />
              <PhoneInput
                fullWidth
                onChange={(value) => setValue('whatsapp', value)}
                country={'sa'}
                specialLabel={strings.mobile}
                error={errors?.whatsapp}
                helperText={errors?.whatsapp?.message}
                inputProps={{
                  required: true,
                  max: '12',
                  name: 'name',
                  variant: 'standard',
                  style: { width: '100%', padding: '27.5px 14px 27px 51px'},
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <input type="hidden" ref={register} name="country_id" />
              {countries.length > 0 && (
                <TextField
                  value={countryId}
                  fullWidth={true}
                  variant="outlined"
                  label={strings.selectCountry}
                  select={true}
                  error={errors?.country_id}
                  helperText={errors?.country_id?.message}
                  onChange={(e) => handleCountryChange(e.target.value)}
                >
                  {countries.map(({ name, id }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <input type="hidden" ref={register} name="city_id" />
              <TextField
                fullWidth={true}
                variant="outlined"
                label={strings.selectCity}
                select={true}
                error={errors?.city_id}
                helperText={errors?.city_id?.message}
                onChange={(e) => setValue('city_id', e.target.value)}
              >
                {states.length > 0 &&
                  states.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth={true}
                variant="outlined"
                inputRef={register}
                name="address"
                label={strings.companyAddress}
                error={errors?.address}
                helperText={errors?.address?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationCityIcon htmlColor="" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                variant="outlined"
                inputRef={register}
                name="instagram"
                error={errors?.instagram}
                helperText={errors?.instagram?.message}
                InputProps={{
                  placeholder: 'https://www.instagram.com/johndoe/',
                  startAdornment: (
                    <InputAdornment position="start">
                      <FaInstagram htmlColor="" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                inputRef={register}
                variant="outlined"
                error={errors?.snapchat}
                helperText={errors?.snapchat?.message}
                name="snapchat"
                InputProps={{
                  placeholder: 'https://www.snapchat.com/johndoe/',
                    startAdornment: (
                      <InputAdornment position="start">
                        <FaSnapchat htmlColor="" />
                      </InputAdornment>
                    ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                inputRef={register}
                variant="outlined"
                error={errors?.facebook}
                helperText={errors?.facebook?.message}
                name="facebook"
                InputProps={{
                  placeholder: 'https://www.facebook.com/johndoe/',
                  startAdornment: (
                    <InputAdornment position="start">
                      <FaFacebook htmlColor="" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                inputRef={register}
                variant="outlined"
                error={errors?.twitter}
                helperText={errors?.twitter?.message}
                name="twitter"
                InputProps={{
                  placeholder: 'https://www.twitter.com/johndoe/',
                  startAdornment: (
                    <InputAdornment position="start">
                      <FaTwitter htmlColor="" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                inputRef={register}
                variant="outlined"
                error={errors?.tiktok}
                helperText={errors?.tiktok?.message}
                name="tiktok"
                InputProps={{
                  placeholder: 'https://www.tiktok.com/johndoe/',
                  startAdornment: (
                    <InputAdornment position="start">
                      <FaTiktok htmlColor="" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                inputRef={register}
                variant="outlined"
                error={errors?.website}
                helperText={errors?.website?.message}
                name="website"
                InputProps={{
                  placeholder: 'https://www.website.com/johndoe/',
                  startAdornment: (
                    <InputAdornment position="start">
                      <WebIcon htmlColor="" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <div>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              style={{ margin: '0.5rem' }}
            >
              {strings.create}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={props.toggleCompanyModal}
            >
              {strings.cancel}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default CompanyWizard
