import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams, withRouter } from "react-router-dom";

import { AudienceContext } from "../../contexts/audience";
import strings from "../../lang/lang";
import AgeSelect from "./AgeSelect";
import AgeSelects from "./AgeSelects";
import GenderSelect from "./GenderSelect";
import UserIntersets from "./UserIntersets";
import CountriesSelect from "./CountriesSelect";
import StatesSelect from "./StatesSelect";
import NeighborhoodSelect from "./NeighborhoodSelect";
import Spinner from "react-bootstrap/Spinner";
import { saveAudienceApi, publisherAdApi } from "../../api";
import {
  getUserJobs,
  getCountries,
  getStates,
  getNeighborhood,
} from "../../routes/auth";
import { getAgeRangeByCategory } from "../../utils/selectAgeCategory";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";

function EditAudience({ setEditAudience, create }) {
  const storedToken = localStorage.getItem("authToken");
  const lang = localStorage.getItem("lang");
  const history = useHistory();
  const params = useParams();
  const {
    selectedCountries,
    setSelectedCountries,
    selectedStates,
    setSelectedStates,
    selectedNeighborhood,
    setSelectedNeighborhood,
    selectedJobs,
    setSelectedJobs,
    gender,
    setGender,
    age,
    setAge,
  } = useContext(AudienceContext);

  const Alert = withReactContent(Swal);
  const [adData, setAdData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [existingCountries, setExistingCountries] = useState([]);
  const dispatch = useDispatch();
  const saveSelectedData = () => {
    if (!create) {
      const formData = new FormData();
      formData.append(
        "country",
        selectedCountries.map((item) => item.name)
      );
      formData.append(
        "state",
        selectedStates.map((item) => item.name)
      );
      formData.append(
        "neighborhood",
        selectedNeighborhood.map((item) => item.name)
      );
      formData.append(
        "users_jobs",
        selectedJobs.map((item) => item.id)
      );
      formData.append("age", age);
      formData.append("gender", gender);
      formData.append("product_id", params.product_id);
      saveAudienceApi(storedToken, formData).then((res) => {
        if (res?.data?.status)
          history.push(`/publisher/myad/${params.product_id}`);
      });
    } else {
      const formData = new FormData();

      formData.append(
        "country",
        selectedCountries.map((item) => item.name)
      );
      formData.append(
        "state",
        selectedStates.map((item) => item.name)
      );
      formData.append(
        "neighborhood",
        selectedNeighborhood.map((item) => item.name)
      );
      formData.append(
        "users_jobs",
        selectedJobs.map((item) => item.id)
      );
      formData.append("age", age);
      formData.append("gender", gender);
    }
    setEditAudience(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!create)
      publisherAdApi(storedToken, params.product_id).then((result) => {
        let data = result.data.data;
        if (data?.status) {
          localStorage.setItem(
            "country_id",
            data?.product?.demographicData?.country
          );
          if (!data?.viewStatus)
            Alert.fire("You are not authrozied to see this ad");
          else {
            if (data?.product?.demographicData?.country != "") {
              getCountries(lang).then((res) => {
                let countries = [];
                if (data?.product?.demographicData?.country.includes(",")) {
                  data?.product?.demographicData?.country
                    .split(",")
                    .map((value) => {
                      let country = res?.data.filter((item) => {
                        return item.id == value;
                      });
                      if (country.length > 0) countries.push(country[0]);
                    });
                } else {
                  let country = res?.data.filter((item) => {
                    return item.id == data?.product?.demographicData?.country;
                  });
                  if (country.length > 0) countries.push(country[0]);
                }
                setSelectedCountries(countries);
              });
            }
            if (data?.product?.demographicData?.state != "") {
              getStates(lang).then((res) => {
                let states = [];
                if (data?.product?.demographicData?.state.includes(",")) {
                  data?.product?.demographicData?.state
                    .split(",")
                    .map((value) => {
                      let state = res?.data.filter((item) => {
                        return item.id == value;
                      });
                      if (state.length > 0) states.push(state[0]);
                    });
                } else {
                  let state = res?.data.filter((item) => {
                    return item.id == data?.product?.demographicData?.state;
                  });
                  if (state.length > 0) states.push(state[0]);
                }
                
                setSelectedStates(states);
              });
            } else setSelectedStates([]);

            if (data?.product?.demographicData?.neighborhood != "") {
              getNeighborhood(lang).then((res) => {
                let neighborhoods = [];
                if (
                  data?.product?.demographicData?.neighborhood.includes(",")
                ) {
                  data?.product?.demographicData?.neighborhood
                    .split(",")
                    .map((value) => {
                      let neighborhood = res?.data.filter((item) => {
                        return item.id == value;
                      });
                      if (neighborhood.length > 0)
                        neighborhoods.push(neighborhood[0]);
                    });
                } else {
                  let neighborhood = res?.data.filter((item) => {
                    return (
                      item.id == data?.product?.demographicData?.neighborhood
                    );
                  });
                  if (neighborhood.length > 0)
                    neighborhoods.push(neighborhood[0]);
                }
                setSelectedNeighborhood(neighborhoods);
              });
            } else setSelectedNeighborhood([]);

            if (data?.product?.demographicData?.users_jobs != "") {
              getUserJobs(lang).then((res) => {
                let jobs = [];
                if (data?.product?.demographicData?.users_jobs.includes(",")) {
                  data?.product?.demographicData?.users_jobs
                    .split(",")
                    .map((value) => {
                      let job = res?.data.filter((item) => {
                        return item.id == value;
                      });
                      if (job.length > 0) jobs.push(job[0]);
                    });
                } else {
                  let job = res?.data.filter((item) => {
                    return (
                      item.id == data?.product?.demographicData?.users_jobs
                    );
                  });
                  if (job.length > 0) jobs.push(job[0]);
                }
                setSelectedJobs(jobs);
              });
            } else setSelectedJobs([]);
            if (data?.product?.demographicData?.age != "") {
              let age = data?.product?.demographicData?.age;

              if (age.includes(",")) setAge(age.split(","));
              else
                setAge(getAgeRangeByCategory(data?.product?.demographicData?.age));
            } else setAge([18, 65]);

            if (data?.product?.demographicData?.gender != "")
              setGender(data?.product?.demographicData?.gender);
            else setGender(2);
            setAdData(data);
          }
          setIsLoading(false);
        }
      });
    setIsLoading(false);
    return () => {
      dispatch({
        type: "CountrySelect",
        payload: [],
        type: "StateSelect",
        payload: [],
      });
    };
  }, []);

  return (
    <div
      style={{ height: "88rem", textAlign: lang == "en" ? "left" : "right" }}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <h1 style={{ textAlign: "center", paddingBottom: 12, fontSize: 24 }}>
            {strings.editaudience}
          </h1>
          <div id="editAudience">
            <div style={{ fontSize: 18 }}>{strings.editaudiencetext}</div>
            <GenderSelect gender={gender} setGender={setGender} />
            <AgeSelect
              ageRanges={age}
              age1={age[0]}
              age2={age[1]}
              setAge={setAge}
            />
            {/* <AgeSelects
              ageRanges={(age)}
              setAge={setAge}
            /> */}
            <UserIntersets
              selectedJobs={selectedJobs}
              setSelectedJobs={setSelectedJobs}
            />
            <CountriesSelect
              countries={existingCountries}
              selectedCountries={selectedCountries}
              setSelectedCountries={setSelectedCountries}
            />
            <StatesSelect
              selectedStates={selectedStates}
              setSelectedStates={setSelectedStates}
            />
            <NeighborhoodSelect
              selectedNeighborhood={selectedNeighborhood}
              setSelectedNeighborhood={setSelectedNeighborhood}
            />
            {/* <LocationSelect locations={locations} setLocations={setLocations} /> */}
          </div>
          <div className="w-full flex justify-end -ml-6 mb-4 mt-4 sm:mt-8 pt-3 border-t-4 border-gray-100">
            {/* <button
              onClick={() => setEditAudience(false)}
              className="mx-2 px-3 sm:px-5 py-1.5 sm:py-2.5 text-sm sm:text-lg bg-gray-200 hover:bg-gray-300 font-semibold text-gray-700 border-2 border-gray-300 focus:border-gray-400 rounded-lg focus:outline-none focus:bg-gray-700 focus:text-gray-300 tracking-wide"
            >
              {strings.cancel}
            </button> */}
            <button
              onClick={saveSelectedData}
              className="mx-2 px-3 sm:px-5 py-1.5 sm:py-2.5 text-sm sm:text-lg bg-green-400 hover:bg-green-500 font-semibold text-gray-100 border-2 border-green-400 rounded-lg focus:outline-none focus:bg-green-700 tracking-wide"
            >
              {strings.saveaudience}
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default withRouter(EditAudience);
