import React, { useEffect, useState } from "react"

function NotFound(props) {
  const [lang, setLang] = useState(localStorage.getItem("lang"))

  useEffect(() => {
    setLang(localStorage.getItem("lang"))
  }, [props.onChange])
  return (
    <div className="transform translate-y-72 text-center">
      <h1 className="text-gray-500 font-semibold text-2xl sm:text-3xl">
        404 | Not Found Page!
      </h1>
    </div>
  )
}

export default NotFound
