import { Animation, Stack } from '@devexpress/dx-react-chart';
import { ArgumentAxis, BarSeries, Chart, Tooltip, ValueAxis } from '@devexpress/dx-react-chart-material-ui';
import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { visitsAdApi } from '../../api';
import strings from '../../lang/lang';
import styles from '../../modules/dashboard.module.css';

function VisitsChart(props) {
  const storedToken = localStorage.getItem("authToken");
  const [data, setData] = useState([]);
  useEffect(() => {
    visitsAdApi(storedToken, props.data).then(({ data }) => {
      setData(data?.data);
    });
  }, []);
  return (
    <Box style={{ height: data.length > 0 && "35rem" }}>
      {
        data.length > 0 ?
          <Chart data={data}>
            <ArgumentAxis />
            <ValueAxis />
            <BarSeries
              name="Visits"
              valueField="visits"
              argumentField="month"
              color="#55BC72"
            />
            <Animation />
            <p className={styles.chartTitle}>{strings.visitspermonth}</p>
            <Stack />
            <Tooltip />
          </Chart>
          :
          <>
            <p className={styles.chartTitle}>{strings.visitspermonth}</p>
            <Typography align="center">No data to avialble right now</Typography>
          </>
      }

    </Box>
  );
}

export default VisitsChart;
