export const ar = {
  getStarted: 'البدء',
  english: 'الإنجليزية',
  arabic: 'عربى',
  asUser: 'تسجيل الدخول كمستخدم',
  asPublisher: 'تسجيل الدخول كناشر',
  earner: 'الكسب',
  publisher: 'الناشر',
  login: 'تسجيل الدخول',
  loginTitle: 'اهلًا وسهلا بعودتك !',
  forgotPwd: 'نسيت كلمة المرور؟',
  newAccount: 'ليس لديك حساب؟',
  register: 'تسجيل',
  haveAccount: 'لديك حساب بالفعل؟',
  email: 'عنوان بريد الكتروني',
  emailrequired: 'البريد الالكتروني مطلوب',
  invalidemail: 'البريد الإلكتروني غير صالح',
  password: 'ادخل كلمه المرور',
  pwdRequired: 'كلمة المرور مطلوبة',
  password_confirmation: 'تأكيد كلمة المرور',
  confirmPwdRequired: 'تاكيد كلمة المرور مطلوب',
  pwdsNotMatch: 'كلمة المرور غير متطابقة',
  chooseUserType: 'حدد نوع حسابك',
  fName: 'الاسم الاول',
  fNameRequired: 'الاسم الاول مطلوب',
  lName: 'الاسم الاخير',
  lNameRequired: 'الاسم الاخير مطلوب',
  advertister: 'المعلن',
  watcher: 'المشاهد',
  invalidCredentials: 'البريد الإلكتروني أو كلمة المرور خاطئة',
  forgotPwdTitle: 'حسنا لا تقلق !',
  sendEmail: 'ارسال بريد',
  doneMsg1: 'تم ارسال رسالة لبريدك!',
  doneMsg2: 'تم إرسال رمز التحقق إلى بريدك الإلكتروني',
  doneMsg3: 'من فضلك ،افحص بريدك الالكتروني...',
  NewPassMsg1: 'تم إدخال رمز التحقق بنجاح!',
  NewPassMsg2: 'من فضلك ، ضع كلمة مرورك الجديدة أدناه ...',
  backToLogin: 'العودة الي  تسجيل الدخول',
  confirmButtonText: 'فهمتك',
  logout: 'الخروج',
  thamin: 'ثمين',
  dashboard: 'لوحة تحكم الناشر',
  watchers: 'عدد المشاهدات',
  visitors: 'الزيارات',
  comments: 'التعليقات',
  likes: 'الإعجابات',
  views: 'عدد النقرات',
  wronganswers: 'إجابات',
  monthlyviews: 'المشاهدات الشهرية',
  steps: 'خطوات انشاء الاعلان',
  postad: 'إنشاء حملة',
  createcompany: 'إنشاء حساب',
  dashboard: 'لوحة التحكم',
  myads: 'إعلاناتي',
  profile: 'الملف الشخصي',
  activeads: 'إعلانات نشطة',
  pendingads: 'انتظار التفعيل',
  rejectedads: 'الإعلانات المرفوضة',
  closedads: 'إعلانات مغلقة',
  uncompleteads: 'إعلانات غير مكتملة',
  adname: 'اسم الاعلان',
  category: 'فئة',
  remainingviews: 'المشاهدات المتبقية',
  action: 'التقارير',
  adslist: 'قائمة الإعلانات',
  search: 'بحث',
  report: 'تقرير',
  goback: 'رجوع',
  questions: 'أسئلة',
  demographic: 'السكانية',
  noCommentsYet: 'لا توجد تعليقات حتي الان',
  userOpinions: 'اراء المستخدمين',
  solvedquestions: 'حل المستخدمون السؤال',
  option: 'خيار',
  visitspermonth: 'زيارات المستخدم شهريا',
  demographicbyagegender: 'الديموغرافية حسب العمر والجنس',
  male: 'ذكر',
  female: 'أنثى',
  males: 'الذكور',
  females: 'الاناث',
  usersolvedquestionandearning: 'الاسئلة المحلولة والارباح',
  solvedquestions: 'أسئلة محلولة',
  earned: 'تم الفوز بها',
  createcompany: 'إنشاء حساب',
  companyLogo: 'اختر شعار الحساب',
  upload: 'تحميل',
  companyName: 'اسم الحساب',
  incorrectnumber: 'رقم الهاتف غير صحيح',
  selectCountry: 'اختر الدولة',
  selectCity: 'اختر مدينة',
  companyAddress: 'عنوان الحساب',
  instagram: 'حساب الانستقرام',
  snapchat: 'حساب سناب شات',
  whatsapp: 'رقم الواتساب',
  create: 'انشاء',
  cancel: 'الغاء',
  companyexist: 'لقد طلبت بالفعل للشركة',
  createad: 'إنشاء حملة',
  adcategory: 'فئة الإعلان',
  costCalculation: 'حساب التكلفة',
  calcViews: 'مشاهدة',
  calcTax: 'الضريبة',
  calcGift: 'هدية',
  calcEarningUser: 'الفائزين',
  audience: 'شرائح الجمهور',
  whocanseead: 'من يمكنه رؤية إعلانك؟',
  audiencedetails: 'تفاصيل الجمهور',
  arLang: 'عربي',
  enLang: 'انجليزي',
  adTitle: 'اسم الاعلان',
  adKeywords: 'الكلمات الدلالية',
  adDescription: 'وصف الاعلان',
  edit: 'يحرر',
  selectGender: 'اختر النوع',
  age: 'العمر',
  location: 'موقع',
  countries: 'الدولة',
  nocountry: 'لم يتم تحديد أي بلد',
  state: 'المدينة',
  nostate: 'لم يتم تحديد أي مدينة',
  neighborhoodinterest: "الحي",
  selectneighborhoodinterest: "اختر الحي",
  noneighborhood: 'لم يتم تحديد أي حي',
  userinterest: 'المهنة',
  selectuserinterest: 'حدد اهتمامات المستخدم',
  nouserinterest: 'لم يتم تحديد اهتمامات المستخدم',
  adcreative: 'تصميم إعلان',
  media: 'وسائط',
  image: 'صورة',
  video: 'فيديو',
  poster: 'ملصق اعلاني',
  website: 'ما هو عنوان URL الذي يجب أن يرتبط به إعلانك؟',
  totalbudget: 'الميزانية المقترحة',
  budgettooltip:
    'الحد الأقصى الذي ستنفقه على إعلانك ، سيصل عدد أكبر من الأشخاص',
  estimated: 'مقدر',
  peoplereach: 'من الأشخاص الذين تم الوصول إليهم يوميًا',
  sar: 'ريال',
  editaudience: 'تعديل الجمهور',
  editaudiencetext:
    'حدد الموقع والعمر والجنس واهتمامات الأشخاص الذين تريد الوصول إليهم من خلال إعلانك',
  cancel: 'الغاء',
  saveaudience: 'حفظ الجمهور',
  all: 'الجميع',
  droppin: 'إسقاط دبوس',
  ok: 'نعم',
  personalInfo: 'البيانات الشخصية',
  edit: 'تعديل',
  editProfile: 'تعديل الملف الشخصي',
  profileName: ':اسم الملف الشخصي',
  gender: 'الجنس',
  mobile: 'الهاتف',
  birthdate: 'تاريخ الميلاد',
  changeProfile: 'تعديل الصورة الشخصية',
  changeCover: 'تعديل صورة الخلفية',
  saveChanges: 'حفظ التغييرات',
  companyInfo: 'بيانات الحساب',
  payment: {
    formTitle: 'عملية الدفع',
    name: 'اسم صاحب البطاقة',
    number: 'رقم البطاقة',
    cvc: 'الرقم السري',
    payBtn: 'أرسل',
  },
  reply: 'الرد',
  post: 'النشر',
  typeReply: 'اكتب ردك هنا...',
  typeComment: 'اكتب رأيك هنا...',
  publishCheck: 'عدم النشر',
  replyCheck: 'اخفاء الرد',
  selectAdCategory: 'إختار قسم الاعلان',
  chooseAdMedia: 'حمل صورة او فيديو',
  chooseAdPoster: 'أضف الصورة التعريفية للحملة',
  additionalInfo: 'معلومات اضافية',
  requiredCategory: 'حقل الفئة الاعلان مطلوب',
  requiredWebsite: 'الرجاء إدخال تنسيق URL الصحيح',
  requiredTitle: 'حقل العنوان مطلوب',
  requiredDes: 'حقل الوصف مطلوب',
  desLimit: 'حقل الوصف يجب ان يكون 20 حرف علي الاقل',
  desMaxLimit: 'لا يمكن أن يحتوي حقل الوصف على أكثر من 200 حرف',
  titleLimit: 'حقل العنوان يجب ان يكون 5 احرف علي الاقل',
  titleMaxLimit: 'لا يمكن أن يحتوي حقل العنوان على أكثر من 25 حرف',
  requiredMedia: 'حقل الصورة او الفيديو مطلوب',
  requiredPoster: 'حقل المصق الاعلاني مطلوب',
  requiredKeywords: 'حقل الكلمات الدلالية مطلوب',
  enterGiftAmount: 'حدد مقدار المكافئة او الخصم',
  giftAmountLabel: 'مقدار المكافئة',
  gift: 'هدية',
  discount: 'خصم',
  nothing: 'لا شيء',
  selectGift: 'اختر مكافئة',
  giftType: 'نوع المكافئة',
  grossTotal: 'المجموع الكلي',
  up: 'توفر لكم أكثر من',
  whatGiftType: 'كيف تريد ان تكافئ المستخدمين الاكثر تفاعلا مع اعلانك',
  one:'١',
  two:'٢',
  three:'٣',
  four:'٤',
  step1: 'الخطوة الأولى',
  step1Title:'قم بفتح حساب معلن موثق',
  step2: 'الخطوة الثانية',
  step2Title: 'اختر اعلان جديد',
  step3: 'الخطوة الثالثه',
  step3Title: 'قم بتعبئة جميع بيانات الاعلان وارفع الاعلان كفيديو او صورة مع وضع الصورة الرئيسية كواجهة للاعلان',
  step4: 'الخطوة الرابعه',
  step4Title: 'اختر الباقة المناسبة لنشاطك التجاري ثم ارفق صورة اثبات الدفع',
  yes:"نعم",
  no:"لا",
  addQuestion:"إضافة سؤال",
  typeQuestion:"أدخل سؤال الإعلان الخاص بكم",
  typeAnswers:"أدخل اجابتين على الاقل الخاصة بالإعلانكم",
  typeAnswer:"ثقف جمهورك المستهدف بجواب حول إعلانك",
  addMoreAnswer:"إضافة المزيد من الإجابة +",
  submit:"تقدم",
  twoAnswers:"يجب أن تكون الإجابات اثنتين أو أكثر",
  paymentMethods:"طرق الدفع",
  creditDebitPayment:"الدفع ببطاقة الائتمان/الخصم",
  bankPayment:"ادفع إيصال مبلغ التحويل",
  creditDebitPay:"باستخدام هذه الطريقة ، يمكنك الدفع من بطاقة الخصم أو الائتمان الخاصة بك",
  bankPay:"باستخدام هذه الطريقة ، تحتاج إلى تقديم إيصال مصرفي هنا للتحقق",
  continue:"تقدم",
  allNotifications:"جميع الاشعارات",
  viewAll:"مشاهدة الكل",
  markAll:" قرات جميع الاشعارات",
  paymentSuccess:"ختر وسيلة الدفع",
  visitAd:"زيارة الإعلان",
  charactor5to10:"يجب أن تكون الأحرف بين 5 إلى 20 حرفًا",
  charactor20to50:"يجب أن يتراوح عدد الأحرف بين 20 إلى 50 حرف",
  videoImageSize:"يتطلب الا يزيد حجم الصورة عن ( 6 ميغابايت)  و حجم الفيديو عن  ( 10 ميغابايت) ",
  supportedFormats:"والتنسيقات المدعومة (mp4-jpg-png-ipeg)",
  name :'المستخدم',
  country:'الدولة',
  neighborhood:'الحي',
  shares :  ' المشاركات',
  winners:'رابحوا الصفقة',
  winners_des:'تم إجابتها بنجاح بواسطة هؤلاء الأعضاء',
  visits : 'الزيارات من الأعضاء',
  visits_des : 'تم محاولة الإجابة عنها بواسطة هؤلاء الأعضاء',
  adPreview:"معاينة الإعلان",
  performanceOverview:"نظرة عامة على الأداء",
  rowsPerPage:"تصفح",
  download:"تحميل",
  thankyou:"شكراً لك",
  newadrequest:"لقد تم رفع طلب اعلان جديد بنجاح",
  adreview:"سوف تتم مراجعة اعلانك وفي حال وجود ملاحظات أو مخالفة لشروط الموقع سوف نرسل لك اشعار للتعديل خلال ٢٤ ساعه",
  slogan:"مع ثمين اعلانك ثمين",
  selectAll:"أختر الكل",
  renew:"إعادة نشر الاعلان",
  continueToPayment:"الدفع",
  numberOfUsers:'عدد المستخدمين الذين تريد منحهم هدايا',
  thminMysteryBox:'صندوق ثمين',
  notSet:"غير مضبوط",
  editAudienceDescription:"اختر المنطقة المستهدفة",
  noCommentYet:"لا توجد تعليقات حتى الآن",
  subscription_proof:'إثبات الدفع',
  amountToPay:"مبلغ الدفع",
  ryal:"ريال",
  paymentDetails:"بيانات الدفع",
  bankDetails:"تفاصيل البنك",
  forBankPayment:"للدفع البنكي",
  companyCreated:"مبروك, تم طلب فتح حساب معلن سوف يتم التواصل معكم  من ادارة التوثيق للتأكد من صحة البيانات خلال 24 ساعة <br> مع ثمين اعلانك ثمين",
  bankTax:"بنك الضرائب",
  adPrice:"سعر الإعلان",
  adOrder:"تم ارسال طلب اعلانك",
  adOrderRecieved:"بنجاح",
  congratulations:"!مبروك",
  adPublishedRecieved:"تم نشر",
  comment:"رأي جديد على اعلانك",
  social:"نقرات التواصل الاجتماعي",
  social_desc:"هؤلاء الأشخاص يزورون حسابات التواصل الاجتماعي",
  account:"الحساب",
  accountInfo: " معلومات مستخدم الحساب",
  analytics: "التحليلات",
  chooseAdSubPoster: "أضف معلومات الإعلان التي تعبر وتشرح عن خدماتك او منتجاتك",
  chooseVidOrImg: "أرفق فيديو أو صورة لخدماتك ومنتجاتك",
  fullName: "الاسم الكامل",
  birthday: "تاريخ الميلاد",
  gender: "الجنس",
  country: "الدولة",
  email: "البريد الإلكتروني",
  mobile: "الهاتف",
  address: "العنوان",
  nationality: "الجنسية",
  language: "اللغة",
  socialMedia: "وسائل التواصل",
  date: "التاريخ",
  passwordRest: "تم إعادة تعيين كلمة المرور بنجاح!",
  labelPassword: "الرجاء إدخال رمز التحقق الخاص بك",
  verificationCode: "رمز التحقق",
  labelNewPass: "الرجاء إدخال كلمة المرور الجديدة",
  labelReNewPass: "الرجاء إعادة إدخال كلمة المرور الجديدة",
  resetPass: "إعادة تعيين كلمة المرور",
  statee: "المدينة",
  neighborhood: "الحي",
  removeAll: "حذف الكل",
  selectcountryinterest: 'اختر الدولة',
  countryinterest: 'الدولة',
  selectstateinterest: 'اختر المدينة',
  stateinterest: 'المدينة',
  quesRequired: "مطلوب اضافة سؤال",
  ansRequired :"الأجوبة مطلوبة! يجب عليك إدخال إجابتين على الأقل",
  chooseSubAdPoster: " وهي صورة تشويقية يطلق عليها  صفحة الهبوط الخاصة بالحملة الاعلانية",
  titleLimit1: 'يجب أن يكون حقل اسم الاعلان أكثر من 5 أحرف',
  titleMaxLimit1: 'يجب أن يكون حقل اسم الاعلان أقل من 20 حرفًا',
  titleLimit2: 'يجب أن يكون حقل وصف الاعلان أكثر من 20 حرفًا',
  titleMaxLimit2: 'لا يمكن أن يحتوي حقل وصف الاعلان على أكثر من 50 حرفًا',
  resPass: "تغيير كلمة المرور",
  addQuesExp: " اختر الجواب الصحيح بالنقر على",
  fileSize: "حجم الملف",
  Mb: "ميغابايت",
  selectVideoMethod: "حدد طريقة رفع الفيديو",
  or: "أو",
  uploadVideo: "اضغط للتحميل",
  pasteUrl:"الصق عنوان url للفيديو",
  allowedPaste:"يمكنك فقط لصق عنوان url",
  invalidUrl:"رابط غير صالح الاستعمال",
  youtubeNotAllowed:"رابط يوتيوب غير مسموح به.",
  countryLimit:"لا يمكنك اختيار أكثر من دولة واحدة",
  stateLimit:"لا يمكنك اختيار أكثر من مدينتين", 
}
