import React from 'react'

export default function Analytics() {
    return (
        <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            <h1>Coming Soon</h1>
        </div>
    )
}

