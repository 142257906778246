import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  ListItemIcon,
  Typography,
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { TextareaAutosize } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AdsList from "../../components/ads/AdsList";
import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { useHistory, withRouter } from "react-router-dom";

import {
  addCommentApi,
  commentsApi,
  publisherAdApi,
  socialVisits,
  saveAudienceApi,
} from "../../api";
import DemographicChart from "../../components/ads/DemographicChart";
import VisitsChart from "../../components/ads/VisitsChart";
import CommentBox from "../../components/CommentBox";
import { AuthContext } from "../../contexts/authContext";
import strings from "../../lang/lang";
import { BUCKET_URL, checkGender,getImage } from "../../utils/helpers";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AudienceContext } from "../../contexts/audience";
import { getAgeRangeByCategory } from "../../utils/selectAgeCategory";
import GoBack from "../../components/GoBack";
import ar from "moment/locale/ar-sa";
import snapchat from "../../assets/img/img/snapchat.png";
import whatsapp from "../../assets/img/img/whatsapp.png";
import facebook from "../../assets/img/img/facebook.png";
import twitter from "../../assets/img/img/twitter.png";
import tiktok from "../../assets/img/img/tiktok.png";
import youtube from "../../assets/img/img/youtube.png";
import instagram from "../../assets/img/img/instagram.png";
import linkedin from "../../assets/img/img/linkedin.png";
import website from "../../assets/img/img/website.png";
import coupon from "../../assets/img/img/coupon.png";

const Alert = withReactContent(Swal);

function MyAd({ match }) {
  const storedToken = localStorage.getItem("authToken");
  const { authState } = useContext(AuthContext);
  const props = useContext(AudienceContext);
  const classes = useStyles();
  const history = useHistory();
  const lang = localStorage.getItem("lang");
  moment.locale(lang);
  let url = history.location.pathname.split("/");
  let id = url.pop();
  const [adData, setAdData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [answers, setAnswers] = useState([]);
  const [value, setValue] = useState(0);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [socialclicks, setSocialClicks] = useState("");

  const checkObject = (item) => {
    if (item) {
      let value = JSON.parse(item);
      if (
        typeof value == "object" &&
        value?.[lang] === "المملكة العربية السعودية"
      )
        return "السعودية";
      else if (typeof value == "object") return value?.[lang];
      return value;
    }
    return "-";
  };

  // const checkGender = (item) => {
  //   if (item === "") return "-";
  //   else {
  //     return item == 0 ? strings.male : strings.female;
  //   }
  // };

  const checkSocialType = (item) => {
    if (item === "tiktok") {
      return <img style={{ width: 40 }} src={tiktok} />;
    }
    if (item === "youtube") {
      return <img style={{ width: 40 }} src={youtube} />;
    }
    if (item === "linkedin") {
      return <img style={{ width: 40 }} src={linkedin} />;
    }
    if (item === "globe") {
      return <img style={{ width: 35 }} src={website} />;
    }
    if (item === "coupon") {
      return <img style={{ width: 70 }} src={coupon} />;
    }
    if (item === "instagram") {
      return <img style={{ width: 40 }} src={instagram} />;
    }
    if (item === "snapchat") {
      return <img style={{ width: 40 }} src={snapchat} />;
    }
    if (item === "whatsapp") {
      return <img style={{ width: 40 }} src={whatsapp} />;
    }
    if (item === "facebook") {
      return <img style={{ width: 40 }} src={facebook} />;
    }
    if (item === "twitter") {
      return <img style={{ width: 40 }} src={twitter} />;
    }
  };
  const visitsColumns = [
    {      
      center: true,
      name:strings.name, 
      cell: row =><div className={classes.nameImage}>
      <img className={classes.image} src={getImage(row?.user?.image)}/>
      <p>{row?.user?.name}</p>
      </div>,
    },
    // { name: strings.name, cell: (row) => row?.user?.name, center: true },
    {
      name: strings.country,
      cell: (row) => checkObject(row?.user?.country?.name),
      center: true,
    },
    {
      name: strings.state,
      cell: (row) => checkObject(row?.user?.state?.name),
      center: true,
    },
    {
      name: strings.neighborhood,
      cell: (row) => checkObject(row?.user?.neighborhoods?.name),
      center: true,
    },
    {
      name: strings.gender,
      cell: (row) => (row?.user?.gender == 0 ? strings.male : strings.female),
      center: true,
    },
    {
      name: strings.age,
      cell: (row) => moment().diff(row?.user?.birthdate, "years"),
      center: true,
    },
    {
      name: strings.userinterest,
      cell: (row) => checkObject(row?.user?.users_jobs?.title),
      center: true,
    },
  ];

  const winners = [
    {      
      center: true,
      name:strings.name, 
      cell: row =><div className={classes.nameImage}>
      <img className={classes.image} src={getImage(row?.user?.image)}/>
      <p>{row?.user?.name}</p>
      </div>,
    },
    // { name: strings.name, cell: (row) => row?.user?.name, center: true },
    {
      name: strings.country,
      cell: (row) => checkObject(row?.user?.country?.name),
      center: true,
    },
    {
      name: strings.state,
      cell: (row) => checkObject(row?.user?.state?.name),
      center: true,
    },
    {
      name: strings.neighborhood,
      cell: (row) => checkObject(row?.user?.neighborhoods?.name),
      center: true,
    },
    {
      name: strings.gender,
      cell: (row) => (row?.user?.gender == 0 ? strings.male : strings.female),
      center: true,
    },
    {
      name: strings.age,
      cell: (row) => moment().diff(row?.user?.birthdate, "years"),
      center: true,
    },
    {
      name: strings.userinterest,
      cell: (row) => checkObject(row?.user?.users_jobs?.title),
      center: true,
    },
    {
      name: strings.shares,
      cell: (row) => JSON.parse(row?.share_links)?.length,
      center: true,
    },
  ];

  const socials = [
    {      
      center: true,
      name:strings.name, 
      cell: row =><div className={classes.nameImage}>
      <img className={classes.image} src={getImage(row?.user?.image)}/>
      <p>{row?.username}</p>
      </div>,
    },
    // { name: strings.name, cell: (row) => row?.username, center: true },
    {
      name: strings.country,
      cell: (row) => checkObject(row?.country?.name),
      center: true,
    },
    {
      name: strings.state,
      cell: (row) => checkObject(row?.state?.name),
      center: true,
    },
    {
      name: strings.neighborhood,
      cell: (row) => checkObject(row?.city?.name),
      center: true,
    },
    {
      name: strings.gender,
      cell: (row) => checkGender(row?.gender),
      center: true,
    },
    {
      name: strings.age,
      cell: (row) => row?.age,
      center: true,
    },
    {
      name: strings.userinterest,
      cell: (row) => checkObject(row?.user_job?.title),
      center: true,
    },
    {
      name: strings.views,
      cell: (row) => row?.views,
      center: true,
    },
    {
      name: strings.socialMedia,
      cell: (row) => checkSocialType(row?.social_type),
      center: true,
    },
    {
      name: strings.date,
      cell: (row) => row?.date,
      center: true,
    },
  ];
  localStorage.setItem("product_id", adData?.product?.id);
  const handleEditDemographic = () => {
    // localStorage.setItem("selectedCountries",adData?.product?.demographicData
    // props.setAge(getAgeRangeByCategory(adData?.product?.demographicData?.age));
    // props.setSelectedCountries(adData?.product?.demographicData?.country);
    // props.setSelectedStates(adData?.product?.demographicData?.state);
    // props.setLocations([
    //   {
    //     state: adData?.product?.demographicData?.state,
    //     country: adData?.product?.demographicData?.country,
    //     distance: "",
    //   },
    // ]);
    // props.setSelectedJobs(adData?.product?.demographicData?.users_jobs);
    // props.setGender(adData?.product?.demographicData?.gender);
    history.push(`/publisher/audience/${id}`);
  };

  useEffect(() => {
    if (!storedToken) window.location.pathname = "/login";
  }, [storedToken]);

  useEffect(() => {
    publisherAdApi(storedToken, id).then((result) => {
      let data = result.data.data;
      if (data?.status) {
        if (!data?.viewStatus)
          Alert.fire("You are not authrozied to see this ad");
        else setAdData(data);
        setIsLoading(false);
      }
    });

    socialVisits(storedToken, id).then((result) => {
      let data = result.data;
      if (data?.status) {
        setSocialClicks(data);
        setIsLoading(false);
      }
    });

    commentsApi(match.params.id, lang).then((res) => {
      if (res?.data?.status) {
        setComments(res?.data?.data);
      }
    });
  }, []);

  const download = (type) => {
    publisherAdApi(`${storedToken}&export=${type}`, id).then((result) => {
      let data = result.data.data;
      window.open(data.url, "_blank");
    });
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAddComment = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("message", newComment);
    formData.append("product_id", match.params.id);

    addCommentApi(storedToken, formData).then((res) => {
      if (res.data.status) {
        setComments([...comments, res.data.data]);
        setNewComment("");
      }
    });
  };

  return (
    <div
      className={classes.container}
      style={{
        direction: lang == "en" ? "ltr" : "rtl",
        textAlign: lang == "en" ? "left" : "right",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <GoBack />
        </Grid>
        {!adData ? (
          <CircularProgress />
        ) : (
          <>
            <Grid item xs={12} md={12}>
              <Card raised>
                <CardHeader
                  className={
                    adData?.product?.views === 0 ? classes.mars : classes.mar
                  }
                  title={strings.performanceOverview}
                  subheader={
                    adData?.product?.views === 0 && (
                      <Button
                        className={
                          adData?.product?.views === 0
                            ? classes.absButtonx
                            : classes.absButton
                        }
                        variant="contained"
                        onClick={(__) =>
                          history.push(
                            `/publisher/renew/${adData?.product?.id}`
                          )
                        }
                        color="primary"
                      >
                        {strings.renew}
                      </Button>
                    )
                  }
                  action={
                    <Button
                      variant="contained"
                      color="primary"
                      className={
                        adData?.product?.views === 0
                          ? classes.absButtonxs
                          : classes.absButtons
                      }
                      onClick={handleEditDemographic}
                    >
                      {strings.edit}
                    </Button>
                  }
                />
                <Divider />
                <CardContent
                  className={lang == "ar" ? "text-right" : "text-left"}
                >
                  <Grid container spacing={4}>
                    <Grid
                      item
                      md={6}
                      xs={12}
                      style={{ borderLeft: "2px dashed lightgray" }}
                    >
                      <DemographicChart data={id} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <VisitsChart data={id} />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={12}>
              <Card raised>
                <CardHeader
                  className={classes.mar}
                  title={`${strings.winners} ${"("} ${
                    adData?.product?.solutions?.length
                  } ${")"}`}
                  subheader={strings.winners_des}
                  action={
                    adData?.product?.solutions?.length > 0 && (
                      <Button
                        onClick={() => download("winners")}
                        color="primary"
                        variant="contained"
                        className={classes.downButtons}
                      >
                        {strings.download}
                      </Button>
                    )
                  }
                />
                <Divider />
                <CardContent
                  className={lang == "ar" ? "text-right" : "text-left"}
                >
                  <Grid container spacing={4}>
                    <Grid item md={12} xs={12}>
                      {adData ? (
                        <AdsList
                          title={false}
                          columns={winners}
                          data={adData?.product?.solutions}
                        />
                      ) : (
                        <CircularProgress />
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={12}>
              <Card raised>
                <CardHeader
                  className={classes.mar}
                  title={`${strings.visits} ${"("} ${
                    adData?.product?.visits?.length
                  } ${")"}`}
                  subheader={strings.visits_des}
                  action={
                    adData?.product?.visits?.length > 0 && (
                      <Button
                        onClick={() => download("visitors")}
                        color="primary"
                        variant="contained"
                        className={classes.downButtons}
                      >
                        {strings.download}
                      </Button>
                    )
                  }
                />
                <Divider />
                <CardContent
                  className={lang == "ar" ? "text-right" : "text-left"}
                >
                  <Grid container spacing={4}>
                    <Grid item md={12} xs={12}>
                      {adData ? (
                        <AdsList
                          title={false}
                          columns={visitsColumns}
                          data={adData?.product?.visits}
                        />
                      ) : (
                        <CircularProgress />
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            {/* Social Visits */}
            <Grid item xs={12} md={12}>
              <Card raised>
                <CardHeader
                  className={classes.mar}
                  title={`${strings.social} ${"("} ${
                    socialclicks?.data?.length
                  } ${")"}`}
                  subheader={strings.social_desc}
                  action={
                    socialclicks?.data?.length > 0 && (
                      <Button
                        onClick={() => download("socials")}
                        variant="contained"
                        color="primary"
                        className={classes.downButtons}
                      >
                        {strings.download}
                      </Button>
                    )
                  }
                />
                <Divider />
                <CardContent
                  className={lang == "ar" ? "text-right" : "text-left"}
                >
                  <Grid container spacing={4}>
                    <Grid item md={12} xs={12}>
                      {socialclicks ? (
                        <AdsList
                          title={false}
                          columns={socials}
                          data={socialclicks?.data}
                        />
                      ) : (
                        <CircularProgress />
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={12}>
              <Card raised>
                <CardHeader title={strings.adPreview} />
                <Divider />
                <CardContent>
                  <Grid container spacing={4}>
                    <Grid xs={0} md={2} />
                    <Grid xs={12} md={8}>
                      <Grid
                        item
                        md={12}
                        xs={12}
                        style={{
                          borderLeft: "2px dashed lightgray",
                          height: "300px",
                        }}
                      >
                        {adData ? (
                          <div>
                            <CardContent
                              className={
                                lang == "ar" ? "text-right" : "text-left"
                              }
                            >
                              <Typography
                                variant="h6"
                                color="textSecondary"
                                component="h2"
                              >
                                {adData?.product?.name}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                              >
                                {adData?.product?.des}
                              </Typography>
                            </CardContent>
                            {adData?.product.mediatype == 0 ? (
                              <CardMedia
                                className={
                                  lang == "ar"
                                    ? classes.adPreviewAr
                                    : classes.adPreviewEn
                                }
                                style={{ height: 340 }}
                                image={BUCKET_URL + adData?.product?.image}
                                title="Paella dish"
                              />
                            ) : (
                              <video
                                controls
                                style={{ width: "100%", height: "200px" }}
                              >
                                <source
                                  src={
                                    adData?.product?.image.includes("http")
                                      ? adData?.product?.image
                                      : BUCKET_URL + adData?.product?.image
                                  }
                                />
                              </video>
                            )}
                          </div>
                        ) : (
                          <CircularProgress />
                        )}
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <CardHeader
                          style={{ direction: "rtl" }}
                          title={strings.userOpinions}
                        />
                        {comments.length > 0 ? (
                          comments.map((comment, index) => (
                            <CommentBox key={index} comment={comment} />
                          ))
                        ) : (
                          <div className="w-full flex justify-center items-center font-semibold text-2xl text-gray-400">
                            {strings.noCommentYet}
                          </div>
                        )}

                        {/* <form
                          dir={lang === "ar" ? "rtl" : "ltr"}
                          onSubmit={handleAddComment}
                          className="w-full flex flex-col"
                        >
                          <TextareaAutosize
                            maxRows={5}
                            placeholder={strings.typeComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            value={newComment}
                            style={{ width: "100%", outline: "none" }}
                            className="px-4 py-3 text-sm border-gray-400 border-2 rounded-md bg-gray-100 focus:border-green-400"
                          />
                          <button
                            type="submit"
                            className="self-end mt-3 px-4 py-1.5 rounded focus:outline-none bg-green-500 text-white font-semibold tracking-wide border-2 border-green-400 capitalize"
                          >
                            {strings.post}
                          </button>
                        </form> */}
                      </Grid>
                    </Grid>
                    <Grid xs={0} md={2} />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Card raised style={{ background: "#f9f9f9", border: "none" }}>
                <CardHeader
                  title={strings.questions}
                  // action={
                  //   <Button
                  //     variant="contained"
                  //     color="primary"
                  //     onClick={()=>history.push(`/publisher/ad/${match.params.id}/questions/add`)}>
                  //     Edit
                  //   </Button>
                  // }
                />
                <CardContent>
                  <Grid item md={12} xs={12}>
                    {adData ? (
                      <div>
                        {adData?.product?.questions.length > 0 && (
                          <>
                            <CardContent>
                              <List dense={true} subheader={<li />}>
                                <ListSubheader style={{ fontSize: 20 }}>
                                  {adData?.product.questions[0].name}
                                </ListSubheader>
                                {JSON.parse(
                                  adData?.product.questions[0].answers
                                ).map((item, index) => (
                                  <>
                                    <ListItem key={index}>
                                      <ListItemIcon>
                                        {index ==
                                        adData?.product.questions[0]
                                          ?.true_answer ? (
                                          <CheckCircleOutlineIcon />
                                        ) : (
                                          <FiberManualRecordIcon />
                                        )}
                                      </ListItemIcon>
                                      <ListItemText primary={item} />
                                    </ListItem>
                                  </>
                                ))}
                              </List>
                            </CardContent>
                            <Divider />
                          </>
                        )}
                      </div>
                    ) : (
                      <CircularProgress />
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    image:{
      borderRadius:"50%",
      padding:"10px",
      width:"60px",
      height:"60px"
    },
    nameImage:{
      display:"flex",
      flexDirection:"column",
      alignItems:"center"
    },
    container: {
      width: "90%",
      margin: "10px auto",
    },
    avatar: {
      width: "12rem",
      height: "12rem",
    },
    media: {
      height: 0,
      backgroundSize: "contain",
      paddingTop: "56.25%", // 16:9
    },
    absButton: {
      position: "absolute",
      top: "16px",
      right: "105px",
      borderRadius: 12,
      "&:hover": {
        border: "2px solid aquamarine",
      },
    },
    absButtonx: {
      position: "absolute",
      right: "105px",
      top: "16px",
      borderRadius: 12,
      "&:hover": {
        border: "2px solid aquamarine",
      },
      [theme.breakpoints.down("md")]: {
        top: 60,
        left: 80,
      },
    },
    absButtons: {
      position: "absolute",
      top: "16px",
      right: "25px",
      borderRadius: 12,
      "&:hover": {
        border: "2px solid aquamarine",
      },
      [theme.breakpoints.down("md")]: {
        position: "unset",
      },
    },
    absButtonxs: {
      position: "absolute",
      top: "16px",
      right: "25px",
      borderRadius: 12,
      "&:hover": {
        border: "2px solid aquamarine",
      },
      [theme.breakpoints.down("md")]: {
        top: 60,
        right: 25,
      },
    },
    downButtons: {
      borderRadius: 12,
      "&:hover": {
        border: "2px solid aquamarine",
      },
    },
    root: {
      maxWidth: 345,
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {
      backgroundColor: red,
    },
    video: {
      width: "100%",
    },
    mar: {
      "& .MuiCardHeader-action": {
        margin: "auto",
        paddingLeft: 10,
      },
      "& .MuiTypography-body1": {
        fontSize: 18,
      },
    },

    mars: {
      [theme.breakpoints.down("md")]: {
        padding: "16px 16px 55px 16px",
      },
    },
    adPreviewAr: {
      height: 340,
      textAlign: "right",
      [theme.breakpoints.down("md")]: {
        height: 195,
      },
    },
    adPreviewEn: {
      height: 340,
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        height: 195,
      },
    },
  };
});

export default withRouter(MyAd);
