import React, { useState } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import styles from "../../jsxStyles/navbar.jsx"
import PostAddIcon from "@material-ui/icons/PostAdd"
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone"
import FeaturedVideoOutlinedIcon from "@material-ui/icons/FeaturedVideoOutlined"
import DashboardIcon from "@material-ui/icons/Dashboard"
import BusinessIcon from "@material-ui/icons/Business"
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	NavbarText,
} from "reactstrap"
import { useSelector } from "react-redux"
import { Button, Divider, Badge } from "@material-ui/core"
import strings from "../../lang/lang";

function BottomNavbar(props) {
	const classes = styles()
	const [isOpen, setIsOpen] = useState(false)
	const user = useSelector((state) => state.user.user)
	const toggle = () => setIsOpen(!isOpen)

	return (
		<div>
			<div color="white" expand="md" className={classes.navBarTop}>
				<NavbarBrand href="/" className={classes.navbarBrand}>
					<img src="https://thaminmainweb.fra1.digitaloceanspaces.com/images/logo_light_en_beta.png" />
				</NavbarBrand>
				<div className="innerContent">
					<Button
						color="primary"
						variant="contained"
						size="large"
						endIcon={<PostAddIcon />}
					>
						{strings.postad}
					</Button>
					<Divider orientation="vertical" />
					<Button
						color="primary"
						onClick={props.toggleCompanyModal}
						variant="contained"
						size="large"
						endIcon={<BusinessIcon />}
					>
						{strings.createcompany}
					</Button>
					<Divider orientation="vertical" />
					<Badge badgeContent={4} color="primary">
						<NotificationsNoneIcon fontSize="large" numOctaves="1" />
					</Badge>
					<Divider orientation="vertical" />
					<NavbarText color="primary">{user.name}</NavbarText>
				</div>
			</div>
			<Navbar color="green" primary expand="md" className={classes.navBar}>
				<NavbarToggler onClick={toggle} />
				<Collapse isOpen={isOpen} navbar>
					<Button
						color="secondary"
						size="large"
						startIcon={<DashboardIcon htmlColor="#212121" />}
					>
						{strings.dashboard}
					</Button>
					<Divider orientation="vertical" />
					<Button
						color="secondary"
						size="large"
						startIcon={<FeaturedVideoOutlinedIcon htmlColor="#d66952" />}
					>
						{strings.myads}
					</Button>
					<Divider orientation="vertical" />
				</Collapse>
			</Navbar>
		</div>
	)
}

export default BottomNavbar;
