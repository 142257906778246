import React from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

function ProfileInfoHead({ title, setShowItem, showItem }) {
  return (
    <header className="flex justify-between items-center px-3 lg:px-10 py-3 shadow-md rounded-lg text-gray-500">
      <span className="text-xl lg:text-3xl font-semibold">{title}</span>
      {showItem ? (
        <IoIosArrowUp
          className="text-2xl lg:text-3xl cursor-pointer font-bold"
          onClick={() => setShowItem(false)}
        />
      ) : (
        <IoIosArrowDown
          className="text-2xl lg:text-3xl cursor-pointer font-bold"
          onClick={() => setShowItem(true)}
        />
      )}
    </header>
  );
}

export default ProfileInfoHead;
