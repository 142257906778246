import React from 'react'
import { Link } from 'react-router-dom'

function AuthButton({ btnText, linkText, linkPath, lang, isDisabled }) {
  return (
    <div className="w-full py-6 flex flex-col">
      <div className="w-full order-2 my-3 sm:my-0 px-4 ">
        <Link
          to={linkPath}
          className="text-blue-500 tracking-wide text-sm md:text-base cursor-pointer"
          style={{ float: lang === 'en' ? 'left' : 'right' }}
        >
          {linkText}
        </Link>
      </div>
      <button
        disabled={isDisabled}
        type="submit"
        className={`w-full px-2 xs:px-6 py-2 text-sm sm:text-sm tracking-wide bg-green-400 ${
          isDisabled ? 'bg-opacity-100' : 'hover:bg-green-600'
        } text-white font-medium focus:outline-none rounded-lg transition duration-200 ease-out`}
      >
        {btnText}
      </button>
    </div>
  )
}

export default AuthButton
