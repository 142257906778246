import { Button } from "@material-ui/core";
import { useHistory } from "react-router";
import ArrowBack from "@material-ui/icons/ArrowBack";
import strings from '../lang/lang'
const GoBack = () => {
    const history  = useHistory();
    return <Button
    size="large"
    style={{
        // marginLeft:"0.5rem",
        marginTop:"1rem",
        marginLeft:"0.5rem",
        padding:"0.5rem",
        borderRadius: 12,
        backgroundColor:"darkgrey",
        "&:hover": {
            backgroundColor:"#000",
            color:"lightgrey",
            border: "2px solid aquamarine",
        }
    }}
    onClick={()=>history.goBack()}
   >
    {strings.goback}
    </Button>
}

export default GoBack;