import React, { useState, useRef } from "react";
import {
  Button,
  Card,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Modal,
  CardHeader,
  CircularProgress,
} from "@material-ui/core";
import { CardBody } from "reactstrap";

import CloseIcon from "@mui/icons-material/Close";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import useStyles from "../../jsxStyles/publisher";
import strings from "../../lang/lang";
import { Field } from "formik";
import { esES } from "@mui/x-date-pickers";

function MediaVideoModal({
  triggerMediaPicker,
  open,
  handleMediaModal,
  handleUrlChange,
  urlRef,
  setFieldValue,
  setVideoPreview,
}) {
  const lang = localStorage.getItem("lang");
  const [urlError, setUrlError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const styles = useStyles();
  const validUrl =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

  const validateUrl = (url) => {
    if (url.match(validUrl)) {
      if (url.includes("youtube")) {
        setUrlError(strings.youtubeNotAllowed);
        setIsLoading(false);
        return false;
      }
      setIsLoading(false);
      return true;
    }
    setUrlError(strings.invalidUrl);
    setIsLoading(false);
    return false;
  };

  return (
    <Modal open={open}>
      <Card className={styles.videoModal}>
        <Grid container style={{ direction: lang === "en" ? "ltr" : "rtl" }}>
          <Grid item xs={9} style={{ textAlign: "start", margin: "auto" }}>
            <CardHeader
              title={strings.selectVideoMethod}
              style={{ fontSize: 20 }}
            />
          </Grid>
          <Grid item xs={3} style={{ textAlign: "end", margin: "auto" }}>
            <Button
              size="small"
              color="primary"
              onClick={() => handleMediaModal(false)}
              variant="contained"
            >
              <CloseIcon />
            </Button>
          </Grid>
        </Grid>
        <CardBody>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              className="container"
              onClick={() => triggerMediaPicker(1)}
            >
              <div>
                <Typography className="content">
                  {strings.uploadVideo}
                  <FileUploadOutlinedIcon style={{ marginLeft: "10px" }} />
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={strings.pasteUrl}
                error={urlError}
                fullWidth
                inputRef={urlRef}
                onKeyPress={(e) => {
                  e.target.value = "";
                  setUrlError(strings.allowedPaste);
                }}
                onPaste={(e) => {
                  setUrlError(null);
                  setVideoPreview(null);
                  setIsLoading(true);
                  setTimeout(() => {
                    if (validateUrl(e.target.value)) {
                      handleUrlChange(e, setFieldValue);
                    }
                  }, 1000);
                }}
              />
              {isLoading && <CircularProgress />}
              {urlError && (
                <FormHelperText style={{ color: "red" }}>
                  {urlError}
                </FormHelperText>
              )}
              <div
                className="w-full flex justify-center"
                style={{ paddingBottom: 8 }}
              ></div>
              {/* <Grid container style={{ padding: "8px 0px" }}>
                <Grid item xs={4} />
                <Grid item xs={4} style={{ textAlign: "center" }}>
                  <Button
                    color="primary"
                    onClick={() => handleMediaModal(false)}
                    variant="contained"
                  >
                    {strings.ok}
                  </Button>
                </Grid>
                <Grid item xs={4} />
              </Grid> */}
            </Grid>
          </Grid>
        </CardBody>
      </Card>
    </Modal>
  );
}

export default MediaVideoModal;
