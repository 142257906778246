import React, { useState, useEffect } from "react";
import { Input, LinearProgress, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DataTable from "react-data-table-component";
import strings from "../../lang/lang";
import GoBack from '../GoBack';
import { publisherAdsApi } from '../../api'
import editedStyles from '../../modules/dashboard.module.css'
import { useHistory, useParams } from "react-router";
import { BiFontSize } from "react-icons/bi";


const customStyles = {
  table: {
    margin: "1rem",
  },
  rows: {
    style: {
      minHeight: "3rem", // override the row height
      fontSize: 15,
      textAlign: "center",
    },
  },
  headCells: {
    style: {
      paddingLeft: "0.5rem", // override the cell padding for head cells
      paddingRight: "0.5rem",
      fontWeight: "bold",
      fontSize: 17,
      textAlign: "center",
    },
  },
  cells: {
    style: {
      paddingLeft: "0.5rem", // override the cell padding for data cells
      paddingRight: "0.5rem",
      fontSize: 15,
      textAlign: "center",
    },
  },
};


function AdsList(props) {
  const [fetchedData, setfetchedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState(null)
  const storedToken = localStorage.getItem('token')
  const lang = localStorage.getItem('lang')
  const history = useHistory()
  const params = useParams()
  const columns = [
    {
      center: true,
      name: "#",
      selector: "id",
    },
    {
      center: true,
      name: strings.adname,
      selector: "name",
    },
    {
      center: true,
      name: strings.category,
      cell: (row) =>
        <span style={{ width: "7.5rem" }}>
          {JSON.parse(row?.cat?.name)?.[lang]}
        </span>,
    },
    {
      center: true,
      name: strings.remainingviews,
      cell: (row) => (
        <span style={{ width: "7.5rem" }}>
          {row.views < 0 ? row.views = 0 : row.views}
          <LinearProgress
            color="primary"
            style={{
              height: 8,
              backgroundColor:
                row.views <= 15 ? "red" : row?.views <= 50 ? "yellow" : "",
            }}
            variant="determinate"
            value={row?.views}
          />
        </span>
      ),
    },
    {
      center: true,
      name: strings.action,
      cell: (row) => (
        <span className={editedStyles.incOnSM}>
          <Button
            color="primary"
            variant="contained"
            background="primary"
            onClick={() => history.push(`/publisher/myad/${row?.id}`)}
          >
            {strings.report}
          </Button>
        </span>
      ),
    },
  ]

  useEffect(__ => {
    const fetchData = async () => {
      const data = await publisherAdsApi(storedToken);
      switch (params.type) {
        case 'active':
          setfetchedData(data?.data?.data.products)
          break
        case 'rejected':
          setfetchedData(data?.data?.data.rejectedproducts)
          break
        case 'closed':
          setfetchedData(data?.data?.data.closedproducts)
          break
        case 'uncompleted':
          setfetchedData(data?.data?.data.uncompletedproducts)
          break
        case 'pending':
          setfetchedData(data?.data?.data.pendingproducts)
          break
        default:
          setfetchedData(props?.data)
          break;
      }
    };
    fetchData();

  }, [searchValue]);

  // const setFetchedData = () => {
  //   switch (params.type) {
  //     case 'active':
  //       console.log("active;")
  //       setFetchedData(activeAds)
  //       break
  //     case 'rejected':
  //       console.log("rejected;")
  //       setFetchedData(rejectedAds)
  //       break
  //     case 'closed':
  //       console.log("closed")
  //       setFetchedData(closedAds)
  //       break
  //     case 'uncompleted':
  //       console.log("uncompleted")
  //       setFetchedData(uncompletedAds)
  //       break
  //     case 'pending':
  //       console.log("pending")
  //       setFetchedData(pendingAds)
  //       break
  //     default:
  //       console.log(";")
  //       setFetchedData(props?.data)
  //       break;
  //   }
  // }

  const onChangeValue = (e) => {
    if (e.target.value == "") {
      setSearchValue("value")
      setFilteredData([]);
    }
    else {
      let filtered = fetchedData.filter((item) => {
        let search = item?.name ?? item?.user?.name
        let cat = item?.user?.users_jobs?.title ?? item?.user_job?.title
        let country = item?.user?.country?.name ?? item?.country?.name
        let state = item?.user?.state?.name ?? item?.state?.name
        let city = item?.user?.neighborhoods?.name ?? item?.city?.name
        if (search?.toLowerCase().includes(e.target.value)) return true;
        
        if (cat?.toLowerCase().includes(e.target.value)) return true

        if (country?.toLowerCase().includes(e.target.value)) return true

        if (state?.toLowerCase().includes(e.target.value)) return true

        if (city?.toLowerCase().includes(e.target.value)) return true
      });
      setFilteredData(filtered);
    }
  };
  return (
    <>
      <DataTable
        title={!props.title ? "" : strings.adslist}
        columns={props.columns ? props.columns : columns}
        data={filteredData?.length > 0 ? filteredData : fetchedData}
        count={filteredData?.length > 0 ? filteredData.length : fetchedData?.length}
        pagination={true}
        pointerOnHover={true}
        fixedHeader={false}
        customStyles={customStyles}
        paginationComponentOptions={{
          rowsPerPageText: strings.rowsPerPage,
        }}
        subHeader
        subHeaderComponent={
          <Input
            onChange={onChangeValue}
            style={{ width: 250, fontSize: 20 }}
            placeholder={strings.search}
          />
        }
      />
    </>
  );
}

const styles = makeStyles((theme) => {
  return {
    progressBar: {
      height: "6px",
    },
  };
});

export default AdsList;
