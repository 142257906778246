import { CircularProgress, Grid, TextField, Button } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";

import strings from "../../lang/lang";
import { getCountries } from "../../routes/auth";
import { getStateByCountryId } from "../../api";
import { useDispatch } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import useStyles from "../../jsxStyles/publisher";

function CountriesSelect(props) {
  const lang = localStorage.getItem("lang");
  const [countries, setCountries] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [ids, setIds] = useState([]);
  const dispatch = useDispatch();
  const Alert = withReactContent(Swal);
  const styles = useStyles();

  // const selectAllInterest = (__) => {
  //   props.setSelectedCountries(countries);
  // };

  useEffect(() => {
    setLoading(true);
    getCountries(lang).then((result) => {
      setCountries(result.data);
      setLoading(false);
    });
  }, []);

  return (
    <div className="my-6">
      <label className={`w-full mb-3 text-lg sm:text-xl font-semibold`}>
        {strings.countryinterest}
      </label>
      <label className={`w-full mb-3 text-sm sm:text-sm font-semibold`}>
        *{strings.countryLimit}
      </label>
      <Grid container spacing={3} style={{ direction: "auto" }}>
        <Grid item xs={8}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Autocomplete
              multiple
              id="combo-box-demo"
              className={styles.countrySelect}
              getOptionDisabled={option => props.selectedCountries.length === 1 || props.selectedCountries.includes(option)}
              options={countries}
              getOptionLabel={(option) => option.name}
              value={props.selectedCountries ?? ""}
              onChange={(event, value) => {
                // if (value.length < 2){
                props.setSelectedCountries(value);
                let ids = [];
                value.map((item) => {
                  ids.push(item?.id);
                });
                dispatch({
                  type: "CountrySelect",
                  payload: ids,
                });
                // } else {Alert.fire(strings.countryLimit)}
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  style={{ margin: "10px 0px" }}
                  variant="outlined"
                  placeholder={strings.selectcountryinterest}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={4} style={{ display: "flex", padding: "1.4rem" }}>
          {/* <Button
            fullwidth
            variant="contained"
            onClick={selectAllInterest}
            color="primary"
          >
            {strings.selectAll}
          </Button> */}
        </Grid>
      </Grid>
    </div>
  );
}

export default CountriesSelect;
