import { Box, Button, Typography, CardHeader } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import styles from "../modules/sidebar.module.css";
import strings from '../lang/lang'
import { NotificationsNoneOutlined } from "@material-ui/icons";
import { getNotificationMessage } from "../utils/helpers";
import { readAllNotification, removeAllNotification, notificationApi } from "../api";
import { useDispatch } from "react-redux";
import { setCount, storeNotifications, setUnreadMsgs } from "../store/actions/userActions";

function NotificationsBox({ isVisible, notifications,setVisibleNotifications }) {
  const lang = localStorage.getItem("lang");
  const token = localStorage.getItem('token')
  const dispatch = useDispatch();

  const handleMarkAllRead = __ => {
    readAllNotification(token)
    .then(res => {
      dispatch(storeNotifications([]))
      dispatch(setCount(0))
      dispatch(setUnreadMsgs(0))
    }) 
   }

   const handleRemoveAllNotification = __ => {
    removeAllNotification(token)
    .then(res => {
      dispatch(storeNotifications([]))
      dispatch(setCount(0))
      dispatch(setUnreadMsgs(0))
    }) 
   }
  return (
    <Box
      dir={lang == "en" ? "ltr" : "rtl"}
      style={{ textAlign: `${lang == "en" ? "left" : "right"}` }}
      className={`w-86 mt-2 lg:-mt-2 sm:mx-4 lg:mx-8 py-2 ${lang === "ar" ? "left-0" : "right-0"
        } ${styles.notificationBox} ${isVisible && styles.activeBox}`}
    >
      <Box px={2.5} pb={1.3}>
        <Typography
          variant="h5"
          color="textSecondary"
          gutterBottom
          style={{ fontWeight: 550 }}
        >
          {strings.allNotifications}
        </Typography>
      </Box>
      <Box className={styles.scrollNotifications}>
        {notifications.map((notification, index) => {
          return (
            <CardHeader
              subheader={getNotificationMessage(notification?.notifiable_type, notification?.data?.data)}
            />
          );
        })}
      </Box>
      <Box display="flex" justifyContent="space-around" px={2}>
        <Button variant="contained" style={{backgroundColor: "red"}}  
        onClick={() => { 
          handleRemoveAllNotification()
          setVisibleNotifications(false)
        }}>
          {strings.removeAll}
        </Button>
        <Button variant="contained" color="primary" onClick={() => {
          handleMarkAllRead()
          setVisibleNotifications(false)
        }}>{strings.markAll} </Button>
      </Box>
    </Box>
  );
}

export default NotificationsBox;
