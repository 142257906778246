import { Box, Grow } from '@material-ui/core'
import React from 'react'
import { useState } from 'react'
import LoginBanner from '../assets/img/login.svg'
import useStyles from '../jsxStyles/home.jsx'
import strings from '../lang/lang'
import styles from '../modules/authLayout.module.css'

function AuthLayout({ children, props }) {
  const [dir, setDir] = useState(
    localStorage.getItem('lang') === 'en' ? 'ltr' : 'rtl',
  )
  const classes = useStyles()
  const [type, setType] = useState('user')
  const [auth, setAuth] = useState('login')

  const handleLang = (lang) => {
    strings.setLanguage(lang)
    localStorage.setItem('lang', lang)
    props.onChange(lang)
    setDir(lang === 'en' ? 'ltr' : 'rtl')
  }

  const AuthLayoutMobileHead = () => (
    <Box className={styles.addOnMobileOnly}>
      <img
        className={styles.logo}
        style={{height: "4rem"}}
        src={`images/logo_light_${localStorage.getItem('lang')}.png`}
      />
      <h1 className="text-3xl text-700 text-white">{strings.getStarted}</h1>
      <div className="my-2 flex">
        <h2
          onClick={() => handleLang('en')}
          className="mx-1.5 text-xl text-white"
        >
          {strings.english}
        </h2>
        <h2
          onClick={() => handleLang('ar')}
          className="mx-1.5 text-xl text-white"
        >
          {strings.arabic}
        </h2>
      </div>
    </Box>
  )

  return (
    <div dir={dir} className={`${classes.homePage} ${styles.container1}`}>
      <AuthLayoutMobileHead />
      <div className={`relative ${classes.container} ${styles.container2}`}>
        <div className={styles.box1}>
          <img
            className="logo"
            src={`images/logo_light_${localStorage.getItem('lang')}.png`}
            style={{ marginTop: auth == 'login' ? 0 : '7rem' }}
          />
          <div>
            {/* <img
              src={LoginBanner}
              alt="wallet"
              className="lg:mt-20 xl:mt-16 object-center rounded-lg"
            /> */}
          </div>
          <div className="firstBox">
            <h1 
                className="text-2xl text-white mx-2">
                {strings.getStarted}
            </h1>
            <div className="lang-section my-3 flex">
              <h2
                className="text-2xl text-white mx-2"
                onClick={() => handleLang('en')}
              >
                {strings.english}
              </h2>
              <h2
                className="text-2xl text-white mx-2"
                onClick={() => handleLang('ar')}
              >
                {strings.arabic}
              </h2>
            </div>
          </div>
        </div>
        {type === 'user' && (
          <div className={`secondBox ${styles.box2}`}>
            <div>{children}</div>
          </div>
        )}
        {type === 'publisher' && (
          <div className="secondBox">
            <div>{children}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AuthLayout
