const initialState = {
  user: [],
  token: [],
  country_change: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "LOGIN_SUCCESS":
      let reduced = {
        ...state,
        user: payload.data,
        token: payload?.data.api_token,
      };
      localStorage.setItem("user", JSON.stringify(reduced.user));
      localStorage.setItem("token", reduced.token);
      return reduced;
    case "VALIDATED_TOKEN":
      let validated = {
        ...state,
        user: payload,
        token: payload?.api_token,
        authToken: payload?.api_token,
      };
      return validated;
    case "LOGOUT":
      state = { ...state, user: null };
      localStorage.removeItem("authToken");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      return state;
    default:
      return state;
  }
};
