import React, { useState, useContext, useEffect, useRef } from "react";
import { MdDone } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";
import { useHistory, withRouter } from "react-router";
import { useSelector } from "react-redux";
import { createQuestionApi, getProductQuestionsById } from "../../api";
import GoBack from "../../components/GoBack";
import strings from '../../lang/lang'
import { Card, CardHeader, FormHelperText } from '@material-ui/core'
import { Container } from "reactstrap";

const testFunction = ()=>{
}

function AdQustionsForm(props) {
  const { match } = props
  const answerField = useRef(null)
  const answerSubmit = useRef(null)
  const storedToken = localStorage.getItem("authToken");
  const lang = localStorage.getItem("lang");
  const authState = useSelector(state => state.user)
  const history = useHistory();

  const [question, setQuestion] = useState("");
  const [error, setError] = useState(null);
  const [isQuestion, setIsQuestion] = useState(false);

  const [answer, setAnswer] = useState("");
  const [trueAnswer, setTrueAnswer] = useState(0);
  const [answers, setAnswers] = useState([]);
  testFunction();
  useEffect(__=>{
    /*getProductQuestionsById(storedToken,match.params.id)
    .then(res=>{
    })*/
  })

  const handleSubmit = (e) => {
    if (answers.length >= 2 && answers.length <= 4) {
      const payload = createQuestionApi(storedToken, {
        name: question,
        answers,
        product_id: match.params?.id,
        user_id: authState?.user?.id,
        true_answer: trueAnswer,
      })
        .then((res) => {
          if (res?.data?.status) {
            window.location.href = `/publisher/ad_response?product_id=${match.params?.id}`;
          }
        })
        .catch((err) => console.log(err));
    }
    else {
      setError(strings.twoAnswers)
    }
  };

  useEffect(() => {
    if (!storedToken) window.location.pathname = "/login"
  }, [storedToken])

  return (
    <>
      <GoBack />
      <Container>
        <Card 
        style={{
          textAlign: lang == "ar" ? "right" : "left"
        }}>
          <CardHeader
            title={strings.addQuestion}
          />
          <div
            dir={lang == "ar" ? "rtl" : "ltr"}
            style={{ textAlign: lang == "ar" ? "right" : "left" }}
            className=" flex flex-col items-center sm:px-20  overflow-auto select-none"
          >

            <div className="flex ">
              <div className="w-6/12 shadow-b-sm hidden lg:block">
                <img src="/images/questions.svg" alt="question" className="h-90" />
              </div>

              <div
                className="w-full lg:w-6/12 flex flex-col"
              >
                {/* <header className="w-full flex px-10 lg:px-0 mb-10 lg:my-0">
                  <h1 className="text-3xl xs:text-4xl lg:text-5xl">{strings.addQuestion}</h1>
                </header> */}
                <div className="w-full flex justify-center my-2">
                  <input
                    type="text"
                    placeholder={strings.typeQuestion}
                    onKeyUp={() => question ? setIsQuestion(true) : setIsQuestion(false)}
                    className="w-11/12 lg:w-10/12 px-3 py-2 mb-3 text-lg text-gray-600 border-2 border-gray-400 rounded-lg focus:border-indigo-500 focus:outline-none"
                    onChange={(e) => setQuestion(e.target.value)}
                  />
                </div>
                {isQuestion && (
                  <div className="w-full my-2 flex flex-col items-center lg:items-end">
                    <div className="w-full min-h-auto max-h-40 flex items-center lg:items-end flex-col overflow-auto overflow-x-hidden">
                      {answers.map((answer, idx) => (
                        <div
                          key={idx}
                          className={`w-11/12 flex justify-between my-1 px-3 py-1.5 ${trueAnswer === idx ? "bg-green-100" : "bg-indigo-50"
                            } text-gray-600 font-semibold text-lg rounded-lg border-2 border-indigo-100`}
                        >
                          <span className="break-words w-7/12">{answer}</span>
                          <div className="flex self-start space-x-4">
                            <MdDone
                              onClick={() => setTrueAnswer(idx)}
                              className={`${trueAnswer === idx
                                ? "text-green-600 text-2xl"
                                : "text-gray-400 text-xl"
                                } cursor-pointer font-bold transition duration-300`}
                            />
                            <IoCloseSharp
                              onClick={() => {
                                answers.length === 1 && setTrueAnswer(0);
                                setAnswers(
                                  answers.filter((_, index) => index !== idx)
                                );
                              }}
                              className="self-center text-red-600 cursor-pointer text-xl font-bold"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                    {
                      answers.length < 4 &&
                      (
                        <>
                          <input
                            ref={answerField}
                            value={answer}
                            onKeyPress={(e) => {
                              if (e.key == "Enter")
                                answerSubmit.current.click()
                            }}
                            placeholder={strings.typeAnswer}
                            className="w-11/12 mt-2 px-3 py-2 text-lg text-gray-600 border-2 border-gray-400 rounded-lg focus:border-indigo-500 focus:outline-none"
                            onChange={(e) => setAnswer(e.target.value)}
                          ></input>

                          {error && <FormHelperText style={{ width: "70%" }} error>{error}</FormHelperText>}
                          <button
                            ref={answerSubmit}
                            onClick={() => {
                              (answer && answers.length <= 4) && setAnswers([...answers, answer]);
                              setAnswer("");
                              answerField.current.focus()
                            }}
                            disabled={!answer}
                            className="self-end mx-4  my-2 px-3 py-1.5 text-sm font-semibold rounded-full text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none"
                          >
                            {strings.addMoreAnswer}
                          </button>
                        </>
                      )}
                  </div>
                )}

                {answers.length > 0 && (
                  <div className="mt-4 lg:mt-0 mb-6 w-full flex flex-col items-center lg:flex-row lg:justify-between px-10">
                    <button onClick={handleSubmit} className="w-11/12 sm:w-5/12 px-4 py-2 bg-indigo-600 hover:bg-indigo-700 text-xl text-white font-semibold rounded-full focus:outline-none capitalize">
                      {strings.submit}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Card>
      </Container>
    </>
  );
}

export default withRouter(AdQustionsForm);
