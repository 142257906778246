import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  logo: {
    [theme.breakpoints.down("sm")]: {
      width: "30% !important",
    },
  },
  page: {
    marginTop: "2rem",
  },
  dashboardCards: {
    height: "7.5rem",
    backgroundColor: "white",
    color: "#495057",
    "& .card-content": {
      display: "flex",
      flexDirection: "column",
      "& p": {
        fontSize: "2rem",
        marginBottom: "0",
        textAlign: "center",
        fontWeight: "bold",
      },
      "& img": {
        width: "17.125rem",
      },
    },
  },
  innerCard: {
    zIndex: "1",
    position: "relative",
    left: "10%",
    top: "5%",
    padding: "0",
    height: "8.125rem",
    width: "8.125rem",
    borderRadius: "50% 0",
    overflow: "none",
    background:
      " linear-gradient(to right, #b4e391 0%,#61c419 50%,#b4e391 100%)",
    "& .card-content": {
      display: "flex",
      flexDirection: "column",
      "& p:first-child": {
        fontSize: "2rem",
        marginBottom: "0",
        color: "white",
        textAlign: "center",
        fontWeight: "bold",
      },
      "& p:nth-child(2)": {
        fontSize: "2rem",
        marginBottom: "0",
        color: "white",
        textAlign: "center",
        fontWeight: "bold",
      },
      "& img": {
        width: "17.125rem",
      },
    },
  },
  stepsCard: {
    position: "relative",
    height: "auto",
    backgroundColor: "#ffffff",
    color: "white",
    display: "flex",
    justifyContent: "space-between",
    // borderRadius:"10%",
    borderRadius: "20%",
    "& .inner-card": {
      display: "flex",
      flexDirection: "column",
      color: "#212121",
      width: "45%",
      "& button": {
        border: "0.2rem solid",
        width: "10rem",
        marginBottom: "0.5rem",
        borderRadius: "0.5rem",
      },
      "& p": {
        textAlign: "center",
      },
    },
    "& .inner-card2": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      flexDirection: "column",
      color: "#212121",
      width: "100%",
      "& button": {
        border: "0.2rem solid",
        width: "10rem",
        marginBottom: "0.5rem",
        marginRight: "5rem",
        borderRadius: "0.5rem",
      },
      "& p": {
        textAlign: "center",
      },
    },
    // borderTopLeft:"50%",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  titleParent: {
    backgroundColor: "#fff",
    padding: "1rem",
    margin: "0.5rem",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f7f7f7",
      border: "1px solid black",
      borderRadius: 12,
    },
  },
  title: {
    fontSize: "1rem",
    fontWeight: "600",
  },
  adsSection: {
    display: "flex",
    padding: "0.5rem",
    justifyContent: "space-between",
    "& > span:nth-child(2)": {},
  },
  pos: {
    marginBottom: 12,
    textAlign: "right",
    fontWeight: "600",
    fontSize: "2rem",
  },
  customModal: {
    width: "90vw" /* Occupy the 90% of the screen width */,
    maxWidth: "90vw",
  },
  steps: {
    display: "flex",
    "& span": {
      padding: "1rem",
      backgroundColor: "aliceblue",
      borderRadius: "50%",
    },
  },
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
  detailCard: {
    backgroundColor: "#f0f2f5",
    padding: "0.5rem",
    "& .detailTop": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& .detailTitle": {
        fontWeight: "600",
      },
      "& .detailButton": {
        backgroundColor: "grey",
        borderRadius: "10%",
        fontSize: "0.8rem",
      },
    },
  },
  locationBox: {
    padding: "0.5rem",
    background: "white",
  },
  sectionTop: {
    display: "flex",
    justifyContent: "space-between",
  },
  totalbudget: {
    display: "block",
  },
  toolTip: {
    width: "2rem",
    height: "2rem",
  },
  cardTitle: {
    fontWeight: "700",
    marginTop: "1rem",
    fontSize: 24,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
      marginTop: 0,
    },
  },
  cardSubTitle: {
    fontWeight: "600",
  },
  button: {
    backgroundColor: "#F2F2F2",
    borderRadius: "5%",
    // fontSize: '0.8rem'
    color: "#212121",
  },
  list: {
    display: "flex",
    height: "100px",
  },
  image: {
    margin: "0 1rem 1rem 1rem",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  budgetCard: {
    marginTop: "0.5rem",
    "& .text-center": {
      textAlign: "center",
    },
    "& .cost": {
      margin: "2rem",
      fontSize: "2.75rem",
      textAlign: "center",
      "& > :nth-child(2)": {
        margin: "0px 0.5rem",
        fontWeight: "bold",
      },
    },
  },
  svg: {
    height: "10rem",
    width: "100%",
  },
  budgetCards: {
    padding: "10px",
    background: "#9ED9AF",
    border: "1px solid black",
    borderRadius: 12,
    "&:hover": {
      border: "1px solid black",
      background: "#CAEED5",
    },
  },
  videoModal: {
    width: "55%",
    margin: "2% auto",
    "& .MuiTypography-h5":{
      [theme.breakpoints.down('sm')]:{
        fontSize:"20px"
      }
    },
    "& .container": {
      border: "1px dashed black",
      height: "calc(100vh/2)",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& .MuiTypography-body1": {
        fontWeight: "600",
      },
      
      transition:"all 0.5s",
      "&:hover":{
        backgroundColor:"rgba(0,0,0,0.5)",
        cursor:"pointer"
      },
      "& .MuiFormLabel-root":{
        fontSize:"15px",
        [theme.breakpoints.down('sm')]:{
          fontSize:"15px"
        }
      }
    },
    [theme.breakpoints.down("md")]: {
      width: "90%",
      margin: "10% auto",
    },
  },
  accountInfo: {
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  createAd: {
    textAlign: "center",
  },
  cardBodySM: {
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  hideOnSm: {
    [theme.breakpoints.down("md")]: {
      display: "none !important",
    },
  },
  whatGiftType: {
    padding: "20px 0px 8px 0px",
    fontSize: 18,
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
  cardsCreadeAd: {
    borderRadius: 30,
    backgroundColor: "white",
    "&:hover": {
      border: "1px solid aquamarine",
    },
  },
  buttonCreateAd: {
    fontSize: 16,
    borderRadius: 12,
    "&:hover": {
      border: "1px solid aquamarine",
    },
    [theme.breakpoints.down("md")]: {
      padding: "5px 8px",
      fontSize: 16,
    },
  },
  websiteCreateAd: {
    "& .MuiInputLabel-animated": {
      fontSize: 16,
      [theme.breakpoints.down("md")]: {
        fontSize: 13,
      },
    },
  },
  chooseAdSubTitle: {
    fontSize: 14,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 13,
    },
  },
  chooseAdSubTitls: {
    fontSize: 18,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
  chooseAdSubTitlss: {
    fontSize: 20,
    textAlign: "center",
    paddingBottom: 12,
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
  adcategory: {
    fontSize: 20,
    textAlign: "-webkit-center",
    [theme.breakpoints.down("md")]: {
      fontSize: 13,
      textAlign: "-webkit-center",
    },
  },
  detailTitles: {
    fontSize: 20,
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
    },
  },
  addQuestion: {
    "& .MuiTypography-h5": {
      fontSize: 20,
      textAlign: "center",
      fontWeight: 600,
      [theme.breakpoints.down("md")]: {
        fontSize: 18,
      },
    },
  },
  budget: {
    fontSize: 18,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
  nothing: {
    "& .MuiTypography-body1": {
      fontSize: 18,
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
      },
    },
  },
  cardsCreadeAd3: {
    "&:hover": {
      borderRadius: "30px 30px 30px 30px",
      border: "1px solid aquamarine",
    },
  },
  InputLabelProps: {
    "& .MuiFormLabel-root.Mui-disabled": {
      fontSize: 26,
      color: "black",
      fontWeight: "bold",
      marginLeft: 4,
      [theme.breakpoints.down("md")]: {
        fontSize: 26,
      },
    },
    "& .MuiInputBase-root.Mui-disabled": {
      fontSize: 18,
      padding: "12px 0px 0px 0px",
      color: "black",
      marginLeft: 4,
      marginRight: 4,
      [theme.breakpoints.down("md")]: {
        fontSize: 18,
      },
    },
  },
  addMoreAnswer: {
    fontSize: 18,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
  },
  addMoreAnswers: {
    marginLeft: "23%",
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
  },
  mobileEmail: {
    "& .MuiInputLabel-formControl": {
      fontSize: 20,
    },
  },
  downButtons: {
    fontSize: 18,
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
  ganderTitReg: {
    textAlign: "center",
    color: "black",
    margin: "auto",
  },
  ganderReg: {
    width: "25%",
    "&:hover": {
      backgroundColor: "#28a745",
    },
    [theme.breakpoints.down("md")]: {
      padding: "5px 8px",
      fontSize: 16,
    },
  },
  ganderReg2: {
    width: "25%",
    backgroundColor: "#28a745",
    "&:hover": {
      backgroundColor: "#28a745",
    },
    [theme.breakpoints.down("md")]: {},
  },
  spe: {
    display: "none",
    "& .react-tel-input .country-list .country:hover": {
      color: "black",
    },
  },
  countrySelect:{
    "& .MuiChip-root":{
      borderRadius:"10px"
    },
    "& .MuiAutocomplete-tag":{
      backgroundColor: "#28a745",
      color: "white",
      padding:"8px"
    },
    '& .MuiChip-deleteIcon':{
        color:"rgb(255, 255, 255)"
    }
  }
}));
