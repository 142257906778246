let initialValues = {
    notifications: [],
    count: 0,
    unreadnotifications: 0
}

export default (state = initialValues, action) => {
    let updatedState;
    switch (action.type) {
        case "Notifications":
            updatedState = { ...state, notifications: action.payload };
            return updatedState;
        case "AddNotification":
            let notifications = state.notifications;
            notifications.unshift(action.payload.notification)
            updatedState = { ...state, ...{ notifications, count: state.count + 1 } }
            return updatedState;
        case "SetCount":
            updatedState = { ...state, count: action.payload }
            return updatedState;
        case "setUnreadMsgs":
            updatedState = { ...state, unreadnotifications: action.payload }
            return updatedState;
        default:
            return state;
    }

}