import "bootstrap/dist/css/bootstrap.min.css";

import {
  Avatar,
  Badge,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Grid,
  Typography,
} from "@material-ui/core";
import EnFlag from "../../assets/img/united-states.png";
import ArFlag from "../../assets/img/saudi-arabia.png";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import BusinessIcon from "@material-ui/icons/Business";
import DashboardIcon from "@material-ui/icons/Dashboard";
import FeaturedVideoOutlinedIcon from "@material-ui/icons/FeaturedVideoOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import QueryStatsIcon from "@material-ui/icons/Equalizer";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import PostAddIcon from "@material-ui/icons/PostAdd";
import React, { useContext, useEffect, useState } from "react";
import { IoLogOutOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  NavbarText,
  NavbarToggler,
} from "reactstrap";

import { addNotification, logout } from "../../store/actions/userActions";
import { LogoutUserApi, notificationApi } from "../../api/index.js";
import useStyles from "../../jsxStyles/navbar.jsx";
import strings from "../../lang/lang";
import styles from "../../modules/sidebar.module.css";
import MobileSidebar from "../MobileSidebar";
import NotificationsBox from "../NotificationsBox";
import { useDispatch, useSelector } from "react-redux";
import { AudienceContext } from "../../contexts/audience";
import { storeNotifications, setCount, setUnreadMsgs } from "../../store/actions/userActions";
import Pusher from "pusher-js";
import { pusherAuthUrl } from "../../utils/constants";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ImageIcon from "@mui/icons-material/Image";
import { BorderAll } from "@material-ui/icons";
import LanguageIcon from "@mui/icons-material/Language";
import userLogo from "../../assets/img/userlogo.png";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";


function PublisherNavbar(props) {
  const storedToken = localStorage.getItem("authToken");

  const history = useHistory();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.user);
  const user = useSelector((state) => state?.user?.user);
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const notifications = useSelector(
    (state) => state?.notifications?.notifications
  );
  const notificationsCount = useSelector(
    (state) => state?.notifications?.count
  );
  const unreadnotificationsCount = useSelector(
    (state) => state?.notifications?.unreadnotifications
  );
  const [activeSidebar, setActiveSidebar] = useState(false);
  const [showCompanyButton, setCompanyButton] = useState(true);
  const [visibleNotifications, setVisibleNotifications] = useState(false);
  const [location, setLocation] = useState(history.location.pathname);
  const toggle = () => setIsOpen(!isOpen);
  const lang = localStorage.getItem("lang");
  const audience = useContext(AudienceContext);
  const current_user = useSelector((state) => state.user.user);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [acc, setAcc] = React.useState(null);
  const accOpen = Boolean(acc);
  const accHandleClick = (event) => {
    setAcc(event.currentTarget);
  };
  const accHandleClose = () => {
    setAcc(null);
  };

  const handleRedirect = (url) => {
    history.push(url);
    setLocation(url);
  };
  const handleLogout = async () => {
    const answer = window.confirm("Are you sure you want to leave thamin?");
    if (answer) {
      const { data } = await LogoutUserApi(storedToken);
      if (data.status) {
        dispatch(logout(data));
        localStorage.removeItem("user");
        handleRedirect("/login");
      }
    }
  };
  let count = useSelector((state) => state.notifications?.count);
  //let unreadnotifications = useSelector((state) => state.notifications?.unreadnotifications);

  var pusher = new Pusher("b6494408db9e729be4e9", {
    authEndpoint: pusherAuthUrl,
    cluster: "mt1",
  });

  useEffect(() => {
    const channel = pusher.subscribe(`private-publisher.${current_user?.id}`);
    channel.bind(
      "Illuminate\\Notifications\\Events\\BroadcastNotificationCreated",
      (data) => {
        let notificationsCount = count + 1;
        dispatch(addNotification({ read_at: null, data }, notificationsCount));
      }
    );

    notificationApi(storedToken).then((res) => {
    
      if (res.data) {
        const { notifications, count, unreadnotifications } = res?.data?.data;
        dispatch(storeNotifications(notifications));
        dispatch(setCount(count));
        dispatch(setUnreadMsgs(unreadnotifications));
      }
    });
    return () => {
      pusher.unsubscribe(`private-publisher.${current_user?.id}`);
    };
  }, []);

  const handleAdCenter = () => {
    audience.setSelectedCountries([]);
    audience.setSelectedStates([]);
    audience.setSelectedJobs([]);
    audience.setGender(0);
    audience.setAge([18, 65]);
    history.push("/publisher/adcenter");
  };

  const updateLang = (lang) => {
    localStorage.setItem("lang", lang);
    window.location.reload();
  };

  document.body.style.overflow = visibleNotifications ? "hidden" : "auto";

  return (
    <div
      dir={lang === "ar" ? "rtl" : "ltr"}
      className={`w-full ${classes.main}  px-1 xs:px-2 lg:px-4 shadow-sm`}
    >
      <Navbar
        color="green"
        expand="md"
        className={`${classes.navBar}`}
        style={{ boxShadow: "0 4px 2px -2px #ddd" }}
      >
        <NavbarBrand href="/">
          <img
            src={`https://thaminmainweb.fra1.digitaloceanspaces.com/images/logo_light_${lang}.png`}
            className={classes.thaminLogo}
            alt="logo"
            style={{ height: 56, minWidth: 75 }}
          />
        </NavbarBrand>
        {/* <NavbarToggler onClick={toggle}/> */}
        <div className="flex justify-between">
          <div>
            <Collapse isOpen={isOpen} navbar>
              <Button
                size="large"
                onClick={() => handleRedirect("/publisher/dashboard")}
                startIcon={<DashboardIcon htmlColor="#fff" />}
                className={
                  location == "/publisher/dashboard"
                    ? classes.active
                    : classes.notActive
                }
              >
                <Typography style={{wordBreak: "unset", fontSize: 18 }}>
                  {strings.dashboard}
                </Typography>
              </Button>

              <Divider orientation="vertical" />

              {current_user?.company && (
                <Button
                  size="large"
                  onClick={() => handleRedirect("/publisher/myads")}
                  startIcon={<FeaturedVideoOutlinedIcon htmlColor="#fff" />}
                  className={
                    location == "/publisher/myads"
                      ? classes.active
                      : classes.notActive
                  }
                >
                  <Typography style={{wordBreak: "unset", fontSize: 18 }}>
                    {strings.myads}
                  </Typography>
                </Button>
              )}
              <Divider orientation="vertical" />

              <Button
                size="large"
                onClick={() => handleRedirect("/publisher/analytics")}
                startIcon={<QueryStatsIcon htmlColor="#fff" />}
                className={
                  location == "/publisher/analytics"
                    ? classes.active
                    : classes.notActive
                }
              >
                <Typography style={{wordBreak: "unset", fontSize: 18 }}>
                  {strings.analytics}
                </Typography>
              </Button>

              <Divider orientation="vertical" />

              {current_user?.company && (
                <Button
                  size="large"
                  onClick={() => handleAdCenter()}
                  startIcon={<PostAddIcon htmlColor="#fff" />}
                  className={
                    location == "/publisher/adcenter"
                      ? classes.active
                      : classes.notActive
                  }
                >
                  <Typography style={{wordBreak: "unset", fontSize: 18 }}>
                    {strings.postad}
                  </Typography>
                </Button>
              )}

              <Divider orientation="vertical" />
              {!current_user?.company && (
                <Button
                  size="large"
                  className={
                    location == "/publisher/adcenter"
                      ? classes.active
                      : classes.notActive
                  }
                  onClick={props.toggleCompanyModal}
                  endIcon={<BusinessIcon htmlColor="#fff" />}
                >
                  <Typography style={{wordBreak: "unset", fontSize: 18 }}>
                  {strings.createcompany}
                  </Typography>
                </Button>
              )}
              <Divider orientation="vertical" />
            </Collapse>
          </div>
          <div className={`flex items-center gap-4 ${styles.hideOnSM}`}>
            <div>
              <Button
                className={classes.accountBtn}
                id="fade-button"
                aria-controls={open ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                startIcon={<LanguageIcon htmlColor="#fff" />}
              >
                <Typography style={{wordBreak: "unset", fontSize: 18 }}>
                  {strings.language}
                </Typography>
              </Button>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                <MenuItem onClick={() => updateLang("ar")}>Arabic</MenuItem>
                <MenuItem onClick={() => updateLang("en")}>English</MenuItem>
              </Menu>
            </div>
          </div>
        </div>
        <div className={`flex justify-between`}>
          <div>
          <DisplayNotifications
            notifications={notifications}
            notificationsCount={notificationsCount}
            isVisible={visibleNotifications}
            setVisible={setVisibleNotifications}
            unreadnotificationsCount={unreadnotificationsCount}
          />
          <Divider orientation="vertical" />
          </div>
          <div className={`flex justify-between ${styles.hideOnSM}`}>
          <Button
            id="fade-button"
            aria-controls={accOpen ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={accOpen ? "true" : undefined}
            onClick={accHandleClick}
          >
            <Avatar>
              <img
                src={userLogo}
                alt="profile img"
                width="200"
                height="200"
                style={{backgroundColor: "white"}}
              ></img>
            </Avatar>
          </Button>
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={acc}
            open={accOpen}
            onClose={accHandleClose}
            TransitionComponent={Fade}
          >
            <div>
              <MenuItem>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <img
                          src={userLogo}
                          alt="profile img"
                          width="500"
                          height="600"
                          style={{backgroundColor: "white"}}
                        ></img>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      style={{ textAlign: "center" }}
                      primary={user.name || "Username"}
                      secondary={user.email || "Email"}
                    />
                  </ListItem>
                </List>
              </MenuItem>
            </div>
            <Grid container>
            <Grid xs={12}>
            <Grid style={{ paddingTop: 10 }} />
            <Grid container>
            <Grid xs={2}/>
            <Grid xs={8}>
            <Button
                onClick={() => handleRedirect("/publisher/account")}
                className={classes.accountBtns}
                startIcon={<AccountBoxIcon />}
              >
                <Typography style={{wordBreak: "unset", padding:"0px 10px", fontSize: 18}}>
                {strings.account}
                </Typography>
            </Button>
            </Grid>
            <Grid xs={2}/>
            </Grid>
            </Grid>
            <Grid xs={12}>  
            <Grid style={{ paddingTop: 10 }} />
            <Grid container>
            <Grid xs={2}/>
            <Grid xs={8}>
            <Button
                onClick={() => handleLogout()}
                className={classes.accountBtns}
                startIcon={<ExitToAppIcon />}        
              >
                <Typography style={{wordBreak: "unset",padding:"0px 10px", fontSize: 18 }}>
                {strings.logout}
                </Typography>
            </Button>
            </Grid>
            <Grid xs={2}/>
            </Grid>
            </Grid>
            </Grid>
          </Menu>
          </div>
        </div>
        <div className={styles.navIcons}>
          <IconButton onClick={() => setActiveSidebar(true)}>
            <MenuIcon />
          </IconButton>

          {activeSidebar && (
            <button
              onClick={() => setActiveSidebar(false)}
              className={styles.btnWrapper}
            ></button>
          )}
          {activeSidebar && (
            <MobileSidebar
              setActiveSidebar={setActiveSidebar}
              toggleCompanyModal={props.toggleCompanyModal}
              username={current_user?.user?.name}
              authState={current_user}
              handleRedirect={handleRedirect}
              handleLogout={handleLogout}
            />
          )}
        </div>
      </Navbar>
    </div>
  );
}

const DisplayNotifications = ({
  notifications,
  notificationsCount,
  isVisible,
  setVisible,
  unreadnotificationsCount
}) => {
  return (
    <>
      {isVisible && (
        <button
          onClick={() => setVisible(false)}
          className={styles.btnWrapper}
        ></button>
      )}
      <IconButton
        style={{ zIndex: 60, position: "relative" }}
        onClick={() =>
          notificationsCount >= 0 ? setVisible(!isVisible) : () => {}
        }
      >
        {notificationsCount > 0 ? (
          <Badge badgeContent={unreadnotificationsCount} color="primary">
            <NotificationsNoneIcon fontSize="large" numOctaves="1" />
          </Badge>
        ) : (
          <NotificationsNoneIcon fontSize="large" numOctaves="1" />
        )}
      </IconButton>
      {isVisible && notificationsCount >= 0 && (
        <NotificationsBox
          notifications={notifications}
          isVisible={isVisible}
          setVisibleNotifications={setVisible}
        />
      )}
    </>
  );
};

export default PublisherNavbar;
