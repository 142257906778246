import { Button, ButtonGroup } from "@material-ui/core";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import BusinessIcon from "@material-ui/icons/Business";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FeaturedVideoOutlinedIcon from "@material-ui/icons/FeaturedVideoOutlined";
import QueryStatsIcon from "@material-ui/icons/Equalizer";
import PostAddIcon from "@material-ui/icons/PostAdd";
import React from "react";
import { IoMdClose } from "react-icons/io";
import strings from "../lang/lang";
import styles from "../modules/sidebar.module.css";
import { useSelector } from "react-redux";

function MobileSidebar({
  toggleCompanyModal,
  setActiveSidebar,
  username,
  handleRedirect,
  handleLogout,
  authState,
}) {
  const user = useSelector((state) => state?.user?.user);
  console.log("auser", authState);
  const lang = localStorage.getItem("lang");
  const handleClick = (path) => {
    if (path) {
      path === "toggler" ? toggleCompanyModal() : handleRedirect(path);
    }

    setActiveSidebar(false);
  };

  const updateLang = (lang) => {
    localStorage.setItem("lang", lang);
    window.location.reload();
  };

  return (
    <div className={`${styles.container} h-full  overflow-hidden`}>
      <div className="w-full flex justify-between items-center px-2 py-4">
        <IoMdClose
          onClick={() => setActiveSidebar(false)}
          className="text-3xl text-gray-600 hover:text-red-600 focus:text-red-600 cursor-pointer"
          title="close"
        />
        <span className="text-gray-600 text-xl xs:text-2xl capitalize h3">
          {username ? username : user.name}
        </span>
      </div>

      <div className="w-full h-full flex flex-col gap-4 px-4">
        <div>
          <ButtonGroup size="medium" aria-label="button group">
            <Button
              onClick={() => updateLang("ar")}
              color="primary"
              variant="contained"
              style={{
                backgroundColor: `${lang == "ar" ? "#000" : "#55BC72"}`,
                fontSize: 18,
              }}
            >
              {strings.arLang}
            </Button>
            <Button
              onClick={() => updateLang("en")}
              color="secondary"
              variant="outlined"
              style={{
                backgroundColor: `${lang == "en" ? "#000" : "#55BC72"}`,
                fontSize: 18,
              }}
            >
              {strings.enLang}
            </Button>
          </ButtonGroup>
        </div>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={() => handleClick("/publisher/dashboard")}
          startIcon={<DashboardIcon />}
          style={{
            backgroundColor: "#55BC72",
            fontSize: 18,
          }}
        >
          {strings.dashboard}
        </Button>
        {authState?.company && (
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={() => handleClick("/publisher/myads")}
            startIcon={<FeaturedVideoOutlinedIcon />}
            style={{
              backgroundColor: "#55BC72",
              fontSize: 18,
            }}
          >
            {strings.myads}
          </Button>
        )}

        {/* <Button
          fullWidth
          color="primary"
          variant="contained"
          size="large"
          onClick={() => handleClick("/publisher/profile")}
          startIcon={<AccountBoxIcon />}
        >
          {strings.profile}
        </Button> */}

        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={() => handleRedirect("/publisher/analytics")}
          startIcon={<QueryStatsIcon htmlColor="#fff" />}
          style={{
            backgroundColor: "#55BC72",
            fontSize: 18,
          }}
        >
          {strings.analytics}
        </Button>
        {!authState?.company && (
          <Button
            fullWidth
            color="primary"
            onClick={() => handleClick("toggler")}
            variant="contained"
            startIcon={<BusinessIcon />}
            style={{
              backgroundColor: "#55BC72",
              fontSize: 18,
            }}
          >
            {strings.createcompany}
          </Button>
        )}

        {authState?.company && (
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={() => handleClick("/publisher/adcenter")}
            startIcon={<PostAddIcon />}
            style={{
              backgroundColor: "#55BC72",
              fontSize: 18,
            }}
          >
            {strings.postad}
          </Button>
        )}
        <Button
          fullWidth
          color="primary"
          variant="contained"
          startIcon={<AccountBoxIcon />}
          onClick={() => handleClick("/publisher/account")}
          style={{
            backgroundColor: "#55BC72",
            fontSize: 18,
          }}
        >
          {strings.account}
        </Button>
        <Button
          fullWidth
          color="primary"
          onClick={handleLogout}
          variant="contained"
          startIcon={<ExitToAppIcon />}
          style={{
            backgroundColor: "#55BC72",
            fontSize: 18,
          }}
        >
          {strings.logout}
        </Button>
      </div>
    </div>
  );
}

export default MobileSidebar;
